import React, { useContext } from 'react'

// Assets
import persona from '../../../../assets/images/mujer-negocios.png'
// Utils
import { ScriptContext } from '../../../../context/ScriptsContext'

import './Home.css'

export const Home = () => {
  const { scriptsSave } = useContext(ScriptContext)
  return (
    <div className="home">
      <div className="homeContent">
        <h1 className="homeTitle">
          {scriptsSave && scriptsSave.homeBuyerString ? scriptsSave.homeBuyerString.welcome : ''}
          {scriptsSave && scriptsSave.homeBuyerString ? scriptsSave.homeBuyerString.tudata : ''}
        </h1>
        <h2 className='homeSubtitle'>{scriptsSave && scriptsSave.homeBuyerString ? scriptsSave.homeBuyerString.portal : ''}</h2>
        <p className="homeDescription">{scriptsSave && scriptsSave.homeBuyerString ? scriptsSave.homeBuyerString.description : ''}</p>
      </div>
      <img className="homeImage" src={persona} alt={scriptsSave && scriptsSave.homeBuyerString ? scriptsSave.homeBuyerString.logo : ''} />
    </div>
  )
}
