import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import LogoEmpresas from '../../../../assets/icons/icon_bd_purchased.svg'
import { DataBaseBuyerStrings } from '../../../../utils/strings'
import { DataContext } from '../../../../context/DataContext'
import { formatDate } from '../DataBasePanel/DataBasePanelFunctions'
import './DataBaseDetail.css'
import { handleDownloadDB, handleInsertCode } from './DataBaseDetailFunctions'
import { AuthContext } from '../../../../context/AuthContext'
import { initialDBDetail } from '../../../../utils/objects'

export const DataBaseDetail = () => {
  const { contextInfo } = useContext(DataContext)
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const { dataBaseDetailStrings } = DataBaseBuyerStrings
  const dbIndex = contextInfo.purchasedDbNum
  const [data, setData] = useState(initialDBDetail)
  const [isRequesting, setIsRequesting] = useState(false)

  useEffect(() => {
    setData({
      shoppingHistoryId: contextInfo.dbpurchased[dbIndex].shoppingHistoryId,
      dbType:
        dbIndex !== undefined &&
        contextInfo.dbpurchased[dbIndex].databaseTypeId.databaseType,
      dbUsersQuantity:
        dbIndex !== undefined && contextInfo.dbpurchased[dbIndex].usersAmount,
      dbExpeditionDate:
        dbIndex !== undefined &&
        formatDate(contextInfo.dbpurchased[dbIndex].expeditionDate),
      dbCreateDate:
        dbIndex !== undefined &&
        contextInfo.dbpurchased[dbIndex].createDt,
      dbUpdateDate:
        dbIndex !== undefined &&
        contextInfo.dbpurchased[dbIndex].updateDt,
      dbState: dbIndex !== undefined && contextInfo.dbpurchased[dbIndex].statusId.statusName
    })
  }, [])

  return (
    <section id="DataBaseDetail">
        <div className='DataBaseDetailTitle'>
           <h1 className="DataBaseDetailTitleText">
            {dataBaseDetailStrings.dataBase}
            {Number(dbIndex) + 1}
            </h1>
            <div className='DataBaseDetailTitleStatusDb'>
              <p className='DataBaseDetailTitleStatusDbState'>Estado:</p>
              <p className='DataBaseDetailTitleStatusDbValue'>
                {data.dbState === 'Pendiente' && 'Pendiente'}
                {data.dbState === 'Activa' && 'Disponible/Activa'}
                {data.dbState === 'Vencida' && 'Vencida'}
                {data.dbState === 'Rechazado' && 'Rechazado'}
              </p>
              <div
                className={`DataBaseStateIndicator ${
                 data.dbState === 'Pendiente'
                  ? 'DataBaseStateIndicatorPendingColor'
                  : data.dbState === 'Activa'
                    ? 'DataBaseStateIndicatorActiveColor'
                    : 'DataBaseStateIndicatorInactiveColor'
                }`
                }>
              </div>
            </div>
        </div>
      <div className="DataBaseDetailTopContent">
        <img src={LogoEmpresas} alt="dataBaseIcon" />
        <div className="DataBaseDetailParagraph">
          <div>
            <div className="DataBaseDetailInfo">
              <p className="DataBaseDetailText">
                {dataBaseDetailStrings.dataBase}
                {data.dbType}
              </p>
            </div>
            <div className="DataBaseDetailInfo">
              <p className="DataBaseDetailText">
                {dataBaseDetailStrings.userQuantity}
                {data.dbUsersQuantity}
              </p>
            </div>
          </div>
          <div className="DataBaseDetailDueDate">
            <p className="DataBaseDetailText">
              {dataBaseDetailStrings.dueDateTitle}
            </p>
            <p className="DataBaseDetailText">{data.dbExpeditionDate}</p>
          </div>
        </div>
      </div>
      <div className="DataBaseDetailBotContent">
        <p>
          {data.dbState === 'Pendiente' &&
          <>
            {data.dbCreateDate === data.dbUpdateDate &&
              <>
              {dataBaseDetailStrings.code1}
              <p>{dataBaseDetailStrings.code2}</p>
              </>
            }
            {data.dbCreateDate !== data.dbUpdateDate &&
              <>
              {dataBaseDetailStrings.pendingMessage1}{' '}
              <span>{dataBaseDetailStrings.pendingMessage2}</span>
              </>
            }
          </>
          }
          {data.dbState === 'Activa' &&
          <>
            <span>{dataBaseDetailStrings.stateOfPayAccepted}</span>{' '}
            {dataBaseDetailStrings.nowYouCanDownloadIt1}
            <span className='textBold'>
              {dataBaseDetailStrings.database}
            </span>
            <br />
            {dataBaseDetailStrings.nowYouCanDownloadIt2}
          </>
          }
          {data.dbState === 'Vencida' &&
          <>
            {dataBaseDetailStrings.expiredMessage}
            <span>{dataBaseDetailStrings.renovateQuestion}</span>
          </>
          }
          {data.dbState === 'Rechazado' &&
          <>
            <span>{dataBaseDetailStrings.rejected}</span>{dataBaseDetailStrings.again}<span className='anchor' onClick={() => navigate('../BaseDeDatos/SeleccionPlan')}>{dataBaseDetailStrings.link}</span>
          </>
          }
        </p>
      </div>
      <div className="DataBaseDetailBoxButton">
        {data.dbState === 'Pendiente' && data.dbCreateDate === data.dbUpdateDate &&
          <button
            id="AcceptBtn"
            className={'DataBaseDetailButton'}
            onClick={() => handleInsertCode(contextInfo, authContext, navigate)}
            disabled={isRequesting}
          >
            {dataBaseDetailStrings.continue}
          </button>
        }
        {data.dbState === 'Activa' &&
          <button
            id="AcceptBtn"
            className={'DataBaseDetailButton'}
            onClick={() => handleDownloadDB(isRequesting, setIsRequesting, data.shoppingHistoryId, authContext)}
            disabled={isRequesting}
          >
            {dataBaseDetailStrings.download}
          </button>
        }
        {data.dbState === 'Vencida' &&
          <button
            id="AcceptBtn"
            className={'DataBaseDetailButton'}
            onClick={() => navigate('../BaseDeDatos/SeleccionPlan')}
          >
            {dataBaseDetailStrings.renovateDB}
          </button>
        }
      </div>
    </section>
  )
}
