import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

export const Layout = ({ data }) => {
  const { pathname } = useLocation()
  return (
    <>
      {data.map((e, i) => (
        <NavLink key={i} id={e.id} to={e.to} className={pathname.includes(e.pathToActiveStyle) ? 'BaseLayoutHeaderParagraph HeaderActive' : 'BaseLayoutHeaderParagraph'}>
          {e.text}
        </NavLink>
      ))}
    </>
  )
}
