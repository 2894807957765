import { activateRequestButton, handleRequestButton } from '../../../../utils/functions'
import { contactForm } from '../../../../utils/queries'
import { deleteSpacing, hasRepeatedCharactersAtMiddle, validateCellphone, validateEmailFormat, validateNames, validateNoWhiteSpacesAtBeginning, validateNoWhiteSpacesAtMiddle } from '../../../../utils/validateFunctions'

export const handleSubmit = async (e, formData, handleShow, isRequesting, setIsRequesting, inputsChecked) => {
  e.preventDefault()
  if (isRequesting) return

  const timeOutId = handleRequestButton(setIsRequesting)
  const areValidateInputs = Object.values(inputsChecked).every((validation) => validation)
  const { name, email, cell, subject } = formData
  if (name && email && cell && subject && areValidateInputs) {
    const content = 'Nombre: ' + name + '\nEmail: ' + email + '\nTeléfono: ' + cell + '\nSolicitud: ' + subject
    await contactForm({ content })
    handleShow()
  } else {
    activateRequestButton(setIsRequesting, timeOutId)
  }
}

export const handleInputOnChange = (e, formData, setFormData, inputsValidated, setInputsValidated) => {
  const regexMinChars = /^[\s\S]{40,}$/
  const regexPuntuationMarks = /[^\w\s]{3}/

  const subject = e.target.value
  if (subject.length > 1800) return

  if (validateNoWhiteSpacesAtBeginning(subject)) {
    if (hasRepeatedCharactersAtMiddle(subject)) {
      return
    }
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  if (
    regexMinChars.test(e.target.value) &&
    !regexPuntuationMarks.test(e.target.value) &&
    e.target.value.length > 39
  ) {
    setInputsValidated({ ...inputsValidated, [e.target.name]: true })
  } else {
    setInputsValidated({ ...inputsValidated, [e.target.name]: false })
  }

  // if (validateRepeatedCharacters(subject)) {
  //   return
  // }
}

export const handleInputOnBlur = (e, formData, setFormData) => {
  setFormData({ ...formData, [e.target.name]: deleteSpacing(e.target.value) })
}

export const handleClickOkModaButton = (navigate) => {
  navigate('/')
}

export const allInputsChecked = (inputs) => {
  for (const key in inputs) {
    if (
      Object.prototype.hasOwnProperty.call(inputs, key) &&
      inputs[key] !== true
    ) {
      return false
    }
  }
  return true
}

export const validateCell = (
  e,
  inputsValidated,
  setInputsValidated,
  formData,
  setFormData
) => {
  const phoneValue = validateCellphone(e)

  if (!phoneValue) {
    return
  }

  if (validateNoWhiteSpacesAtBeginning(phoneValue)) {
    setFormData({ ...formData, [e.target.name]: phoneValue })
  }

  if (phoneValue || phoneValue === '') {
    if (phoneValue.startsWith('3') && phoneValue.length === 10) {
      setInputsValidated({ ...inputsValidated, cell: true })
    } else {
      setInputsValidated({ ...inputsValidated, cell: false })
    }
  }
}

export const validateEmail = (
  e,
  inputsValidated,
  setInputsValidated,
  formData,
  setFormData
) => {
  const email = e.target.value

  if (validateNoWhiteSpacesAtBeginning(email) && validateNoWhiteSpacesAtMiddle(email)) {
    setFormData({ ...formData, [e.target.name]: email })
  } else {
    return
  }

  if (email !== '') {
    if (!validateEmailFormat(email)) {
      setInputsValidated({ ...inputsValidated, email: false })
    } else {
      setInputsValidated({ ...inputsValidated, email: true })
    }
  } else {
    setInputsValidated({ ...inputsValidated, email: false })
  }
}

export const validateName = (
  e,
  inputsValidated,
  setInputsValidated,
  formData,
  setFormData
) => {
  const name = e.target.value

  if (hasRepeatedCharactersAtMiddle(name)) {
    return
  }

  if (validateNames(name) && validateNoWhiteSpacesAtBeginning(name)) {
    setFormData({ ...formData, [e.target.name]: name })
  }

  if (name !== '' && name.length >= 8) {
    setInputsValidated({ ...inputsValidated, name: true })
  } else {
    setInputsValidated({ ...inputsValidated, name: false })
  }
}
