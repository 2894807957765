import {
  customerSupportString,
  frequentQuestionsBuyerString
} from '../../utils/strings'
import { getPqrsByRadicateNum } from '../../utils/userqueries'

export const questionsAndAnswers = [
  {
    question: frequentQuestionsBuyerString.buyersPortal,
    answer: frequentQuestionsBuyerString.responseBuyerPortal
  },
  {
    question: frequentQuestionsBuyerString.specializedDatabase,
    answer: frequentQuestionsBuyerString.userInformation
  },
  {
    question: frequentQuestionsBuyerString.databasesReceived,
    answer: frequentQuestionsBuyerString.receivedByEmail
  },
  {
    question: frequentQuestionsBuyerString.usersPrivacy,
    answer: frequentQuestionsBuyerString.tudataCommitment
  },
  {
    question: frequentQuestionsBuyerString.resolveDoubts,
    answer: frequentQuestionsBuyerString.additionalQuestions
  }
]

export const handleChangeSearchRadicate = async (
  e,
  setRadicateValue,
  authContext,
  handleShow,
  setModalMessage,
  setIsValidRadicated
) => {
  const value = e.target.value
  setRadicateValue(value.toUpperCase())

  if (value.length === 9) {
    e.target.blur()
    const response = await getPqrsByRadicateNum(value, authContext)

    if (response.response) {
      setIsValidRadicated(true)
      setModalMessage(response.body.content)
      handleShow()
    } else if (response.data.code.length < 3) {
      setIsValidRadicated(false)
      setModalMessage(customerSupportString.notValidRadicatedOrUser)
      handleShow()
    }
  }
}

export const handleCloseModal = (setRadicateValue, handleClose) => {
  setRadicateValue('')
  handleClose()
}
