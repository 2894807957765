import React, { createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../hooks/useModal'
import { Modal } from '../components/Modal/Modal'
import { removeData } from '../utils/functions'
import { initialAuthContextState } from '../utils/objects'
import { authContextStrings } from '../utils/strings'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()

  const [sessionData, setSessionData] = useState(initialAuthContextState)

  const [showModal, handleShowInactiveSessionModal, handleClose] = useModal()

  const closeInactiveSessionModal = () => {
    removeData({ navigate, setSessionData })
    handleClose()
  }

  const data = {
    sessionData,
    setSessionData,
    initialAuthContextState,
    navigate,
    handleShowInactiveSessionModal
  }

  return (
    <AuthContext.Provider value={data}>
      {children}
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>
            {authContextStrings.sessionExpired}
          </p>
        </Modal.Content>
        <Modal.Buttons>
          <button onClick={closeInactiveSessionModal} className="Modal-greenBtn">
            {authContextStrings.ok}
          </button>
        </Modal.Buttons>
      </Modal>
    </AuthContext.Provider>
  )
}
