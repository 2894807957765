import React, { useContext, useEffect, useState } from 'react'
import logo from '../../assets/icons/logo.svg'
import arrowIcon from '../../assets/icons/icon_flecha_blanco.svg'
import openEyeIcon from '../../assets/icons/icon_pass2.svg'
import closeEyeIcon from '../../assets/icons/icon_pass1.svg'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { Form, Anchor } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { loginBuyerString } from '../../utils/strings'
import LoginFunctions from './LoginFunctions'
import { useModal } from '../../hooks/useModal'
import { maxSize, validateEmailFormat, validatePassFormat } from '../../utils/validateFunctions'
import { Modal } from '../../components/Modal/Modal'
import { DataContext } from '../../context/DataContext'
import { AuthContext } from '../../context/AuthContext'
import './LogIn.css'
import { getUserData } from '../../utils/queries'

export const LogIn = () => {
  const { contextInfo, setContextInfo } = useContext(DataContext)
  const { sessionData, setSessionData, handleShowInactiveSessionModal } = useContext(AuthContext)
  const navigate = useNavigate()
  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [cript, setCript] = useState('')
  const [showIncorrectFormatPass, setshowIncorrectFormat] =
    useState('showSpanPass')
  const [showIncorrectFormatEmail, setshowIncorrectFormatEmail] =
    useState('showSpanPass')
  const [eyeIcon, setEyeIcon] = useState(openEyeIcon)
  const [isRequesting, setIsRequesting] = useState(false)
  const [remindMe, setRemindMe] = useState(false)
  const [loginData, setLoginData] = useState({ email: '', pass: '' })

  useEffect(() => {
    const data = getUserData()
    if (data.buyerEmail && data.buyerPass) {
      setLoginData(data)
      setEmail(data.buyerEmail)
      setPassword(data.buyerPass)
      setCript(data.buyerPass)
    }
  }, [])

  return (
    <div className="logInMainContainer">
      <section className="logInSubContainer1">
        <img
          src={logo}
          className="logInSubContainer1Logo"
          alt={loginBuyerString.tuDataLogo}
        ></img>
        <h2 className="logInSubContainer1H2">
          {loginBuyerString.welcome}
          <br />
          {loginBuyerString.portal}
        </h2>
        <Link to="/" className="logInReturnParagraph">
          <img
            className="returnArrow"
            src={arrowIcon}
            alt={loginBuyerString.backArrow}
          />
          {loginBuyerString.back}
        </Link>
      </section>
      <section className="logInSubContainer2">
        <h1 className="login-col2__title1">{loginBuyerString.login}</h1>
        <h5 className="login-col2__title2">{loginBuyerString.welcomeText}</h5>
        <Form
          className="loginForm"
          onSubmit={(e) =>
            LoginFunctions.validateUser({
              e,
              email,
              cript,
              navigate,
              setModalMessage,
              handleShow,
              setContextInfo,
              contextInfo,
              sessionData,
              setSessionData,
              setIsRequesting,
              handleShowInactiveSessionModal,
              remindMe
            })
          }
        >
          <FloatingLabel label={loginBuyerString.Email}>
            <Form.Control
              className="logInSubContainer2Form1"
              autoComplete="off"
              id="email"
              type="email"
              placeholder={loginData.buyerEmail === '' ? ' ' : loginData.buyerEmail}
              value={loginData.buyerEmail}
              onBlur={() =>
                LoginFunctions.errorMail({ email, setshowIncorrectFormatEmail })
              }
              onChange={(e) => {
                LoginFunctions.handleEmailChange({ e, setEmail })
                setLoginData({ ...loginData, buyerEmail: e.target.value })
              }
              }
              onInput={(e) => maxSize(e, 'email')}
            />
            <span className={showIncorrectFormatEmail}>
              {loginBuyerString.spanFormatEmail}
            </span>
            {/* <span className={showIncorrectFormatEmail}>{login.spanFormatEmail}</span> */}
          </FloatingLabel>
          <FloatingLabel className="passwordInput" label={loginBuyerString.password}>
            <Form.Control
              className="logInSubContainer2Form2"
              autoComplete="off"
              id="password"
              type="password"
              name="logInPassword"
              placeholder={loginData.buyerPass === '' ?? ' '}
              value={loginData.buyerPass}
              onBlur={(e) =>
                LoginFunctions.errorFormat({
                  e,
                  criptTrue: true,
                  password,
                  setPassword,
                  setshowIncorrectFormat,
                  setCript
                  // setIsPasswordVisible
                })
              }
              onFocus={(e) =>
                LoginFunctions.decodePassword({ e, cript, setPassword })
              }
              onChange={(e) => {
                LoginFunctions.handlePass({ e, setPassword, setCript })
                setLoginData({ ...loginData, buyerPass: e.target.value })
              }
              }
              onInput={(e) => maxSize(e, 'password')}
            />
            <span className={showIncorrectFormatPass}>
              {loginBuyerString.spanFormatPass}
            </span>
            <Anchor
              className="showPassButtonLogIn"
              onClick={() =>
                LoginFunctions.eyeShowPassword(
                  'logInPassword',
                  setEyeIcon,
                  openEyeIcon,
                  closeEyeIcon
                )
              }
            >
              <img
                className="logInEyeIcon"
                src={eyeIcon}
                alt="show password icon"
              />
            </Anchor>
          </FloatingLabel>
          <div className="logInRememberContainer">
            <div className="logInRememberSubContainer">
              <input
                className="logInRecordarChk"
                id="logInRememberChk"
                type="checkbox"
                onClick={() => setRemindMe(!remindMe)}
              ></input>
              <label className="logInRecordarLbl" htmlFor="logInRememberChk">
                {loginBuyerString.remember}
              </label>
            </div>
            <Link
              className="logInRememberContainerLnk"
              to="/RecuperarContrasena"
            >
              {loginBuyerString.forgotPassword}
            </Link>
          </div>
          <button
            type="submit"
            className="logInBtnContinuar"
            disabled={isRequesting || !validateEmailFormat(email) || !validatePassFormat(cript)}
          >
            {loginBuyerString.getIn}
          </button>
        </Form>
        <p className="logInParagraph">
          {loginBuyerString.wanna}
          <Link to="/RegistroEmpresa" className="logInPargraphLink">
            {loginBuyerString.register}
          </Link>
        </p>
      </section>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button onClick={handleClose} className="Modal-greenBtn">
            {loginBuyerString.ok}
          </button>
        </Modal.Buttons>
      </Modal>
    </div>
  )
}
