export const formatDate = (date) => {
  const dateRaw = new Date(date)

  const year = dateRaw.getUTCFullYear()
  const month = String(dateRaw.getUTCMonth()).padStart(2, '0')
  const monthName = new Date(2000, month).toLocaleString('es-ES', { month: 'short' })
  const day = String(dateRaw.getUTCDate()).padStart(2, '0')

  const DateFormatted = `${day} ${monthName} ${year}`

  return DateFormatted
}

export const handleGoToBDPurchased = (e, navigate, contextInfo, setContextInfo) => {
  const id = e.target.id
  const dbNum = id.split('-')[1]
  setContextInfo({ ...contextInfo, purchasedDbNum: dbNum })
  navigate('DetalleDB')
}

export const handleClickBodyRow = (e, index) => {
  e.stopPropagation()
  document.getElementById(`bdPurchased-${index}`).click()
}
