import { handleRequestButton } from '../../utils/functions'
import { contactForm2, getPqrIds } from '../../utils/queries'
import { deleteAccounButBuyerString } from '../../utils/strings'
import { updateBuyerActive } from '../../utils/userqueries'
import {
  deleteSpacing,
  hasRepeatedCharactersAtMiddle,
  validateNoWhiteSpacesAtBeginning
} from '../../utils/validateFunctions'
// import { updateBuyerActive } from '../../utils/userqueries'

export const reasons = [
  {
    id: 'input1',
    option: deleteAccounButBuyerString.checkBoxOption1
  },
  {
    id: 'input2',
    option: deleteAccounButBuyerString.checkBoxOption2
  },
  {
    id: 'input3',
    option: deleteAccounButBuyerString.checkBoxOption3
  }
]

export const handleRequest = async ({
  e,
  reason,
  otherReason,
  deleteData,
  handleShow,
  contextInfo,
  setRadicate,
  btnDisabled,
  isRequesting,
  setIsRequesting,
  authContext
}) => {
  e.preventDefault()
  if (btnDisabled || isRequesting || (reason === 'input3' && otherReason.length < 15)) return

  const { sessionData, setSessionData } = authContext
  if (reason !== '') {
    let timeOutId = handleRequestButton(setIsRequesting)
    const pqrsResponse = await getPqrIds(authContext)
    if (pqrsResponse.response) {
      const pqrs = pqrsResponse.body
      const reasonOption = deleteData ? deleteAccounButBuyerString.deleteData : deleteAccounButBuyerString.deleteAccount
      const deleteReason = pqrs.find((item) => item.pqrsName === reasonOption)
      const foundReason = reasons.find((item) => item.id === reason)
      const content =
        deleteAccounButBuyerString.request +
        deleteReason.pqrsName +
        deleteAccounButBuyerString.reason +
        foundReason.option +
        deleteAccounButBuyerString.lineBreak +
        otherReason

      timeOutId = handleRequestButton(setIsRequesting, timeOutId)
      const res = await contactForm2({
        pqrsId: deleteReason.pqrsId,
        content,
        authContext
      })
      if (res && typeof res === 'object' && !('response' in res && 'data' in res)) {
        handleRequestButton(setIsRequesting, timeOutId)
        const signOut = await updateBuyerActive(authContext)
        if (signOut.response) {
          setRadicate(res.radicatedNum)
          handleShowModal({ e, handleShow })
          setSessionData({ ...sessionData, logged: false })
        }
      }
    }
  } else {
    alert(deleteAccounButBuyerString.selectAnOption)
  }
}

export const handleShowModal = ({ e, handleShow }) => {
  e.preventDefault()
  handleShow()
}

export const handleInputChange = (
  e,
  isInputChecked,
  setIsInputChecked,
  setReason
) => {
  const id = e.target.id
  setReason(id)
  setIsInputChecked({ ...isInputChecked, checkboxChecked: id })
}

export const handleOther = ({
  e,
  setOtherReason,
  isInputChecked,
  setIsInputChecked
}) => {
  const subject = e.target.value

  if (!validateNoWhiteSpacesAtBeginning(subject)) return
  if (hasRepeatedCharactersAtMiddle(subject)) return

  setOtherReason(subject)
  setIsInputChecked({ ...isInputChecked, checkboxChecked: e.target.name })
}

export const handleOnBlurOther = (e, setOtherReason) => {
  const subject = e.target.value
  setOtherReason(deleteSpacing(subject))
}

export const handleDeleteData = ({
  e,
  setDeleteData,
  isInputChecked,
  setIsInputChecked
}) => {
  setDeleteData(e.target.checked)
  setIsInputChecked({
    ...isInputChecked,
    inputDeleteAll: e.target.checked
  })
}
