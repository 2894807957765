import { signIn, fetchAuthSession, signOut } from 'aws-amplify/auth'
import { AppLocalStorageService } from './app-local-storage'
import { jwtDecode } from 'jwt-decode'

const localStorage = new AppLocalStorageService()

export class Amplify {
  jwtKeyName = this._btoa('jwt')

  async sign () {
    try {
      await signIn({
        username: process.env.REACT_APP_COGNITO_USER,
        password: process.env.REACT_APP_COGNITO_PASSWORD
      })
      const tokens = (await fetchAuthSession()).tokens
      const idToken = tokens.idToken.toString()
      this.setToken(idToken)
    } catch (err) {
      const tokens = (await fetchAuthSession()).tokens
      if (tokens) {
        const idToken = tokens.idToken.toString()
        this.setToken(idToken)
      }
      console.log(err)
    }
  }

  async signOut () {
    try {
      await signOut()
    } catch (err) {
      console.log(err)
    }
  }

  setToken (jwt) {
    localStorage.setItem(this.jwtKeyName, this._btoa(jwt))
  }

  async getToken () {
    let jwt = localStorage.getItem(this.jwtKeyName)
    if (!jwt) {
      const tokens = (await fetchAuthSession()).tokens
      jwt = tokens.idToken.toString()
      return jwt
    }
    const jwtOld = this._atob(jwt)
    return jwtOld
  }

  cleanToken () {
    localStorage.removeItem(this.jwtKeyName)
  }

  _btoa (value) {
    try {
      const ret = btoa(value)
      return ret
    } catch (e) {
      return ''
    }
  }

  _atob (value) {
    try {
      const ret = atob(value)
      return ret
    } catch (e) {
      return ''
    }
  }

  validateToken (jwt) {
    const decoded = jwtDecode(jwt)
    const isAuth = !!decoded && !!decoded.exp && new Date(decoded.exp * 1000) > new Date()
    return isAuth
  }
}
