import { validateNumberOfUsersWhoWishToContactThem } from '../../../../utils/userqueries'
import {
  DataBaseBuyerStrings,
  databaseFunctionsBuyerString
} from '../../../../utils/strings'
import {
  activateRequestButton,
  handleRequestButton
} from '../../../../utils/functions'

const planTypeMapping = {
  planBDSimple: 1,
  planBDEspecializada: 2
}

export const handleSubmit = async (
  navigate,
  planType,
  contextInfo,
  setContextInfo,
  setModalMessage,
  handleShow,
  authContext,
  isRequesting,
  setIsRequesting
) => {
  if (isRequesting) return

  const { sessionData } = authContext
  const { buyerId } = sessionData
  const planTypeNum = planTypeMapping[planType]

  if (!planTypeNum) {
    throw new Error(databaseFunctionsBuyerString.undefinedPlan)
  }

  try {
    if (planTypeNum === 1) {
      const timeOutId = handleRequestButton(setIsRequesting)
      const validateResponse = await validateNumberOfUsersWhoWishToContactThem(
        planTypeNum,
        authContext
      )

      if (validateResponse.body && validateResponse.body.count !== undefined) {
        const count = validateResponse.body.count

        if (count < 1) {
          activateRequestButton(setIsRequesting, timeOutId)
          setModalMessage(
            DataBaseBuyerStrings.dataBasePriceStrings.notUsersToBuy
          )
          handleShow()
        } else {
          const total = count
          setContextInfo({ ...contextInfo, usersWhoWishToContactThem: total })
          navigate(`/buyer/${buyerId}/BaseDeDatos/CompraBaseDatosPaso1`)
        }
      }
    } else {
      navigate('../CategoriasBDUbicacion')
    }
  } catch (error) {
    throw new Error(error)
  }
}
