import React from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import principalLogo from '../../assets/icons/logo.svg'

// Utils
import { successfulRegisterBuyerString } from '../../utils/strings'

import './SucessfulRegister.css'

export const SuccessfulRegister = () => {
  const navigate = useNavigate()

  const handleNextButtonClick = () => {
    navigate('/login')
  }
  return (
    <section className="successfulRegister">
      <img src={principalLogo} alt="logo principal" className="successfulRegisterLogo" />
      <div className="successfulRegisterPrincipalContent">
        <div className="successfulRegisterPrincipalMessage">
          <h1 className="successfulRegisterTitle">
            {successfulRegisterBuyerString.successfulRegister}
          </h1>
          <p className="successfulRegisterPurchasedPlan">
            {successfulRegisterBuyerString.validatingData}
          </p>
        </div>
        <p className="successfulRegisterSecondaryMessage">
          {successfulRegisterBuyerString.checkEmail}<span className="successfulRegisterTuData">{successfulRegisterBuyerString.tudata}.</span>
        </p>
        <button className='successfulRegisterNextButton' onClick={handleNextButtonClick}>
          {successfulRegisterBuyerString.continue}
        </button>
      </div>
    </section>
  )
}
