import { activateRequestButton, handleRequestButton } from '../../utils/functions'
import { buyDataBase } from '../../utils/userqueries'

export const handleInputChange = ({ setCode, e, index }) => {
  const pivot = e.target.value.charAt(0)
  const value = /^[0-9]+$/.test(pivot) ? pivot : ''

  setCode(
    (prev) => prev.substring(0, index) + value + prev.substring(index + 1)
  )

  if (index < 5 && value) {
    e.target.nextElementSibling.focus()
  } else if (index === 5) {
    e.target.blur()
  }
}

export const handleSubmit = async (
  e,
  navigate,
  code,
  sessionData,
  setSessionData,
  isRequesting,
  setIsRequesting,
  handleShowInactiveSessionModal
) => {
  e.preventDefault()

  if (isRequesting) return

  const timeOutId = handleRequestButton(setIsRequesting)

  const { authTk, companyId, buyerId } = sessionData
  const requestData = {
    companyId,
    buyerId,
    code
  }
  const reqBuyDb = await buyDataBase(
    requestData,
    authTk,
    navigate,
    setSessionData,
    handleShowInactiveSessionModal
  )
  if (reqBuyDb?.response) navigate('../../../TransaccionExitosa')

  activateRequestButton(setIsRequesting, timeOutId)

  // Revisar: shoppingHistory -> usersBuyed -> reward
  // Borrar (fallo): reward -> usersBuyed -> shoppingHistory
}
