import React, { useContext, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { loopayCodeString } from '../../utils/strings'
import './LoopayCode.css'
import { handleSubmit } from './LoopayCodeFunctions'
import { AuthContext } from '../../context/AuthContext'
// import { DataContext } from '../../context/DataContext'
import { useNavigate } from 'react-router-dom'
import { handleCode, handlePaste } from '../../utils/functions'

export const LoopayCode = () => {
  const navigate = useNavigate()
  const { sessionData, setSessionData, handleShowInactiveSessionModal } = useContext(AuthContext)
  // const { contextInfo } = useContext(DataContext)
  const [isRequesting, setIsRequesting] = useState(false)
  const [code, setCode] = useState('')
  const [, setFirstInput] = useState(null)

  return (
    <div className='mainContainerLoopayCode'>
      {/* <p className='firstParagraphLoopayCode'>
        {loopayCodeString.entityCode}{''}
        <span className='firstParagraphSpanLoopayCode'>{state.name}</span>
      </p> */}
      <p className='secondParagraphLoopayCode'>{loopayCodeString.codeSent}</p>
      <p className='secondParagraphLoopayCode'>{loopayCodeString.enterCode}</p>
      <Form
        className='formContainerLoopayCode'
        noValidate
        validated={true}
        onSubmit={(e) => handleSubmit(
          e,
          navigate,
          code,
          sessionData,
          setSessionData,
          isRequesting,
          setIsRequesting,
          handleShowInactiveSessionModal)}
      >
        <div className='inputContainerLoopayCode'>
          <Form.Control.Feedback className={'invalidFeedback'} type='invalid'>
            {/* Mensaje de formato inválido */}
          </Form.Control.Feedback>
          {Array.from(new Array(6), (_, i) => i).map((index) => (
            <Form.Control
              key={`input_${index}`}
              className='inputCodeLoopayCode'
              type='text'
              onChange={(e) => handleCode(e, index, code, setCode)}
              onPaste={(e) => handlePaste(e, index, code, setCode)}
              value={code[index] || ''}
              required
              maxLength="1"
              ref={index === 0 ? (input) => setFirstInput(input) : null}
            />
          ))}
        </div>
        <div className='checkBoxesContainerLoopayCode'>
          <button
            type='submit'
            disabled={code < 6 || isRequesting}
            className='sendCodeBtn'>
            {loopayCodeString.sendCode}
          </button>
        </div>
      </Form >
      <hr className='hrLoopayCode' />
    </div >
  )
}
