import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion'
import iconSearch from '../../assets/icons/icon_lupa.svg'
import iconInfo from '../../assets/icons/icon_info2.svg'
import { customerSupportString } from '../../utils/strings'
import {
  handleChangeSearchRadicate,
  handleCloseModal,
  questionsAndAnswers
} from './CustomerSupportFunctions'
import { AuthContext } from '../../context/AuthContext'
import { Modal } from '../../components/Modal/Modal'
import { useModal } from '../../hooks/useModal'
import IconCheck from '../../assets/icons/icon_bien.svg'
import './CustomerSupport.css'

export const CustomerSupport = () => {
  const authContext = useContext(AuthContext)

  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState('')
  const [radicateValue, setRadicateValue] = useState('')
  const [isValidRadicated, setIsValidRadicated] = useState(false)
  return (
    <section className="customerSupportMainContainer">
      <input
        className="customerSupportTextInput"
        type="text"
        placeholder={customerSupportString.enterYourRegistrationNumber}
        maxLength={9}
        value={radicateValue}
        onChange={(e) =>
          handleChangeSearchRadicate(
            e,
            setRadicateValue,
            authContext,
            handleShow,
            setModalMessage,
            setIsValidRadicated
          )
        }
      />
      <img
        className="customerSupportSearchIcon"
        src={iconSearch}
        alt="icono lupa"
      />
      <div className="customerSupportSubContainer">
        <div className="customerSupportImgPContainer">
          <img
            className="customerSupportInfoIcon"
            src={iconInfo}
            alt="info icon"
          />
          <p className="customerSupportMainParagraph">
            {customerSupportString.checkRequest}
          </p>
        </div>
        <Link to="Nuevapqrs" className="customerSupportButton">
          {customerSupportString.newPqrs}
        </Link>
      </div>
      <h2 className="customerSupportH2">{customerSupportString.faq}</h2>
      <Accordion className="customerSupportAccordion" defaultActiveKey="">
        {questionsAndAnswers.map((item, id) => (
          <Accordion.Item
            eventKey={item}
            key={id + item}
            className="customerSupportAccordionItem"
          >
            <Accordion.Header>{item.question}</Accordion.Header>
            <Accordion.Body className="customerSupportAccordionItemContent">
              {item.answer}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <Modal isOpen={showModal}>
        <Modal.Content>
          {isValidRadicated && (
            <div className="modalHeader">
              <img
                className="buyDBUploadReceiptModalIcon"
                src={IconCheck}
                alt="logo check"
              />
              <p className="modalSettled">
                {`${customerSupportString.filingNumber}${radicateValue}`}
              </p>
            </div>
          )}
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            onClick={() => handleCloseModal(setRadicateValue, handleClose)}
            className="Modal-orangeBtn"
          >
            {customerSupportString.ok}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
