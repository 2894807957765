import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Modal } from '../Modal/Modal'
import { useModal } from '../../hooks/useModal'
import { NavFooter } from '../NavFooter/NavFooter'
import personIcon from '../../assets/icons/icon_userless.svg'
import './BaseLayout.css'
import { useLayoutByLocation } from '../../hooks/useLayoutByLocation'
import { pathAndLayout } from './utils/layoutData'
import { useTour } from '../../hooks/useTour'
import {
  ConfigStepsBuyerString,
  DataBaseStepsBuyerString,
  DetailDBStepsBuyerString,
  HomeStepsBuyer
} from '../../utils/tourStrings'
import { BaseLayoutBuyerString } from '../../utils/strings'
import { DataContext } from '../../context/DataContext'
import { AuthContext } from '../../context/AuthContext'
import { getBuyerActiveValidation } from '../../utils/userqueries'

export const BaseLayout = ({ children }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [tourPath, setTourPath] = useState(HomeStepsBuyer)
  const [isTourApp, setIsTourApp] = useState(true)
  const tour = useTour('LS_KEY', tourPath)
  const { contextInfo } = useContext(DataContext)
  const authContext = useContext(AuthContext)
  const [showModal, handleShow, handleClose] = useModal()

  const validateBuyerActive = async () => {
    const buyerPat = contextInfo.sessionInfo.findBuyer.buyer.buyerId
    const responseValidation = await getBuyerActiveValidation(buyerPat, authContext)
    return responseValidation.response
  }

  const handleNavLinkClick = async (e) => {
    e.preventDefault()
    const isBuyerActive = await validateBuyerActive()
    if (isBuyerActive) {
      navigate('Ajustes/CondicionesYPoliticas/Eliminarcuenta')
    } else {
      handleShow()
    }
  }

  useEffect(() => {
    if (pathname.endsWith('Cuenta')) setTourPath(HomeStepsBuyer)
    else if (pathname.endsWith('BaseDeDatos')) setTourPath(DataBaseStepsBuyerString)
    else if (pathname.endsWith('DetalleDB')) setTourPath(DetailDBStepsBuyerString)
    else if (pathname.includes('Ajustes')) setTourPath(ConfigStepsBuyerString)
    else setIsTourApp(false)
  }, [pathname])

  return (
    <>
      {isTourApp && tour}
      <section id="BaseLayout">
        <div className="BaseLayoutHeader">
          {useLayoutByLocation(pathAndLayout)}
        </div>
        <hr className="hrBaseLayout" />
        <section className="pageContent">{children}</section>
      </section>
      {pathname.endsWith('CondicionesYPoliticas')
        ? (
        <NavFooter noBackLinkClass="hidden">
          <NavLink
            to="Ajustes/CondicionesYPoliticas/Eliminarcuenta"
            className="conditionsPoliciesFooterButton"
            onClick={handleNavLinkClick}
          >
            <img
              className="conditionsPoliciesFooterButtonImg"
              src={personIcon}
              alt="Person Icon"
            />
            {BaseLayoutBuyerString.deleteAccount}
          </NavLink>
        </NavFooter>
          )
        : pathname.endsWith('CambioDeContrasena') ||
        pathname.endsWith('AtencionAlCliente') ||
        pathname.endsWith('Cuenta') ||
        pathname.endsWith('BaseDeDatos') ||
        pathname.endsWith('CodigoLoopay')
          ? null
          : (
        <NavFooter />
            )}
      <Modal isOpen={showModal}>
        <Modal.Content>
          <img src="" alt="" />
          <p>
            {BaseLayoutBuyerString.notValidatedTitle}
            <br />
            {BaseLayoutBuyerString.notValidatedBody}
          </p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-greenBtn' onClick={handleClose}>OK</button>
        </Modal.Buttons>
      </Modal>
    </>
  )
}
