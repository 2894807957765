import React, { useState } from 'react'
import {
  landingPageBuyerString,
  usModalBuyerString
} from '../../../../utils/strings'
import './UsView.css'

export const UsView = () => {
  const [openItem, setOpenItem] = useState({
    whoAreUs: true,
    mision: true,
    vision: true
  })

  return (
    <div className="contentUs">
      <div className="backgroundUs">
        <div className="accordionContentUs">
          <label className="labelUs">{landingPageBuyerString.usTitle}</label>
          <article
            className={`itemUs ${openItem.whoAreUs ? 'itemOpened' : ''}`}
            onClick={() =>
              setOpenItem({ ...openItem, whoAreUs: !openItem.whoAreUs })
            }
          >
            <h5>{landingPageBuyerString.aboutUs}</h5>
            {openItem.whoAreUs && (
              <p>
                {usModalBuyerString.usText1}
                <strong>{usModalBuyerString.tudata}</strong>
                {usModalBuyerString.usText2}
              </p>
            )}
          </article>
          <article
            className={`itemUs ${openItem.mision ? 'itemOpened' : ''}`}
            onClick={() =>
              setOpenItem({ ...openItem, mision: !openItem.mision })
            }
          >
            <h5>{landingPageBuyerString.mision}</h5>
            {openItem.mision && <p>{usModalBuyerString.usMision}</p>}
          </article>
          <article
            className={`itemUs ${openItem.vision ? 'itemOpened' : ''}`}
            onClick={() =>
              setOpenItem({ ...openItem, vision: !openItem.vision })
            }
          >
            <h5>{landingPageBuyerString.vision}</h5>
            {openItem.vision && <p>{usModalBuyerString.usVision}</p>}
          </article>
        </div>
      </div>
    </div>
  )
}
