import {
  activateRequestButton,
  handleRequestButton,
  passwordEncrypt
} from '../../utils/functions'
import { getPurchasedDB, saveUserData } from '../../utils/queries'
import { loginFunctionsBuyerString } from '../../utils/strings'
import { buyerLogin } from '../../utils/userqueries'
import {
  validateDataBaseExpiration,
  validateEmailFormat,
  validatePassFormat
} from '../../utils/validateFunctions'

export const decodePassword = ({ e, cript, setPassword }) => {
  setPassword(cript)
  e.target.value = cript
}

export const errorMail = ({ email, setshowIncorrectFormatEmail }) => {
  if (email !== '') {
    if (!validateEmailFormat(email)) {
      setshowIncorrectFormatEmail('incorrectFormat')
    } else {
      setshowIncorrectFormatEmail('showSpanPass')
    }
  }
}

export const errorFormat = ({
  e,
  criptTrue,
  password,
  setPassword,
  setshowIncorrectFormat,
  setCript,
  setIsPasswordVisible
}) => {
  if (password !== '') {
    if (!validatePassFormat(password)) {
      setshowIncorrectFormat('incorrectFormat')
    } else {
      setshowIncorrectFormat('showSpanPass')
    }
  }
  if (!criptTrue) {
    setCript(password)
    const random =
      password !== '' ? Math.random().toString(36).substring(2, 15) : ''
    setPassword(random)
    e.target.value = random
    setIsPasswordVisible(false)
  }
}

export const handleEmailChange = ({ e, setEmail }) => {
  setEmail(e.target.value)
}

export const handleEyeIconClick = (
  inputType,
  setInputType,
  eyeIcon,
  setEyeIcon,
  setIsPasswordVisible,
  isPasswordVisible,
  changePassword,
  openEyeIcon,
  cript,
  closeEyeIcon,
  password,
  setPassword
) => {
  setIsPasswordVisible(!isPasswordVisible)
  inputType === `${changePassword.password}`
    ? setInputType(`${changePassword.text}`)
    : setInputType(`${changePassword.password}`)
  if (eyeIcon === openEyeIcon) {
    document.getElementById('password').value = cript
    setEyeIcon(closeEyeIcon)
  } else {
    const random =
      password !== '' ? Math.random().toString(36).substring(2, 15) : ''
    setPassword(random)
    document.getElementById('password').value = random
    setEyeIcon(openEyeIcon)
  }
}

export const handleKeyPress = ({
  e,
  email,
  cript,
  navigate,
  setModalMessage,
  handleShow,
  password,
  setPassword,
  setshowIncorrectFormat,
  setCript,
  setIsPasswordVisible,
  setIsRequesting,
  setContextInfo,
  contextInfo,
  sessionData,
  setSessionData,
  handleShowInactiveSessionModal,
  remindMe
}) => {
  if (e.key === 'Enter') {
    validateUser({
      e,
      email,
      cript,
      navigate,
      setModalMessage,
      handleShow,
      setContextInfo,
      contextInfo,
      sessionData,
      setSessionData,
      setIsRequesting,
      handleShowInactiveSessionModal,
      remindMe
    })
    errorFormat({
      e,
      criptTrue: true,
      password,
      setPassword,
      setshowIncorrectFormat,
      setCript,
      setIsPasswordVisible
    })
  }
}

export const handlePass = ({ e, setPassword, setCript }) => {
  setPassword(e.target.value)
  setCript(e.target.value)
}

export const reset = (
  inputType,
  setInputType,
  eyeIcon,
  setEyeIcon,
  openEyeIcon,
  closeEyeIcon
) => {
  setEyeIcon(openEyeIcon)
  if (eyeIcon === closeEyeIcon) {
    if (inputType === 'text') {
      setInputType('password')
    }
  }
}

export const validateUser = async ({
  e,
  email,
  cript,
  navigate,
  setModalMessage,
  handleShow,
  setContextInfo,
  contextInfo,
  sessionData,
  setSessionData,
  setIsRequesting,
  handleShowInactiveSessionModal,
  remindMe
}) => {
  e.preventDefault()
  if (remindMe) {
    saveUserData(email, cript)
  }
  let timeOutId = handleRequestButton(setIsRequesting)

  if (validateEmailFormat(email) && validatePassFormat(cript)) {
    const password = passwordEncrypt(cript)
    timeOutId = handleRequestButton(setIsRequesting, timeOutId)
    const buyerData = await buyerLogin(email, password)

    handleRequestButton(setIsRequesting, timeOutId)
    const dbpurchased = await getPurchasedDB(
      buyerData.body?.findBuyer.buyer.buyerId,
      buyerData.body?.loginCognito.idToken,
      navigate,
      handleShowInactiveSessionModal
    )
    let databasesStates = []
    let numberOfActiveDataBases = 0
    if (dbpurchased?.body?.length > 0) {
      databasesStates = validateDataBaseExpiration(dbpurchased?.body)
      if (databasesStates.length > 0) {
        numberOfActiveDataBases = databasesStates.filter(
          (item) => item === 'Activa'
        ).length
      }
    }
    setSessionData({
      ...sessionData,
      buyerId: buyerData.body?.findBuyer.buyer.buyerId,
      companyId: buyerData.body?.findBuyer.buyer.companyId.companyId,
      companyComertialName:
        buyerData.body?.findBuyer.buyer.companyId.companyComertialName,
      buyerAddress: buyerData.body?.findBuyer.buyer.buyerAddress,
      buyerLegalRepresentative:
        buyerData.body?.findBuyer.buyer.buyerLegalRepresentative,
      buyerContactName: buyerData.body?.findBuyer.buyer.buyerContactName,
      buyerCredentialEmail: buyerData.body?.findBuyer.buyerCredentialEmail,
      buyerRepresentativeIdNum:
        buyerData.body?.findBuyer.buyer.buyerRepresentativeIdNum,
      logged: buyerData.body?.findBuyer.buyerCredentialEmail ? true : undefined,
      authTk: buyerData.body?.loginCognito.idToken
    })
    setContextInfo({
      ...contextInfo,
      sessionInfo: buyerData.body,
      tokens: buyerData.body?.loginCognito,
      dbpurchased: dbpurchased?.body,
      databasesStates,
      numberOfActiveDataBases
    })
    if (buyerData.statusCode === 500 || buyerData.statusCode === 404) {
      setContextInfo({ ...contextInfo, loginEmail: email, loginPass: cript })
      navigate('/VerificationCode')
    } else if (buyerData.data?.code?.includes('FAIL_LOGIN')) {
      setContextInfo({ ...contextInfo, loginEmail: email, loginPass: cript })
      navigate('/VerificationCode')
    } else if (buyerData.response) {
      const dataFound = buyerData.body.findBuyer
      if (dataFound.buyer.buyerId) {
        navigate(`/buyer/${buyerData.body.findBuyer.buyer.buyerId}/Cuenta`)
      } else {
        activateRequestButton(setIsRequesting, timeOutId)
        setModalMessage(loginFunctionsBuyerString.userNotExits)
        handleShow()
      }
    } else {
      if (buyerData.data?.code?.includes('USER_NOT_TRUE')) {
        activateRequestButton(setIsRequesting, timeOutId)
        setModalMessage(loginFunctionsBuyerString.attemptsLimit)
        handleShow()
      } else if (buyerData.data?.code?.includes('BUYER_NOT_FOUND')) {
        activateRequestButton(setIsRequesting, timeOutId)
        setModalMessage(loginFunctionsBuyerString.userNotExits)
        handleShow()
      } else if (buyerData.data?.code?.includes('BUYER_NOT_ACTIVE')) {
        activateRequestButton(setIsRequesting, timeOutId)
        setModalMessage(loginFunctionsBuyerString.accountInElimination)
        handleShow()
      } else if (
        buyerData.data?.code?.includes('BUYER_NOT_ENABLE_TRY_IN_ONE_MINUTE')
      ) {
        activateRequestButton(setIsRequesting, timeOutId)
        setModalMessage(loginFunctionsBuyerString.attemptsLimit)
        handleShow()
      } else {
        activateRequestButton(setIsRequesting, timeOutId)
        setModalMessage(loginFunctionsBuyerString.incorrectPassword)
        handleShow()
      }
    }
  }
}

export const eyeShowPassword = (
  inputName,
  setEyeIcon,
  openEyeIcon,
  closeEyeIcon
) => {
  const element = document.getElementsByName(inputName)[0]
  if (element.type === 'password') {
    element.type = 'text'
    setEyeIcon(closeEyeIcon)
  } else {
    element.type = 'password'
    setEyeIcon(openEyeIcon)
  }
  element.focus()
}

const LoginFunctions = {
  decodePassword,
  errorMail,
  errorFormat,
  handleEmailChange,
  handleKeyPress,
  handlePass,
  validateUser,
  eyeShowPassword
}

export default LoginFunctions
