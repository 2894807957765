import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/icons/logo.svg'
import { compBuyerString, landingPageBuyerString } from '../../utils/strings'
import Error404 from '../../assets/images/404.png'

import './NotFound.css'

export const NotFound = () => {
  return (
    <div className="mainContainerNotFound">
      <div className="contendLogo_Nav">
        <Link to="/">
          <img
            className="logoAppNotFound"
            src={logo}
            alt={landingPageBuyerString.logoTuData}
          />
        </Link>
      </div>
      <div className="containerNotFound">
        <div className="subContainerNotFound1">
          <img src={Error404} alt="Error404" className='subContainerNotFoundImg' />
          <h1 className="mainTitleNotFound">{compBuyerString.errorMessage}</h1>
        </div>
      </div>
    </div>
  )
}
