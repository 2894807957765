import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { passwordRecoveryBuyerStrings } from '../../../utils/strings'
import * as functions from './SetEmailFunctions'
import { Form } from 'react-bootstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { DataContext } from '../../../context/DataContext'
import { Modal } from '../../../components/Modal/Modal'
import { useModal } from '../../../hooks/useModal'
import './../PasswordRecovery.css'
import { validateEmailFormat } from '../../../utils/validateFunctions'

export const SetEmail = () => {
  const { contextInfo, setContextInfo } = useContext(DataContext)

  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState('')
  const [email, setEmail] = useState('')

  const [isRequesting, setIsRequesting] = useState(false)

  const navigate = useNavigate()
  useEffect(() => {
    setContextInfo({ ...contextInfo, passwordRecoverylocation: 'SetEmail', emailPasswordRecovery: '' })
  }, [])

  return (
    <Form className='passwordRecoveryRSideCodeForm' onSubmit={(e) =>
      functions.handleSubmit(
        e,
        navigate,
        contextInfo,
        handleShow,
        setModalMessage,
        isRequesting,
        setIsRequesting,
        email
      )}>
      <div className='passwordRecoveryRSideInputsGroup'>
        <FloatingLabel label={passwordRecoveryBuyerStrings.email}>
          <Form.Control
            name='emailPasswordRecovery'
            type='email'
            pattern="[^\s]+"
            autoComplete='off'
            placeholder=' '
            required
            value={email}
            onChange={(e) => functions.hadleFormChange(e, contextInfo, setContextInfo, setEmail)}
            onBlur={() => functions.handleOnBlurEmail(email, setEmail)}
          />
        </FloatingLabel>
      </div>
      <button
        className='passwordRecoveryRSideSendBtn'
        type='submit'
        disabled={!validateEmailFormat(email) || isRequesting}>
        {passwordRecoveryBuyerStrings.send}
      </button>
      <Modal isOpen={showModal}>
        <Modal.Content>
          {modalMessage}
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-greenBtn' onClick={handleClose}>{passwordRecoveryBuyerStrings.ok}</button>
        </Modal.Buttons>
      </Modal>
    </Form>
  )
}
