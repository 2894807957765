import React from 'react'

import './GrayCard.css'

export const GrayCard = ({ title, children }) => {
  return (
    <div className="grayCard">
      <h2 className="grayCardTitle">
        {title}
      </h2>
      {children}
    </div>
  )
}
