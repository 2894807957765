import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { categoriesBuyerStrings } from '../../../../utils/strings'
import whiteBackIcon from '../../../../assets/icons/icon_flecha_blanco.svg'
import iconLupa from '../../../../assets/icons/icon_lupa-8.png'
import { SubCategoriesItemsCard } from '../../components/SubCategoriesItemsCard/SubCategoriestemsCard'
import { CategoriesContext } from '../../../../context/CategoriesContext'
import { getElements } from '../../../../utils/queries'
import './SubCategoriesItems.css'
import { AuthContext } from '../../../../context/AuthContext'
import { searchItem } from '../../../../utils/functions'

export const SubCategoriesItems = () => {
  const navigate = useNavigate()
  const { categoriesSelected, setCategoriesSelected } =
    useContext(CategoriesContext)
  const authContext = useContext(AuthContext)
  const { sessionData } = authContext
  const { authTk } = sessionData
  const { subCatSelected } = categoriesSelected
  const [elementSelected, setElementSelected] = useState([])
  const { state: planType } = useLocation()

  const [item, setItem] = useState({
    subcategoriesItemsSearch: ''
  })
  const [pivotList, setPivotList] = useState([])
  const [filteredList, setFilteredList] = useState([])

  useEffect(() => {
    const getSubCatItems = async () => {
      const elements = await getElements(authContext)
      if (elements) {
        const elementFiltered = elements.filter((element) =>
          subCatSelected.some(
            (subCat) => subCat.name === element.subcategoryName
          )
        )
        setPivotList(elementFiltered)
        setFilteredList(elementFiltered)
      }
    }

    if (authTk) {
      getSubCatItems()
    }
  }, [subCatSelected])

  useEffect(() => {
    if (categoriesSelected.elementSelected) {
      const catAlreadySelected = filteredList.filter((subCat) => categoriesSelected.elementSelected.some((item) => item.elementId === subCat.elementId))
      setElementSelected(catAlreadySelected)
    }
  }, [filteredList])

  const handleNextCategory = (selection) => {
    if (!selection.length) return

    setCategoriesSelected({ ...categoriesSelected, elementSelected })
    navigate('/TusEleccionesBD', { state: planType })
  }

  const isAlreadySelected = (id) => {
    const isSelected = elementSelected.some((item) => item.elementId === id)
    if (isSelected) return true
    else return false
  }

  return (
    <section id="SubCategoriesItems">
      <div className="SubCategoriesItemsContent">
        <div className="SubCategoriesItemsSearch">
          <div className="SubCategoriesItemsSearchInputContainer">
            <input
              placeholder="Buscar"
              type="text"
              className="SubCategoriesItemsSearchInput"
              name="subcategoriesItemsSearch"
              onChange={(e) =>
                searchItem(e, item, setItem, pivotList, setFilteredList)
              }
            />
            <button className="SubCategoriesItemsSearchBtn">
              <img src={iconLupa} alt="iconLupa" />
            </button>
          </div>
        </div>
        <div className="SubCategoriesItemsList">
          {subCatSelected?.map((subCategory) => (
            <div
              key={subCategory.id}
              className="SubCategoriesItemsListCategory"
            >
              <h2>{`${subCategory.categoryName} / ${subCategory.name}`}</h2>
              <div className="SubCategoriesItemsListCategoryGrid">
                {filteredList?.map(
                  (item) =>
                    item.subcategoryName === subCategory.name && (
                      <SubCategoriesItemsCard
                        key={item.elementId}
                        name={item.elementName}
                        elementsData={filteredList}
                        elementSelected={elementSelected}
                        setElementSelected={setElementSelected}
                        isAlreadySelected={isAlreadySelected(item.elementId)}
                      />
                    )
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="SubCategoriesItemsFooter">
          <div className="SubCategoriesItemsFooterContent">
            <div>
              <Link to="../SubCategoriasBD">
                <img
                  className="SubCategoriesItemsFooterBackBtn"
                  src={whiteBackIcon}
                  alt="nextIcon"
                />
                <p>{categoriesBuyerStrings.back}</p>
              </Link>
            </div>
            <div>
              <button
                onClick={() => handleNextCategory(elementSelected)}
                className="SubCategoriesItemsFooterNextBtn"
                disabled={!elementSelected.length}
              >
                <p>{categoriesBuyerStrings.continue}</p>
                <img src={whiteBackIcon} alt="nextIcon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
