import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { categoriesBuyerStrings } from '../../../../utils/strings'
import { CategoriesCard } from '../../components/CategoriesCard/CategoriesCard'
import { CategoriesContext } from '../../../../context/CategoriesContext'
import { AuthContext } from '../../../../context/AuthContext'
import { validateNumberOfSelectionsbyAge } from '../../../../utils/userqueries'
import appLogo from '../../../../assets/icons/logo.svg'
import kidImg from '../../../../assets/images/kid.svg'
import teenImg from '../../../../assets/images/teen.svg'
import adultImg from '../../../../assets/images/adult.svg'
import seniorImg from '../../../../assets/images/senior.svg'
import whiteBackIcon from '../../../../assets/icons/icon_flecha_blanco.svg'

import './CategoriesAge.css'
import { useModal } from '../../../../hooks/useModal'
import { Modal } from '../../../../components/Modal/Modal'

const ageCategories = [
  {
    id: 1,
    name: 'Kid',
    min: 18,
    max: 25,
    age: '18 - 25',
    image: kidImg
  },
  {
    id: 2,
    name: 'Teen',
    min: 26,
    max: 35,
    age: '26 - 35',
    image: teenImg
  },
  {
    id: 3,
    name: 'Adult',
    min: 36,
    max: 50,
    age: '36 - 50',
    image: adultImg
  },
  {
    id: 4,
    name: 'Senior',
    min: 51,
    max: 100,
    age: '51 - En adelante',
    image: seniorImg
  }
]

export const CategoriesAge = () => {
  const { state: planType } = useLocation()
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const { categoriesAgeStrings } = categoriesBuyerStrings
  const { categoriesSelected, setCategoriesSelected } =
    useContext(CategoriesContext)
  const [showModal, handleShow, handleClose] = useModal()
  const [ageSelected, setAgeSelected] = useState([])
  const [modalData, setModalData] = useState(0)

  const handleNextCategory = async (selection) => {
    if (!Object.keys(selection).length) return

    const minAgeSelected = ageSelected.reduce((minAge, current) => {
      return current.min < minAge.min ? current : minAge
    }, ageSelected[0])
    const maxAgeSelected = ageSelected.reduce((maxAge, current) => {
      return current.max > maxAge.max ? current : maxAge
    }, ageSelected[0])
    const age = { min: minAgeSelected.min, max: maxAgeSelected.max }
    setCategoriesSelected({ ...categoriesSelected, age })
    const amount = await validateNumberOfSelectionsbyAge(
      authContext,
      categoriesSelected.city,
      categoriesSelected.gender,
      age
    )
    // modal alert continuar / corregir
    setModalData(amount?.count)
    handleShow()
  }

  useEffect(() => {
    if (categoriesSelected.age) {
      const isCatMinSelected = ageCategories.filter((age) => categoriesSelected.age.min === age.min)
      const isCatMaxSelected = ageCategories.filter((age) => categoriesSelected.age.max === age.max)
      if (JSON.stringify(isCatMinSelected) === JSON.stringify(isCatMaxSelected)) setAgeSelected(isCatMinSelected)
      else setAgeSelected([...isCatMinSelected, ...isCatMaxSelected])
    }
  }, [])

  const isAlreadySelected = (id) => {
    const isSelected = ageSelected.some((item) => item.id === id)
    if (isSelected) return true
    else return false
  }

  return (
    <section id="CategoriesAge">
      <div className="CategoriesAgeContent">
        <div className="CategoriesAgeLogoBox">
          <img src={appLogo} alt="appLogo" />
        </div>
        <div className="CategoriesAgeText">
          <p>{categoriesBuyerStrings.titleParagraph}</p>
        </div>
        <div className="CategoriesAgeGrid">
          {ageCategories.map((data) => (
            <CategoriesCard
              key={data.id}
              name={data.name}
              image={data.image}
              cardName={data.age}
              categoryData={ageCategories}
              categorySelected={ageSelected}
              setCategorySelected={setAgeSelected}
              isAlreadySelected={isAlreadySelected(data.id)}
            />
          ))}
        </div>
        <div className="CategoriesAgeFooter">
          <div className="CategoriesAgeFooterContent">
            <div>
              <Link to="../CategoriasBDGenero">
                <img
                  className="CategoriesAgeFooterBackBtn"
                  src={whiteBackIcon}
                  alt="nextIcon"
                />
                <p>{categoriesBuyerStrings.back}</p>
              </Link>
            </div>
            <p>{categoriesAgeStrings.age}</p>
            <div>
              <button
                onClick={() => handleNextCategory(ageSelected)}
                className="CategoriesAgeFooterNextBtn"
                disabled={!Object.keys(ageSelected).length}
              >
                <p>{categoriesBuyerStrings.next}</p>
                <img src={whiteBackIcon} alt="nextIcon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{categoriesAgeStrings.amount}{modalData}</p>
        </Modal.Content>
        <Modal.Buttons>
          {modalData === 0
            ? (
            <button className="Modal-greenBtn" onClick={handleClose}>
              {categoriesAgeStrings.ok}
            </button>
              )
            : (
            <>
              <button className="Modal-normalBtn" onClick={handleClose}>
                {categoriesAgeStrings.edit}
              </button>
              <button
                className="Modal-orangeBtn"
                onClick={() =>
                  navigate('/CategoriasBDGeneral', { state: planType })
                }
              >
                {categoriesAgeStrings.continue}
              </button>
            </>
              )}
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
