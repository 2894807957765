import {
  activateRequestButton,
  handleRequestButton,
  passwordEncrypt
} from '../../utils/functions'
import { getPurchasedDB, validateBuyerQuery } from '../../utils/queries'
import { firstSignInBuyerStrings } from '../../utils/strings'
import { validateDataBaseExpiration } from '../../utils/validateFunctions'

export const handleSubmit = async (
  e,
  setVerificationCodeClass,
  code,
  setCode,
  navigate,
  contextInfo,
  setContextInfo,
  setIsAValidCode,
  sessionData,
  setSessionData,
  isRequesting,
  setIsRequesting,
  handleShow,
  setModalMessage,
  handleShowInactiveSessionModal
) => {
  e.preventDefault()
  if (code.length < 6 || isRequesting) return

  setIsAValidCode(true)

  const password = passwordEncrypt(contextInfo.loginPass)
  const timeOutId = handleRequestButton(setIsRequesting)
  const validation = await validateBuyerQuery(
    contextInfo.loginEmail,
    password,
    code
  )

  if (validation.response === true) {
    setIsAValidCode(validation.response)
    setVerificationCodeClass('firstSignInRSideCorrectCode')
    handleRequestButton(setIsRequesting, timeOutId)
    const dbpurchased = await getPurchasedDB(
      validation.body?.findBuyer.buyer.buyerId,
      validation.body?.loginCognito.idToken,
      navigate,
      handleShowInactiveSessionModal
    )
    let databasesStates = []
    let numberOfActiveDataBases = 0

    if (dbpurchased?.body?.length > 0) {
      databasesStates = validateDataBaseExpiration(dbpurchased?.body)
      if (databasesStates.length > 0) {
        numberOfActiveDataBases = databasesStates.filter(
          (item) => item === 'Activa'
        ).length
      }
    }
    setSessionData({
      ...sessionData,
      buyerId: validation.body?.findBuyer.buyer.buyerId,
      companyId: validation.body?.findBuyer.buyer.companyId.companyId,
      companyComertialName:
        validation.body?.findBuyer.buyer.companyId.companyComertialName,
      buyerAddress: validation.body?.findBuyer.buyer.buyerAddress,
      buyerLegalRepresentative:
        validation.body?.findBuyer.buyer.buyerLegalRepresentative,
      buyerContactName: validation.body?.findBuyer.buyer.buyerContactName,
      buyerCredentialEmail: validation.body?.findBuyer.buyerCredentialEmail,
      buyerRepresentativeIdNum:
        validation.body?.findBuyer.buyer.buyerRepresentativeIdNum,
      logged: validation.body?.findBuyer.buyerCredentialEmail
        ? true
        : undefined
    })
    setContextInfo({
      ...contextInfo,
      sessionInfo: validation.body,
      dbpurchased: dbpurchased?.body,
      databasesStates,
      numberOfActiveDataBases
    })
    navigate(`/buyer/${validation.body.findBuyer.buyer.buyerId}/Cuenta`)
  } else {
    if (
      validation.data.code.includes('MAX_POSSIBLE_ATTEMPTS') ||
      validation.data.code.includes('BUYER_NOT_ENABLE_TRY_IN_ONE_MINUTE')
    ) {
      activateRequestButton(setIsRequesting, timeOutId)
      setVerificationCodeClass('firstSignInRSideWrongCode')
      setCode('')
      setModalMessage(firstSignInBuyerStrings.maxAttempts)
      handleShow()
    }
    activateRequestButton(setIsRequesting, timeOutId)
    setVerificationCodeClass('firstSignInRSideWrongCode')
    setCode('')
  }
}

export const handleInputChange = ({
  setCode,
  e,
  index,
  firstInput,
  setFirstInput
}) => {
  const pivot = e.target.value.charAt(0)
  const value = /^[0-9]+$/.test(pivot) ? pivot : ''

  setCode(
    (prev) => prev.substring(0, index) + value + prev.substring(index + 1)
  )

  if (index === 0 && value) {
    setFirstInput(e.target)
  }

  if (index < 5 && value) {
    e.target.nextElementSibling.focus()
  } else if (index === 5 && value) {
    firstInput.focus()
  }
}

export const closeModalMaxAttempts = (navigate, handleClose) => {
  handleClose()
  navigate('/login')
}
