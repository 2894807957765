import {
  activateRequestButton,
  handleRequestButton
} from '../../../../utils/functions'
import { yourChoicesBuyerString } from '../../../../utils/strings'
import { validateNumberOfUsersWhoWishToContactThem } from '../../../../utils/userqueries'

export const handleSubmit = async (
  navigate,
  contextInfo,
  setContextInfo,
  setModalMessage,
  handleShow,
  authContext,
  selection,
  isRequesting,
  setIsRequesting
) => {
  if (!selection.length || isRequesting) return
  const { sessionData } = authContext
  const { buyerId } = sessionData
  try {
    const timeOutId = handleRequestButton(setIsRequesting)
    const validateResponse = await validateNumberOfUsersWhoWishToContactThem(
      2,
      authContext,
      contextInfo.personalize
    )
    if (validateResponse.body && validateResponse.body.count !== undefined) {
      const count = validateResponse.body.count
      if (count < 1) {
        activateRequestButton(setIsRequesting, timeOutId)
        setModalMessage(yourChoicesBuyerString.notUsersToBuy)
        handleShow()
      } else {
        const total = count
        setContextInfo({ ...contextInfo, usersWhoWishToContactThem: total })
        navigate(`/buyer/${buyerId}/BaseDeDatos/CompraBaseDatosPaso1`, { state: true })
      }
    }
  } catch (error) {
    throw new Error(error)
  }
}
