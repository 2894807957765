import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { Navigate } from 'react-router-dom'

export const PublicRoute = ({ children }) => {
  const { sessionData } = useContext(AuthContext)
  const { logged, buyerId } = sessionData

  return (
    !logged
      ? children
      : <Navigate to={`/buyer/${buyerId}/Cuenta`} />
  )
}
