import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import principalLogo from '../../../../assets/icons/logo.svg'

// Context
import { DataContext } from '../../../../context/DataContext'

// Utils
import { successfulTransactionBuyerString } from '../../../../utils/strings'

import {
  getUsersBuyedList,
  handleNextButtonClick
} from './SuccessfulTransactionFunctions'
import './SuccessfulTransaction.css'
import { AuthContext } from '../../../../context/AuthContext'

export const SuccessfulTransaction = () => {
  const { contextInfo, setContextInfo } = useContext(DataContext)
  const authContext = useContext(AuthContext)
  const { sessionData } = authContext
  const { buyerId } = sessionData
  const navigate = useNavigate()

  useEffect(() => {
    getUsersBuyedList(authContext, setContextInfo, contextInfo)
  }, [])

  return (
    <section className="successfulTransaction">
      <img
        src={principalLogo}
        alt="logo principal"
        className="successfulTransactionLogo"
      />
      <div className="successfulTransactionPrincipalContent">
        <div className="successfulTransactionPrincipalMessage">
          <h1 className="successfulTransactionTitle">
            {successfulTransactionBuyerString.successfulTransaction}
          </h1>
          <p className="successfulTransactionPurchasedPlan">
            {successfulTransactionBuyerString.havePurchased}
            {`${
              contextInfo.planType === 'planBDEspecializada'
                ? 'Especializada'
                : 'Simple'
            }`}
          </p>
        </div>
        <p className="successfulTransactionSecondaryMessage">
          {successfulTransactionBuyerString.useDDBB}
        </p>
        <button
          className="successfulTransactionNextButton"
          onClick={() => handleNextButtonClick(navigate, buyerId)}
        >
          {successfulTransactionBuyerString.continue}
        </button>
      </div>
    </section>
  )
}
