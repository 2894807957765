import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { Navigate } from 'react-router-dom'

export const PrivateRoute = ({ children }) => {
  const { sessionData } = useContext(AuthContext)
  const { logged } = sessionData

  return (
    logged
      ? children
      : <Navigate to="/" />
  )
}
