import React, { useEffect, useState } from 'react'
import Joyride, { STATUS } from 'react-joyride'
import { useLocation } from 'react-router-dom'
import { joyrideStyles } from '../utils/objects'

export const useTour = (localStorageKey, steps) => {
  const [run, setRun] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    const tourViewed = window.localStorage.getItem(localStorageKey)
    if (!tourViewed) {
      window.localStorage.setItem(localStorageKey, '1')
      setRun(true)
    }
  }, [localStorageKey])

  const handleClickStart = (e) => {
    e.preventDefault()
    setRun(true)
  }

  const handleJoyrideCallback = (data) => {
    const { status } = data
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      setRun(false)
    }
  }
  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        run={run}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={steps}
        styles={joyrideStyles}
        disableOverlayClose={true}
        hideCloseButton={true}
        locale={{
          back: 'Atrás',
          close: 'Cerrar',
          last: 'Fin',
          next: 'Siguiente',
          open: 'Abrir Diálogo',
          skip: 'Omitir'
        }}
        spotlightClicks={false}
        spotlightPadding={0}
        swcMinify={false}
        disableScrolling={true}
        disableCloseOnEsc={true}
      />
      <button
        className={
          pathname.includes('HistorialdePagos')
            ? 'btnStartTourHidden'
            : 'btnStartTour'
        }
        onClick={handleClickStart}
      ></button>
    </>
  )
}
