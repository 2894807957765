import { getStatesAndCities } from '../../utils/queries'
import { companyRegistrationBuyerString } from '../../utils/strings'
import { deleteSpacing, fileExtension, hasRepeatedCharactersAtMiddle, validateNames, validateNoWhiteSpacesAtBeginning } from '../../utils/validateFunctions'

export const createRegistrationDataList = async (values, setValues) => {
  try {
    const dataList = await getStatesAndCities()
    setValues({ ...values, statesList: dataList.states, citiesList: dataList.cities })
  } catch (error) {
    console.error(error)
  }
}

export const handleChangeCompanyCommercialAndBusinessName = (
  e,
  contextInfo,
  setContextInfo,
  sessionData,
  setSessionData,
  values,
  setValues
) => {
  const value = e.target.value
  let formattedValue = ''
  if (hasRepeatedCharactersAtMiddle(value)) {
    return
  }

  if (validateNames(value) && validateNoWhiteSpacesAtBeginning(value)) {
    setValues({ ...values, [e.target.name]: value })
  }
  if (e.target.name === 'companyComertialName') {
    formattedValue = formattedValue + value
    setValues({ ...values, commercialName: formattedValue })
  } else if (e.target.name === 'companyBussinesName') {
    formattedValue = formattedValue + value
    setValues({ ...values, companyName: formattedValue })
  }
  setContextInfo({ ...contextInfo, [e.target.name]: e.target.value })
  setSessionData({ ...sessionData, [e.target.name]: e.target.value })
}

export const verificationInput = (
  condition,
  item,
  showIncorrectFormat,
  setShowIncorrectFormat,
  e,
  values,
  setValues,
  contextInfo,
  setContextInfo
) => {
  handleInputOnBlur(e, values, setValues, contextInfo, setContextInfo)
  if (!condition) {
    setShowIncorrectFormat({ ...showIncorrectFormat, [item]: 'incorrectFormat' })
    return false
  } else {
    setShowIncorrectFormat({ ...showIncorrectFormat, [item]: 'showSpanPass' })
    return true
  }
}

export const handleChangeLogoDocument = (
  e,
  values,
  setValues,
  contextInfo,
  setContextInfo,
  showIncorrectFormat,
  setShowIncorrectFormat,
  setLogoName,
  setLogoFile,
  setModalMessage,
  handleShow
) => {
  const isAValidExtension = fileExtension(e, setLogoName, setLogoFile, companyRegistrationBuyerString.allowedExtensions, setModalMessage, handleShow)

  if (isAValidExtension) {
    setValues({ ...values, logoDocument: e.target.files[0] })
    setShowIncorrectFormat({ ...showIncorrectFormat, logo: 'showSpanPass' })
    const formData = new FormData()
    formData.append('file', e.target.files[0], e.target.files[0].name)
    setContextInfo({ ...contextInfo, [e.target.name]: formData })
  } else {
    setValues({ ...values, logoDocument: null })
    setContextInfo({ ...contextInfo, [e.target.name]: '' })
  }
}

export const handleChangeRutDocument = (
  e,
  values,
  setValues,
  contextInfo,
  setContextInfo,
  showIncorrectFormat,
  setShowIncorrectFormat,
  setRUTName,
  setRUTFile,
  setModalMessage,
  handleShow
) => {
  const isAValidExtension = fileExtension(e, setRUTName, setRUTFile, companyRegistrationBuyerString.allowedExtensions, setModalMessage, handleShow)

  if (isAValidExtension) {
    setValues({ ...values, rutDocument: e.target.files[0] })
    setShowIncorrectFormat({ ...showIncorrectFormat, rut: 'showSpanPass' })
    const formData = new FormData()
    formData.append('file', e.target.files[0], e.target.files[0].name)
    setContextInfo({ ...contextInfo, [e.target.name]: formData })
  } else {
    setValues({ ...values, rutDocument: null })
    setContextInfo({ ...contextInfo, [e.target.name]: '' })
  }
}

export const handleChangeNitValue = (e, contextInfo, setContextInfo, values, setValues, showIncorrectFormat, setShowIncorrectFormat) => {
  const value = e.target.value
  const formattedValue = value.replace(/[^0-9]/g, '')

  setValues({ ...values, nitValue: formattedValue })

  if (formattedValue[0] === '0') {
    setValues({ ...values, nitValue: '' })
  } else {
    verificationInput(formattedValue.length === 9, 'nit', showIncorrectFormat, setShowIncorrectFormat, e, values, setValues, contextInfo, setContextInfo)
    setContextInfo({ ...contextInfo, [e.target.name]: formattedValue })
  }
}

export const handleStateAndCity = (e, contextInfo, setContextInfo, values, setValues) => {
  if (e.target.name === 'departmentId') {
    setValues({ ...values, department: e.target.value })
  } else {
    setContextInfo({ ...contextInfo, [e.target.name]: e.target.value })
  }
}

export const formVerification = (
  contextInfo,
  companyComertialName,
  formData,
  showIncorrectFormat,
  setShowIncorrectFormat,
  department,
  setContextInfo,
  navigate
) => {
  const validateField = (item, condition) => {
    if (!condition) {
      setShowIncorrectFormat({ ...showIncorrectFormat, [item]: 'incorrectFormat' })
      return false
    } else {
      setShowIncorrectFormat({ ...showIncorrectFormat, [item]: 'showSpanPass' })
      return true
    }
  }

  const validateCompanyInfo = () => {
    return (
      validateField('commercialName', companyComertialName) &&
      validateField('companyName', contextInfo.companyBussinesName) &&
      validateField('logo', contextInfo.logo) &&
      validateField('nit', contextInfo.companyNit && contextInfo.companyNit.length === 9) &&
      validateField('department', department) &&
      validateField('city', contextInfo.cityId) &&
      validateField('rut', contextInfo.RUToCCFile)
    )
  }

  if (validateCompanyInfo()) {
    setContextInfo({
      ...contextInfo,
      formData
    })

    navigate('/RegistroEmpresa2')
  }
}

export const handleChangeState = (e, contextInfo, setContextInfo, values, setValues) => {
  setValues({ ...values, department: e.target.value })
  setContextInfo({ ...contextInfo, cityId: undefined })
  handleStateAndCity(e, contextInfo, setContextInfo, values, setValues)
}

export const handleSubmit = (
  contextInfo,
  companyComertialName,
  setContextInfo,
  navigate,
  showIncorrectFormat,
  setShowIncorrectFormat,
  logoDocument,
  rutDocument,
  department
) => {
  const formData = new FormData()
  formData.append('logo', logoDocument)
  formData.append('file', rutDocument)
  formVerification(
    contextInfo,
    companyComertialName,
    formData,
    showIncorrectFormat,
    setShowIncorrectFormat,
    department,
    setContextInfo,
    navigate
  )
}

export const handleInputOnBlur = (e, inputValue, setInputValue, contextInfo, setContextInfo) => {
  let name = ''
  switch (e.target.name) {
    case 'companyComertialName':
      name = 'commercialName'
      break
    case 'companyBussinesName':
      name = 'companyName'
      break
    case 'companyNit':
      name = 'nitValue'
      setInputValue({ ...inputValue, [name]: deleteSpacing(e.target.value.replace(/[^0-9]/g, '')) })
      setContextInfo({ ...contextInfo, [e.target.name]: deleteSpacing(e.target.value.replace(/[^0-9]/g, '')) })
      return
  }
  setInputValue({ ...inputValue, [name]: deleteSpacing(e.target.value) })
  setContextInfo({ ...contextInfo, [e.target.name]: deleteSpacing(e.target.value) })
}
