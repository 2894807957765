import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import openEyeIcon from '../../../assets/icons/icon_pass2.svg'
import closeEyeIcon from '../../../assets/icons/icon_pass1.svg'
import { passwordRecoveryBuyerStrings, passwordChangeBuyerString } from '../../../utils/strings'
import * as functions from './SetNewPasswordFunctions'
import { Form } from 'react-bootstrap'
import { DataContext } from '../../../context/DataContext'
import { useModal } from '../../../hooks/useModal'
import { Modal } from '../../../components/Modal/Modal'
import { useChangePassword } from '../../PasswordChange/useChangePassword'
import './../PasswordRecovery.css'
import { maxSize } from '../../../utils/validateFunctions'

export const SetNewPassword = () => {
  const { contextInfo } = useContext(DataContext)
  const { setContextInfo } = useContext(DataContext)
  const [, setShowNotEqualPasswords] = useState('showSpanPass')
  const { states, handlers } = useChangePassword()
  const navigate = useNavigate()
  const { inputValues, showPassword, conditions } = states
  const { setShowPassword, handleNewPassword, handleConfirmPassword } = handlers
  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState('')
  const [isRequesting, setIsRequesting] = useState(false)
  useEffect(() => {
    setContextInfo({ ...contextInfo, passwordRecoverylocation: 'SetNewPassword' })
  }, [])

  return (
    <>
      <p className='passwordRecoveryRSideTopText'>{passwordRecoveryBuyerStrings.enterPassword}</p>
      <Form className='passwordRecoveryRSideCodeForm'
        onSubmit={(e) =>
          functions.handleSubmit(
            e,
            navigate,
            contextInfo,
            setShowNotEqualPasswords,
            handleShow,
            setModalMessage,
            inputValues.inputValue2,
            inputValues.inputValue3,
            conditions,
            isRequesting,
            setIsRequesting,
            inputValues
          )}>
        <div className='passwordRecoveryRSideInputsGroup'>
          <input
            placeholder={passwordChangeBuyerString.originalPassword}
            className='setNewpasswordInput'
            type={showPassword.secondInput ? 'password' : 'text'}
            value={inputValues.inputValue2}
            onChange={handleNewPassword}
            onInput={(e) => maxSize(e, 'password')}
            required />
          <Link className='setNewPasswordShowPassButton' onClick={() => setShowPassword((showPassword) => ({ ...showPassword, secondInput: !showPassword.secondInput }))} >
            <img className='setNewPasswordEyeIcon' src={showPassword.secondInput ? openEyeIcon : closeEyeIcon} alt={passwordChangeBuyerString.setNewPasswordEyeIcon} />
          </Link>
          {(!conditions.isNewPasswordValid && inputValues.inputValue2) && <span className='setNewPasswordFeedbackMessage'>{passwordChangeBuyerString.messageInvalid2}</span>}
          <input
            placeholder={passwordChangeBuyerString.confirmPassword}
            className='setNewpasswordInput'
            type={showPassword.thirdInput ? 'password' : 'text'}
            value={inputValues.inputValue3}
            onChange={handleConfirmPassword}
            onInput={(e) => maxSize(e, 'password')}
            required />
          <Link className='setNewPasswordShowPassButton' onClick={() => setShowPassword((showPassword) => ({ ...showPassword, thirdInput: !showPassword.thirdInput }))} >
            <img className='setNewPasswordEyeIcon' src={showPassword.thirdInput ? openEyeIcon : closeEyeIcon} alt={passwordChangeBuyerString.setNewPasswordEyeIcon} />
          </Link>
          {inputValues.inputValue2 !== inputValues.inputValue3 && <span className='setNewPasswordFeedbackMessage'>{passwordChangeBuyerString.dontEquals}</span>}
        </div>
        <button className="passwordRecoveryRSideSendBtn"
          disabled={!(conditions.isNewPasswordValid && (inputValues.inputValue2 === inputValues.inputValue3)) || isRequesting}
          type='submit'>
          {passwordRecoveryBuyerStrings.continue}
        </button>
        <Modal isOpen={showModal}>
          <Modal.Content>
            {modalMessage}
          </Modal.Content>
          <Modal.Buttons>
            <button className='Modal-greenBtn' onClick={handleClose}>{passwordRecoveryBuyerStrings.ok}</button>
          </Modal.Buttons>
        </Modal>
      </Form >
    </>
  )
}
