import React, { useContext, useRef, useState } from 'react'
// import { useNavigate } from 'react-router-dom'

// Assets
// import checkIcon from '../../../../assets/icons/icon_check_green.svg'
// import iconUpload from '../../../../assets/icons/icon_upload_blanco.png'

// Components
import { BuyDBResume } from '../../components/BuyDBResume/BuyDBResume'
// import { Modal } from '../../../../components/Modal/Modal'

// Context
import { DataContext } from '../../../../context/DataContext'

// Utils
// import { useModal } from '../../../../hooks/useModal'
import {
  buyDBFinalResumeBuyerString,
  buyDBUploadReceiptBuyerString
} from '../../../../utils/strings'

import './BuyDBUploadReceipt.css'
import { useNavigate } from 'react-router-dom'
import { acceptBtnModal } from './BuyDBUploadReceptFunctions'
import { AuthContext } from '../../../../context/AuthContext'
// import { acceptBtnModal, handleClickSendBtn, storeBill } from './BuyDBUploadReceptFunctions'
// import { AuthContext } from '../../../../context/AuthContext'
import LoopayWhiteLogo from '../../../../assets/images/Loopay-logo-blanco.png'
import WompiWhiteLogo from '../../../../assets/images/Wompi-logo-blanco.png'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'

export const BuyDBUploadReceipt = () => {
  const navigate = useNavigate()
  const { contextInfo } = useContext(DataContext)
  const [showModal, handleShow, handleClose] = useModal()
  const [showModal2, handleShow2] = useModal()
  const [modalMessageChecked, setModalMessageChecked] = useState(false)
  const inputCheck = useRef(false)

  // const { DBPrice, numberOfUsersToBuy, dbPurchaseJSON } = contextInfo
  const { DBPrice, numberOfUsersToBuy } = contextInfo

  const totalPricePerDB = DBPrice * numberOfUsersToBuy
  // const databaseId = dbPurchaseJSON.databaseType

  const authContext = useContext(AuthContext)
  const dataContext = useContext(DataContext)

  const [isRequesting, setIsRequesting] = useState(false)

  const handleInputCheck = () => {
    setModalMessageChecked(inputCheck.current.checked)
  }

  return (
    <section className="buyDBUploadReceipt">
      <BuyDBResume planType={contextInfo.planType} />
      <div className="buyDBUploadReceiptConfirmation">
        <header className="buyDBUploadReceiptConfirmationHeader">
          <h2 className="buyDBUploadReceiptTitle">
            {buyDBUploadReceiptBuyerString.dataForPayment}
          </h2>
          <h2 className="buyDBUploadReceiptTitle">
            {buyDBUploadReceiptBuyerString.price}
          </h2>
        </header>
        <div className="buyDBUploadReceiptConfirmationContent">
          <div className="buyDBUploadReceiptConfirmationDataContent">
            <p className="buyDBUploadReceiptConfirmationParagraph">
              {`${buyDBUploadReceiptBuyerString.paymentMethod}`}
              <br />
              <span>{buyDBUploadReceiptBuyerString.usersAmount}</span>
              {contextInfo.numberOfUsersToBuy}
            </p>
          </div>
          <div className="buyDBFinalResumeConfirmationPriceContent">
            <p className="buyDBUploadReceiptConfirmationParagraph">
              {buyDBUploadReceiptBuyerString.symbol}
              {`${contextInfo.DBPrice * contextInfo.numberOfUsersToBuy * buyDBFinalResumeBuyerString.iva}`}
            </p>
          </div>
        </div>
      </div>
      <div className="buyDBUploadReceiptPayButtons">
        <button
          disabled={isRequesting}
          className="paymentDescriptionSend-btn"
          onClick={handleShow}
        >
          {buyDBUploadReceiptBuyerString.goToLoopay}
          <img src={WompiWhiteLogo} alt="wompi-logo" />
        </button>
        {/* <div className="paymentDescription-uploadFile">
            <p className="paymentDescription-VoucherName">{voucherFile}</p>
            <div>
              <input
                type="file"
                id="voucherUpload"
                accept=".jpg,.jpeg,.png,.pdf"
                onChange={(e) =>
                  storeBill(
                    e,
                    setVoucherFile,
                    setFile,
                    setModalMessage,
                    handleShow
                  )
                }
              />
              <label htmlFor="voucherUpload" className="paymentDescription-btn">
                <img src={iconUpload} alt="dinero-icon" />
                <p>{buyDBUploadReceiptBuyerString.uploadReceipt}</p>
              </label>
            </div>
          </div> */}
        {/* <button
            className="paymentDescriptionSend-btn"
            type="button"
            onClick={() =>
              handleClickSendBtn(
                handleShow,
                contextInfo.dbPurchaseJSON,
                contextInfo,
                setContextInfo,
                buyerId,
                companyId,
                file,
                setRadicateNum,
                isRequesting,
                setIsRequesting
              )
            }
            disabled={!file || isRequesting}
          >
            {buyDBUploadReceiptBuyerString.enviar}
          </button> */}
        <button
          disabled={isRequesting}
          className="paymentDescriptionSend-btn"
          onClick={handleShow}
        >
          {buyDBUploadReceiptBuyerString.goToLoopay}
          <img src={LoopayWhiteLogo} alt="loopay-logo" />
        </button>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <div className="buyDBFinalResumeModalContent">
            <input
              className="buyDBFinalResumeModalContentInput"
              type="checkbox"
              ref={inputCheck}
              onClick={handleInputCheck}
            />
            <p className="buyDBFinalResumeModalContentText">
              {buyDBFinalResumeBuyerString.haveReadAndAccept}
              <span className="buyDBFinalResumeModalContentSpan">
                {buyDBFinalResumeBuyerString.termsAndConditions}
              </span>
            </p>
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <button onClick={handleClose} className="Modal-normalBtn">
            {buyDBFinalResumeBuyerString.cancel}
          </button>
          <button
            onClick={() =>
              acceptBtnModal(
                modalMessageChecked,
                navigate,
                authContext,
                dataContext,
                isRequesting,
                setIsRequesting,
                totalPricePerDB,
                handleShow2
              )
            }
            className={`${
              modalMessageChecked ? 'Modal-orangeBtn' : 'Modal-disabledBtn'
            }`}
            disabled={isRequesting}
          >
            {buyDBFinalResumeBuyerString.accept}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showModal2}>
        <Modal.Content>
          <div className="buyDBFinalResumeModalContent">
            <p className="buyDBFinalResumeModalContentText">
              {buyDBFinalResumeBuyerString.error}
            </p>
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <button
            onClick={() => {
              navigate('../Cuenta')
            }}
            className="Modal-normalBtn"
          >
            {buyDBFinalResumeBuyerString.cancel}
          </button>
        </Modal.Buttons>
      </Modal>
      {/* <Modal isOpen={showModal}>
        <Modal.Content>
          {radicateNum && (
            <>
              <div className="newPqrsModalHeader">
                <img src={checkIcon} className="modalIcon" alt={buyDBUploadReceiptBuyerString.iconCheck} />
                <p>
                  {buyDBUploadReceiptBuyerString.filedNumber}
                  {radicateNum}
                </p>
              </div>
              <p className="newPqrsConsultationFirst">
                {buyDBUploadReceiptBuyerString.requestProcessPart1}{' '}
                <span className="newPqrsConsultationSecond">
                  {buyDBUploadReceiptBuyerString.requestProcessPart2}
                </span>
              </p>
            </>
          )}
          {!file && <p>{modalMessage}</p>}
        </Modal.Content>
        <Modal.Buttons>
          {radicateNum && (
            <button
              onClick={() => acceptBtnModal(navigate)}
              className="Modal-greenBtn"
            >
              {buyDBUploadReceiptBuyerString.ok}
            </button>
          )}
          {!file && (
            <button className="Modal-greenBtn" onClick={handleClose}>
              {buyDBUploadReceiptBuyerString.ok}
            </button>
          )}
        </Modal.Buttons>
      </Modal> */}
    </section>
  )
}
