import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import closepasswordChangeEyeIcon from '../../assets/icons/icon_pass1.svg'
import openpasswordChangeEyeIcon from '../../assets/icons/icon_pass2.svg'
import IconCheck from '../../assets/icons/icon_bien.svg'
import { passwordChangeBuyerString } from '../../utils/strings'
import { Modal } from '../../components/Modal/Modal'
import { useModal } from '../../hooks/useModal'
import { useChangePassword, handleClickPasswordChanged } from './useChangePassword'
import './PasswordChange.css'
import { AuthContext } from '../../context/AuthContext'
import { maxSize } from '../../utils/validateFunctions'

export const PasswordChange = () => {
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const { sessionData, setSessionData } = authContext
  const { buyerCredentialEmail } = sessionData
  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState('')
  const [isChangeSuccess, setIsChangeSuccess] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const email = buyerCredentialEmail
  const { states, handlers } = useChangePassword()

  const {
    inputValues,
    showPassword,
    formValidated,
    progressBarValue,
    conditions
  } = states
  const {
    setInputValues,
    setShowPassword,
    handleNewPassword,
    handleConfirmPassword,
    handleSubmit
  } = handlers

  return (
    <div>
      <form
        className="passwordChangeFormContainer"
        onSubmit={(event) =>
          handleSubmit({
            event,
            email,
            inputValues,
            handleShow,
            setModalMessage,
            setIsChangeSuccess,
            authContext,
            setIsRequesting,
            formValidated,
            isRequesting
          })
        }
      >
        <div className="passwordChangeInputContainer">
          <label className="passwordChangeInputLabel">
            {passwordChangeBuyerString.currentPassword}
          </label>
          <input
            onInput={(e) => maxSize(e, 'password')}
            className="passwordChangeInput"
            type={showPassword.firstInput ? 'password' : 'text'}
            value={inputValues.inputValue1}
            onChange={(e) =>
              setInputValues((inputValues) => ({
                ...inputValues,
                inputValue1: e.target.value
              }))
            }
            required
          />
          <Link
            className="passwordChangeShowPassButton"
            onClick={() =>
              setShowPassword((showPassword) => ({
                ...showPassword,
                firstInput: !showPassword.firstInput
              }))
            }
          >
            <img
              className="passwordChangeEyeIcon"
              src={
                showPassword.firstInput
                  ? openpasswordChangeEyeIcon
                  : closepasswordChangeEyeIcon
              }
              alt={passwordChangeBuyerString.passwordChangeEyeIcon}
            />
          </Link>
          <label className="passwordChangeInputLabel">
            {passwordChangeBuyerString.newPassword}
          </label>
          <input
            onInput={(e) => maxSize(e, 'password')}
            className="passwordChangeInput"
            type={showPassword.secondInput ? 'password' : 'text'}
            value={inputValues.inputValue2}
            onChange={handleNewPassword}
            required
          />
          <Link
            className="passwordChangeShowPassButton"
            onClick={() =>
              setShowPassword((showPassword) => ({
                ...showPassword,
                secondInput: !showPassword.secondInput
              }))
            }
          >
            <img
              className="passwordChangeEyeIcon"
              src={
                showPassword.secondInput
                  ? openpasswordChangeEyeIcon
                  : closepasswordChangeEyeIcon
              }
              alt={passwordChangeBuyerString.passwordChangeEyeIcon}
            />
          </Link>
          <progress
            className={`passwordProgressBar ${
              progressBarValue < 60
                ? 'passwordProgressBarWeak'
                : progressBarValue < 90
                ? 'passwordProgressBarMedium'
                : 'passwordProgressBarStrong'
            }`}
            value={progressBarValue}
            max={100}
          ></progress>
          {!conditions.isNewPasswordValid && inputValues.inputValue2 && (
            <span className="passwordChangeFeedbackMessage">
              {passwordChangeBuyerString.messageInvalid2}
            </span>
          )}

          <label className="passwordChangeInputLabel">
            {passwordChangeBuyerString.confirmPassword}
          </label>
          <input
            onInput={(e) => maxSize(e, 'password')}
            className="passwordChangeInput"
            type={showPassword.thirdInput ? 'password' : 'text'}
            value={inputValues.inputValue3}
            onChange={handleConfirmPassword}
            required
          />
          <Link
            className="passwordChangeShowPassButton"
            onClick={() =>
              setShowPassword((showPassword) => ({
                ...showPassword,
                thirdInput: !showPassword.thirdInput
              }))
            }
          >
            <img
              className="passwordChangeEyeIcon"
              src={
                showPassword.thirdInput
                  ? openpasswordChangeEyeIcon
                  : closepasswordChangeEyeIcon
              }
              alt={passwordChangeBuyerString.passwordChangeEyeIcon}
            />
          </Link>
          {inputValues.inputValue2 !== inputValues.inputValue3 && (
            <span className="passwordChangeFeedbackMessage">
              {passwordChangeBuyerString.dontEquals}
            </span>
          )}
        </div>

        <div className="passwordChangeBtnContainer">
          <button
            type="submit"
            className="passwordChangeSubmitButton"
            disabled={!formValidated || isRequesting}
          >
            {passwordChangeBuyerString.changePassword}
          </button>
          <Modal isOpen={showModal}>
            <Modal.Content>
              {isChangeSuccess && (
                <img src={IconCheck} className="modalIcon" alt="icon-check" />
              )}
              <p>{modalMessage}</p>
            </Modal.Content>
            <Modal.Buttons>
              <button
                className="Modal-greenBtn"
                  onClick={isChangeSuccess ? () => handleClickPasswordChanged(navigate, sessionData, setSessionData) : handleClose}
              >
                {passwordChangeBuyerString.ok}
              </button>
            </Modal.Buttons>
          </Modal>
        </div>
      </form>
    </div>
  )
}
