import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { categoriesBuyerStrings } from '../../../../utils/strings'
import { CategoriesCard } from '../../components/CategoriesCard/CategoriesCard'
import { CategoriesContext } from '../../../../context/CategoriesContext'
import { validateNumberOfSelectionsbyGender } from '../../../../utils/userqueries'
import appLogo from '../../../../assets/icons/logo.svg'
import maleImg from '../../../../assets/images/male.svg'
import femaleImg from '../../../../assets/images/female.svg'
import otherImg from '../../../../assets/images/other.svg'
import anyImg from '../../../../assets/images/anygender.svg'
import whiteBackIcon from '../../../../assets/icons/icon_flecha_blanco.svg'
import './CategoriesGender.css'
import { AuthContext } from '../../../../context/AuthContext'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'

const genderCategories = [
  {
    id: 1,
    name: 'Male',
    cardName: 'Hombre',
    image: maleImg
  },
  {
    id: 2,
    name: 'Female',
    cardName: 'Mujer',
    image: femaleImg
  },
  {
    id: 3,
    name: 'Other',
    cardName: 'Otro',
    image: otherImg
  },
  {
    id: 4,
    name: 'Any',
    cardName: 'Cualquier Género',
    image: anyImg
  }
]

export const CategoriesGender = () => {
  const { state: planType } = useLocation()
  const [showModal, handleShow, handleClose] = useModal()
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const { categoriesGenderStrings } = categoriesBuyerStrings
  const { categoriesSelected, setCategoriesSelected } =
    useContext(CategoriesContext)
  const [gender, setGender] = useState([])
  const [modalData, setModalData] = useState(0)

  useEffect(() => {
    if (categoriesSelected.gender) {
      const catAlreadySelected = genderCategories.filter((gender) => categoriesSelected.gender.some((item) => item === gender.id))
      setGender(catAlreadySelected)
    }
  }, [])

  const handleNextCategory = async (selection) => {
    if (!selection.length) return

    const gendersId = gender.map((genderItem) => genderItem.id)
    setCategoriesSelected({ ...categoriesSelected, gender: gendersId })
    const amount = await validateNumberOfSelectionsbyGender(
      authContext,
      categoriesSelected.city,
      gendersId
    )
    // modal alert continuar / corregir
    setModalData(amount?.count)
    handleShow()
  }

  const isAlreadySelected = (id) => {
    const isSelected = gender.some((item) => item.id === id)
    if (isSelected) return true
    else return false
  }

  return (
    <section id="CategoriesGender">
      <div className="CategoriesGenderContent">
        <div className="CategoriesGenderLogoBox">
          <img src={appLogo} alt="appLogo" />
        </div>
        <div className="CategoriesGenderText">
          <p>{categoriesBuyerStrings.titleParagraph}</p>
        </div>
        <div className="CategoriesGenderGrid">
          {genderCategories.map((data) => (
            <CategoriesCard
              key={data.id}
              id={data.id}
              name={data.name}
              image={data.image}
              cardName={data.cardName}
              categoryData={genderCategories}
              categorySelected={gender}
              setCategorySelected={setGender}
              isAlreadySelected={isAlreadySelected(data.id)}
            />
          ))}
        </div>
        <div className="CategoriesGenderFooter">
          <div className="CategoriesGenderFooterContent">
            <div>
              <Link to="../CategoriasBDUbicacion">
                <img
                  className="CategoriesGenderFooterBackBtn"
                  src={whiteBackIcon}
                  alt="backIcon"
                />
                <p>{categoriesBuyerStrings.back}</p>
              </Link>
            </div>
            <p>{categoriesGenderStrings.gender}</p>
            <div>
              <button
                onClick={() => handleNextCategory(gender, showModal)}
                className="CategoriesGenderFooterNextBtn"
                disabled={!gender.length}
              >
                <p>{categoriesBuyerStrings.next}</p>
                <img src={whiteBackIcon} alt="nextIcon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{categoriesGenderStrings.amount}{modalData}</p>
        </Modal.Content>
        <Modal.Buttons>
          {modalData === 0
            ? (
            <button className="Modal-greenBtn" onClick={handleClose}>
              {categoriesGenderStrings.ok}
            </button>
              )
            : (
            <>
              <button className="Modal-normalBtn" onClick={handleClose}>
                {categoriesGenderStrings.edit}
              </button>
              <button
                className="Modal-orangeBtn"
                onClick={() =>
                  navigate('/CategoriasBDEdad', { state: planType })
                }
              >
                {categoriesGenderStrings.continue}
              </button>
            </>
              )}
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
