import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import whiteBackIcon from '../../../../assets/icons/icon_flecha_blanco.svg'
import DeleteIcon from '../../../../assets/icons/icon_eliminar.svg'
import iconPlus from '../../../../assets/icons/icon_plus.svg'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import { CategoriesContext } from '../../../../context/CategoriesContext'
import './YourChoices.css'
import { DataContext } from '../../../../context/DataContext'
import { handleSubmit } from './YourChoicesFunctions'
import { yourChoicesBuyerString } from '../../../../utils/strings'
import { AuthContext } from '../../../../context/AuthContext'

export const YourChoices = () => {
  const { contextInfo, setContextInfo } = useContext(DataContext)
  const authContext = useContext(AuthContext)
  const { categoriesSelected, setCategoriesSelected } =
    useContext(CategoriesContext)
  const navigate = useNavigate()
  const [isRequesting, setIsRequesting] = useState(false)

  const getElementsId = categoriesSelected?.elementSelected?.map(
    (element) => element.elementId
  )
  const deleteCategoriesToJSON = (({
    generalCatSelected,
    subCatSelected,
    elementSelected,
    ...rest
  }) => rest)(categoriesSelected)

  const categoriesToJSON = {
    ...deleteCategoriesToJSON,
    element: getElementsId
  }

  useEffect(() => {
    setContextInfo({ ...contextInfo, personalize: categoriesToJSON })
  }, [])

  const { elementSelected, generalCatSelected } = categoriesSelected

  const [showModal, handleShow, handleClose] = useModal()
  const [showModal2, handleShow2, handleClose2] = useModal()
  const [modalMessage, setModalMessage] = useState('')
  const [modalValues, setModalValues] = useState({
    item: '',
    subCategory: ''
  })

  const handleModalValues = (item, subCat) => {
    setModalValues({ item, subCategory: subCat })
    handleShow()
  }

  const deleteElement = () => {
    const deleteItem = elementSelected.filter(
      (subCat) => subCat.elementName !== modalValues.item
    )
    setCategoriesSelected({
      ...categoriesSelected,
      elementSelected: deleteItem
    })
    handleClose()
  }

  return (
    <section id="YourChoices">
      <div className="YourChoicesContent">
        <div className="YourChoicesBackBox">
          <Link to={'/SubCategoriasItemsBD'}>
            <img src={whiteBackIcon} alt="back-img" />
            <span>{yourChoicesBuyerString.yourElections}</span>
          </Link>
        </div>
        <div className="YourChoicesBox">
          <div className="YourChoicesBoxScroll">
            {generalCatSelected?.map((category) => (
              <div key={category.id} className="YourChoicesBoxCategory">
                <h2>{category.name}</h2>
                <div className="YourChoicesBoxCategorySubCatsSelected">
                  {elementSelected?.map(
                    (item) =>
                      item.categoryName === category.name && (
                        <article
                          key={item.elementId}
                          className="YourChoicesBoxCategorySubCat"
                        >
                          <span
                            className={
                              item.elementName.length > 30 ? 'longText' : ''
                            }
                          >
                            {item.elementName}
                          </span>
                          <button
                            onClick={() =>
                              handleModalValues(item.elementName, category.name)
                            }
                          >
                            <img src={DeleteIcon} alt="delete-icon" />
                          </button>
                        </article>
                      )
                  )}
                </div>
              </div>
            ))}
          </div>
          <Modal isOpen={showModal}>
            <Modal.Content>
              <p>
                {yourChoicesBuyerString.wishDelete}
                <span className="orange">{modalValues.item}</span>{' '}
                {yourChoicesBuyerString.text}
                <span>{modalValues.subCategory}</span>
                {yourChoicesBuyerString.symbolo}
              </p>
            </Modal.Content>
            <Modal.Buttons>
              <button className="Modal-normalBtn" onClick={handleClose}>
                {yourChoicesBuyerString.cancel}
              </button>
              <button className="Modal-orangeBtn" onClick={deleteElement}>
                {yourChoicesBuyerString.delete}
              </button>
            </Modal.Buttons>
          </Modal>
        </div>
        <div className="YourChoicesFooter">
          <div className="YourChoicesFooterContentlside">
            <Link to="../SubCategoriasItemsBD">
              <img src={iconPlus} alt="nextIcon" />
              <p>{yourChoicesBuyerString.addOther}</p>
            </Link>
          </div>
          <div className="YourChoicesFooterContentrside">
            <button
              className="yourChoicesContinueBuyBtn"
              disabled={!elementSelected?.length || isRequesting}
              onClick={() =>
                handleSubmit(
                  navigate,
                  contextInfo,
                  setContextInfo,
                  setModalMessage,
                  handleShow2,
                  authContext,
                  elementSelected,
                  isRequesting,
                  setIsRequesting
                )
              }
            >
              <span className="yourChoicesContinueBuyTextBtn">
                {yourChoicesBuyerString.continueBuy}
              </span>
              <img src={whiteBackIcon} alt="nextIcon" />
            </button>
          </div>
        </div>
      </div>
      <Modal isOpen={showModal2}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-greenBtn" onClick={handleClose2}>
            {yourChoicesBuyerString.ok}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
