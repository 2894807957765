import {
  activateRequestButton,
  handleRequestButton,
  passwordEncrypt
} from '../../utils/functions'
import {
  createBuyerCredentialsQuery,
  createBuyerQuery,
  createCompanyQuery
} from '../../utils/queries'
import { companyRegistrationBuyerString } from '../../utils/strings'
import { validatePassFormat } from '../../utils/validateFunctions'

export const hadleCompanyFormChange = (
  e,
  contextInfo,
  setContextInfo,
  setShowRePasswordIncorrectFormat,
  setAreValidCredentials,
  areValidCredentials,
  buyerCredentialPass,
  setShowPasswordIncorrectFormat,
  setshowIncorrectFormatPass
) => {
  setContextInfo({ ...contextInfo, [e.target.name]: e.target.value })
  if (e.target.name === 'buyerCredentialRePass') {
    if (validatePassFormat(e.target.value)) {
      setShowRePasswordIncorrectFormat('showSpanPass')
      setAreValidCredentials({
        ...areValidCredentials,
        credentialRePass: true
      })
    } else {
      setShowRePasswordIncorrectFormat('incorrectFormat')
      setAreValidCredentials({
        ...areValidCredentials,
        credentialRePass: false
      })
    }
  } else if (e.target.name === 'buyerCredentialPass') {
    if (validatePassFormat(e.target.value)) {
      setshowIncorrectFormatPass('showSpanPass')
      setAreValidCredentials({ ...areValidCredentials, credentialPass: true })
    } else {
      setshowIncorrectFormatPass('incorrectFormat')
      setAreValidCredentials({ ...areValidCredentials, credentialPass: false })
    }
  }
}

export const CreateCompanyAndBuyer = async (
  contextInfo,
  companyComertialName,
  buyerAddress,
  buyerLegalRepresentative,
  buyerContactName,
  companyEmail,
  buyerRepresentativeIdNum,
  buyerCredentialPass,
  setIsRequesting,
  lastTimeOutId,
  reCaptcha
) => {
  try {
    let timeOutId = handleRequestButton(setIsRequesting, lastTimeOutId)
    const createCompanyResponse = await createCompanyQuery(
      contextInfo,
      companyComertialName,
      reCaptcha
    )
    let companyId = null

    if (!createCompanyResponse.data.body) {
      companyId = createCompanyResponse.data.companyId
    } else {
      companyId = createCompanyResponse.data.body.companyId
    }

    timeOutId = handleRequestButton(setIsRequesting, timeOutId)
    const createBuyerResponse = await createBuyerQuery(
      contextInfo,
      companyId,
      buyerAddress,
      buyerLegalRepresentative,
      buyerContactName,
      buyerRepresentativeIdNum
    )

    if (createBuyerResponse.response) {
      const password = passwordEncrypt(buyerCredentialPass)
      handleRequestButton(setIsRequesting, timeOutId)
      const createBuyerCredentialsResponse = await createBuyerCredentialsQuery(
        createBuyerResponse.data.body.buyerId,
        companyEmail,
        password
      )

      return createBuyerCredentialsResponse
    }
    activateRequestButton(setIsRequesting, timeOutId)
    return null
  } catch (error) {
    console.error(companyRegistrationBuyerString.mistake, error)
  }
}

export const eyeShowPassword = (
  inputName,
  setEyeIcon,
  openEyeIcon,
  closeEyeIcon
) => {
  const element = document.getElementsByName(inputName)[0]
  if (element.type === 'password') {
    element.type = 'text'
    setEyeIcon(closeEyeIcon)
  } else {
    element.type = 'password'
    setEyeIcon(openEyeIcon)
  }
  element.focus()
}

export const handleSubmit = (
  event,
  contextInfo,
  companyComertialName,
  buyerAddress,
  buyerLegalRepresentative,
  buyerContactName,
  companyEmail,
  buyerRepresentativeIdNum,
  buyerCredentialPass,
  setShowPasswordIncorrectFormat,
  setShowRePasswordIncorrectFormat,
  navigate,
  isRequesting,
  setIsRequesting,
  areValidCredentials,
  reCaptcha
) => {
  event.preventDefault()
  if (isRequesting) return

  const timeOutId = handleRequestButton(setIsRequesting)
  if (
    !areValidCredentials.credentialPass ||
    !areValidCredentials.credentialRePass
  ) {
    activateRequestButton(setIsRequesting, timeOutId)
    return
  }
  formVerification(
    contextInfo,
    companyComertialName,
    buyerAddress,
    buyerLegalRepresentative,
    buyerContactName,
    companyEmail,
    buyerRepresentativeIdNum,
    buyerCredentialPass,
    setShowPasswordIncorrectFormat,
    setShowRePasswordIncorrectFormat,
    navigate,
    setIsRequesting,
    timeOutId,
    reCaptcha
  )
}

export const formVerification = async (
  contextInfo,
  companyComertialName,
  buyerAddress,
  buyerLegalRepresentative,
  buyerContactName,
  companyEmail,
  buyerRepresentativeIdNum,
  buyerCredentialPass,
  setShowPasswordIncorrectFormat,
  setShowRePasswordIncorrectFormat,
  navigate,
  setIsRequesting,
  lastTimeOutId,
  reCaptcha
) => {
  if (!buyerCredentialPass) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowPasswordIncorrectFormat('incorrectFormat')
  } else {
    setShowPasswordIncorrectFormat('showSpanPass')
  }
  if (!contextInfo.buyerCredentialRePass) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowRePasswordIncorrectFormat('incorrectFormat')
  } else if (buyerCredentialPass !== contextInfo.buyerCredentialRePass) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowRePasswordIncorrectFormat('incorrectFormat')
  } else {
    setShowRePasswordIncorrectFormat('showSpanPass')
  }

  if (
    buyerLegalRepresentative &&
    contextInfo.buyerRepresentativeIdType &&
    buyerRepresentativeIdNum &&
    buyerContactName &&
    buyerAddress &&
    contextInfo.companyPhone &&
    contextInfo.companyPhone.toString().length === 10 &&
    companyEmail &&
    buyerCredentialPass &&
    validatePassFormat(buyerCredentialPass) &&
    contextInfo.buyerCredentialRePass &&
    buyerCredentialPass === contextInfo.buyerCredentialRePass
  ) {
    const timeOutId = handleRequestButton(setIsRequesting, lastTimeOutId)
    const response = await CreateCompanyAndBuyer(
      contextInfo,
      companyComertialName,
      buyerAddress,
      buyerLegalRepresentative,
      buyerContactName,
      companyEmail,
      buyerRepresentativeIdNum,
      buyerCredentialPass,
      setIsRequesting,
      timeOutId,
      reCaptcha
    )
    if (!response.response) {
      console.error(response)
    } else {
      navigate('../RegistroExitoso')
    }
  }
}
