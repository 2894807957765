import React, { createContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { disableForwardAndBackwardNavigation } from '../utils/functions'
import { initialDataContextState } from '../utils/objects'

export const DataContext = createContext({})

export const DataProvider = ({ children }) => {
  const [contextInfo, setContextInfo] = useState(initialDataContextState)

  // Redirect to landing page if user refreshes any page on the app
  // ---------------------------------------------------------------
  const navigate = useNavigate()
  useEffect(() => {
    if (!contextInfo.activePage) {
      navigate('/')
    }
  }, [contextInfo.activePage])
  // ---------------------------------------------------------------

  disableForwardAndBackwardNavigation()

  const data = {
    contextInfo,
    setContextInfo,
    initialDataContextState
  }

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>
}
