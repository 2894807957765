import React from 'react'
// import { Link } from 'react-router-dom'
import { userBuyerString, conditionsAndPoliciesBuyerString } from '../../utils/strings'
import dbImage from '../../assets/icons/icon_bd_fondomorado.png'
// import downLoadImage from '../../assets/icons/icon_download_naranja.svg'
import './ConditionsAndPolicies.css'

export const ConditionsAndPolicies = () => {
  return (
<>
<section className='conditionsPoliciesSection1'>
<img className='conditionsPoliciesMainImg' src={dbImage} alt='data_base_icon' />
<div className='conditionsPoliciesSubContainer1'>
<h3 className='conditionsPoliciesH3'>{conditionsAndPoliciesBuyerString.dataBaseDescription}</h3>
<p className='conditionsPoliciesParagraph'>{userBuyerString.conditionsPoliciesParagraph}</p>
</div>
</section>
{/* <section className='conditionsPoliciesSection2'>
<Link className='conditionsPoliciesDownLoadLink'><img className='conditionsPoliciesDownLoadImg' src={downLoadImage} alt='DownLoad Icon' />{conditionsAndPoliciesBuyerString.politicalData}</Link>
<Link className='conditionsPoliciesDownLoadLink'><img className='conditionsPoliciesDownLoadImg' src={downLoadImage} alt='DownLoad Icon' />{conditionsAndPoliciesBuyerString.platformUsePolitical}</Link>
<Link className='conditionsPoliciesDownLoadLink'><img className='conditionsPoliciesDownLoadImg' src={downLoadImage} alt='DownLoad Icon' />{conditionsAndPoliciesBuyerString.terms}</Link>
</section> */}
</>

  )
}
