import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import checkIcon from '../../../../assets/icons/icon_bien.svg'

import { useNavigate } from 'react-router-dom'
import { maxSize } from '../../../../utils/validateFunctions'
import { useModal } from '../../../../hooks/useModal'
import { Modal } from '../../../../components/Modal/Modal'
import { contactBuyerString } from '../../../../utils/strings'
import { handleInputOnBlur, handleSubmit, handleInputOnChange, handleClickOkModaButton, allInputsChecked, validateCell, validateEmail, validateName } from './ContactFunctions'

import './Contact.css'

export const Contact = () => {
  const navigate = useNavigate()
  const [showModal, handleShow] = useModal()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    cell: '',
    subject: ''
  })
  const [inputsValidated, setInputsValidated] = useState({
    name: false,
    email: false,
    cell: false,
    subject: false
  })

  const [inputsChecked, setInputsChecked] = useState(false)

  useEffect(() => {
    setInputsChecked(allInputsChecked(inputsValidated))
  }, [inputsValidated])

  const [isRequesting, setIsRequesting] = useState(false)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    if (inputsChecked && !isRequesting) {
      handleSubmit(e, formData, handleShow, isRequesting, setIsRequesting, inputsChecked)
    }
  }

  return (
    <div className="contendContactanos">
      <label className="labelTextContactate">
        {contactBuyerString.leaveUs}
        <br />
        {contactBuyerString.message}
      </label>
      <Form
        className="constendFormsContactanos"
        id="form"
        onSubmit={handleFormSubmit}
      >
        <Form.Group
          as={Col}
          className="longGeneralFieldsContact"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label className="labelForm">{contactBuyerString.name}</Form.Label>
          <Form.Control
            className="formContactanos"
            autoComplete="off"
            type="text"
            name='name'
            required
            value={formData.name}
            onChange={(e) => validateName(e, inputsValidated, setInputsValidated, formData, setFormData)}
            onInput={(e) => maxSize(e, 'flName')}
            onBlur={(e) => handleInputOnBlur(e, formData, setFormData)}
          />
        </Form.Group>

        <Form.Group
          className="longGeneralFieldsContact"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label className="labelForm">{contactBuyerString.mail}</Form.Label>
          <Form.Control
            className="formContactanos"
            autoComplete="off"
            type="email"
            name='email'
            required
            value={formData.email}
            onChange={(e) => validateEmail(e, inputsValidated, setInputsValidated, formData, setFormData)}
            onInput={(e) => maxSize(e, 'email')}
          />
        </Form.Group>

        <Form.Group
          className="longGeneralFieldsContact"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label className="labelForm">{contactBuyerString.cell}</Form.Label>
          <Form.Control
            className="formContactanos"
            autoComplete="off"
            type="text"
            name='cell'
            minLength={10}
            maxLength={10}
            value={formData.cell}
            onChange={(e) => validateCell(e, inputsValidated, setInputsValidated, formData, setFormData)}
            required
            onInput={(e) => maxSize(e)}
          />
        </Form.Group>

        <Form.Group
          className="mb-4 longGeneralFields2Contact"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label className="labelForm">{contactBuyerString.subject}</Form.Label>
          <Form.Control
            className="textAreaContactanos"
            placeholder={contactBuyerString.descriptionPlaceholder}
            as="textarea"
            rows={3}
            required
            name='subject'
            value={formData.subject}
            onChange={(e) => handleInputOnChange(e, formData, setFormData, inputsValidated, setInputsValidated)}
            onBlur={(e) => handleInputOnBlur(e, formData, setFormData)}
          />
          <p className='subjectCounter'>{`${!formData.subject ? 0 : formData.subject?.length}${contactBuyerString.amount}`}</p>
        </Form.Group>
        <button
          type="submit"
          className={`${inputsChecked ? 'btnContactanos' : 'btnContactanosDisabled'}`}
          disabled={isRequesting}>
          {contactBuyerString.send}
        </button>
      </Form>
      <Modal isOpen={showModal}>
        <Modal.Content width={'modalLarge'}>
          <img src={checkIcon} alt="check-icon" className='modalIcon' />
          <p>
            {contactBuyerString.thanks}
            <br />
            {contactBuyerString.weWillContactYou}
          </p>
        </Modal.Content>
        <Modal.Buttons margin={'modalBtnShort'}>
          <button
            onClick={() => handleClickOkModaButton(navigate)}
            className="Modal-greenBtn"
          >
            {contactBuyerString.ok}
          </button>
        </Modal.Buttons>
      </Modal>
    </div>
  )
}
