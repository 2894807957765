import { React, createContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const CategoriesContext = createContext()

export const CategoriesProvider = ({ children }) => {
  const [categoriesSelected, setCategoriesSelected] = useState({})
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const isInCategoriesView = pathname.includes('Categorias')
    const lengthCategoriesObject = Object.keys(categoriesSelected).length
    const isInFirstCategoryView = pathname.includes('CategoriasBDUbicacion')
    if (!isInFirstCategoryView && isInCategoriesView && !lengthCategoriesObject) {
      navigate('/CategoriasBDUbicacion')
    }
  }, [categoriesSelected, pathname])

  const data = { categoriesSelected, setCategoriesSelected }
  return <CategoriesContext.Provider value={ data }>{children}</CategoriesContext.Provider>
}
