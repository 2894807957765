import React from 'react'

export const layoutDataStrings = {
  politics: 'Condiciones y políticas',
  changePassword: 'Cambio de contraseña',
  clientAttend: 'Atención al cliente',
  list: 'Listado'
}

export const authContextStrings = {
  sessionExpired: 'Su sesión expiró o se inició sesión en su cuenta desde otro dispositivo.',
  ok: 'OK'
}

export const indexBuyerString = {
  buyers: 'Compradores',
  account: 'Cuenta',
  dataBase: 'Base de datos',
  settings: 'Ajustes',
  logOut: 'Cerrar sesión',
  accept: 'Aceptar',
  cancel: 'Cancelar',
  logOutAsking: '¿Desea cerrar sesión?',
  logoTuData: 'Logo_TuData',
  logoPerson: 'logo_persona'
}

export const logInBuyerString = {
  serverErrorLogin: 'No fue posible realizar el inicio de sesión.'
}

export const userBuyerString = {
  loremIpsum:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus efficitur, odio et efficitur eleifend, nulla nulla pellentesque urna, id congue tellus ipsum a nulla. Integer id placerat metus. Etiam ac ligula eleifend, luctus eros pulvinar, viverra neque. Cras faucibus eros at tortor rutrum accumsan.',
  conditionsPoliciesParagraph:
    'Las bases de datos contienen información esencial como nombre, identificación, edad, género, correo electrónico y fecha de nacimiento. Mientras que la base de datos simple se enfoca en estos datos fundamentales para análisis demográficos y de tendencias, la base de datos especializada va más allá al incluir detalles adicionales como hobbies y preferencias. Esto permite un análisis más detallado y personalizado, así como una adaptación más precisa de servicios y comunicaciones.'
}

export const conditionsAndPoliciesBuyerString = {
  dataBaseDescription: 'Descripción de la Base de datos',
  politicalData: 'Política de tratamiento de datos',
  platformUsePolitical: 'Política de uso de la plataforma',
  terms: 'Términos y condiciones de Tudata'
}

export const deleteAccounButBuyerString = {
  deleteAccount: 'Eliminar cuenta',
  deleteData: 'Eliminar datos',
  paragraphLine1: '¡Lamentamos que te vayas!',
  paragraphLine2:
    'Te pedimos diligenciar el siguiente formato y en 1 día hábil daremos respuesta a tu requerimiento.',
  paragraphLine3: '¿Por qué vas a eliminar tu cuenta?',
  checkBoxOption1:
    'No estoy satisfecho con la información de las bases de datos',
  checkBoxOption2: 'No estoy de acuerdo con el precio de las bases de datos',
  checkBoxOption3: 'Otra:',
  generalParagraphPart1:
    'Recuerda que si tienes un contrato activo con nosotros, hay algunas condiciones que impiden eliminar tu cuenta de inmediato. Si tienes dudas, te invitamos a consultar',
  terms: 'Términos y condiciones',
  generalParagraphPart2: 'o lo estipulado en tu contrato.',
  deleteMyData: 'Deseo que se eliminen todos mis datos',
  ok: 'OK',
  textFollowup2:
    'Tu cuenta está en proceso de eliminación. Ten presente este número para que puedas dar seguimiento en tu correo.',
  settled: 'Radicado No. :',
  queries: 'Consultas',
  advertising: 'He recibido demasiada publicidad',
  noShare: 'Ya no quiero compartir mis datos',
  noSatisfied: 'No estoy satisfecho con el manejo de mis datos',
  agreePayment: 'No estoy de acuerdo con el pago recibido',
  inappropriateContent: 'He recibido contenido inapropiado',
  noAuthorized: 'No he autorizado el uso de mi información',
  other: 'Otra:',
  sendRequest: 'Enviar solicitud',
  selectAnOption: 'Seleccione una opción',
  request: 'Solicitud: ',
  reason: '\nMotivo: ',
  lineBreak: '\n'
}

export const passwordChangeBuyerString = {
  originalPassword: 'Contraseña',
  currentPassword: 'Contraseña actual',
  requiredField: 'Revisar los datos.',
  newPassword: 'Nueva contraseña',
  messageInvalid1: 'Contraseña de mínimo 8 caracteres',
  messageInvalid2:
    'La contraseña debe contener mínimo 8 caracteres, mayúsculas, minúsculas, números y símbolos (! # $ % & * , . : ? @ [ ] ^ _ { | } ~).',
  confirmPassword: 'Confirmar contraseña',
  dontEquals: 'La nueva contraseña y su confirmación no son iguales',
  notSame: 'Las contraseñas no coinciden. Vuelva a intentarlo',
  oldPasswordWrong: 'La contraseña original no coincide con la registrada',
  sameOldPasswordError:
    'La nueva contraseña es igual a la registrada previamente.',
  buttonChangePassword: 'Cambiar contraseña',
  password: 'password',
  text: 'text',
  iconEye: 'icon eye',
  changePassword: 'Cambiar contraseña',
  textPassword: (
    <span>
      Has cambiado satisfactoriamente tu contraseña.
      <br /> Vuelve a iniciar sesión para continuar.
    </span>
  ),
  ok: 'OK'
}

export const DataBaseBuyerStrings = {
  dataBaseChooseStrings: {
    selectDB: 'Selecciona la base de datos',
    step1of2: 'Paso 1 de 2',
    dataBase: 'Base de datos',
    DBSimple: 'Simple',
    DBSpecialized: 'Especializada',
    planDBSimple: 'planBDSimple',
    planDBSpecialized: 'planBDEspecializada',
    descriptionDBSimple:
      'Incluye nombre, identificación, edad, género, correo electrónico y fecha de nacimiento.',
    descriptionDBSpecialized:
      'Además de los datos básicos, incluye hobbies y preferencias en distintos sectores.',
    back: 'Volver',
    select: 'Seleccionar',
    skip: 'Omitir'
  },
  dataBaseDetailStrings: {
    dataBase: 'Base de datos ',
    userQuantity: 'Cantidad de usuarios: ',
    descriptionTitle: 'Descripción: ',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti magni error distinctio dolorum iure neque!',
    dueDateTitle: 'Fecha de vencimiento',
    dueDate: '05 Jul 2023',
    stateOfPayAccepted: 'Estado de pago aceptado, ',
    nowYouCanDownloadIt1: 'ahora está disponible esta ',
    database: 'Base de Datos, ',
    nowYouCanDownloadIt2: 'puedes descargarla a continuación.',
    pendingMessage1: 'Aún estamos validando tu pago, ',
    pendingMessage2: 'en minutos te daremos respuesta.',
    expiredMessage: 'Esta Base de datos actualmente está Vencida ',
    renovateQuestion: '¿Deseas renovar esta base de datos?',
    rejected: 'La compra de la Base de datos fue rechazada. ',
    again: 'Puedes volver a iniciar el proceso de compra en el siguiente ',
    link: 'enlace.',
    tip: 'Recuerda que puedes renovarlo desde el día siguiente al vencimiento.',
    renovateDB: 'Renovar Base de datos',
    download: 'Descargar',
    code1: 'Parece que no alcanzaste a ingresar el código durante la compra.',
    code2: 'Por favor haz click en el botón e ingresa el código que llegará a tu correo.',
    continue: 'Continuar'
  },
  dataBasePanelStrings: {
    newDataBase: 'Nueva Base de datos',
    purchaseDate: 'Fecha de compra',
    type: 'Tipo',
    usersQuantity: 'Cantidad de usuarios',
    dueDate: 'Fecha de vencimiento',
    DBState: 'Estado de la BD',
    noDB: 'No dispones de una base de datos. ',
    wannaBuy: '¿Te interesaría adquirir una?'
  },
  dataBasePriceStrings: {
    selectIdealPlan: 'Selecciona el plan ideal para ti',
    step2of2: 'Paso 2 de 2',
    dataBase: 'Base de datos',
    dbSimple: 'Simple',
    dbSpecialized: 'Especializada',
    back: 'Volver',
    months: '3 meses',
    price: '$000.000 COP',
    select: 'Seleccionar',
    ok: 'OK',
    notUsersToBuy:
      'Lo sentimos, no hay usuarios que hayan autorizado el uso de tus datos, pero puedes obtener una a través de una consulta personalizada'
  },
  notValidatedTitle: 'Tu cuenta está en proceso de verificación.',
  notValidatedBody: 'Se te notificará por correo cuando tu cuenta haya sido autorizada para utilizar los servicios de tudata.'
}

export const yourChoicesBuyerString = {
  notUsersToBuy:
    'No hay usuarios disponibles para comprar con las características especificadas.',
  continueBuy: 'Continuar compra',
  addOther: 'Agregar otro',
  ok: 'OK',
  cancel: 'Cancelar',
  delete: 'Eliminar',
  yourElections: 'Tus Elecciones',
  wishDelete: '¿Estás seguro de eliminar ',
  text: 'de ',
  symbolo: '?'
}

export const compBuyerString = {
  infoZero:
    'Recuerda que para bloquear tus datos de una entidad debes seguir los siguientes pasos:',
  infoOne:
    '1. Busca en nuestra base de datos a la entidad con su nombre comercial o su número de registro comercial (NIT).',
  infoTwo:
    '2. Dale clic para acceder a las opciones de la entidad y selecciona la opción de "bloquear".',
  infoThree:
    '3. Sigue las instrucciones para llenar el formulario y radicar la solicitud en el sistema. Recuerda que este proceso se demora 15 días hábiles desde el momento de la radicación de los papeles.',
  notFound: '¿No encuentras la entidad que estás buscando?',
  queries: 'Solicitudes',
  list: 'Listado',
  notAllowedContact: 'Contacto no permitido',
  askBlock: 'Bloquear',
  askAllow: 'Autorizar',
  activeBlock: 'Bloqueo activo',
  entityBlocked: 'Entidad bloqueada',
  blockInProcess: 'Bloqueo en proceso',
  authEntity: 'Entidad autorizada',
  nit: 'NIT',
  name: 'Nombre de la entidad',
  status: 'Estado',
  process: 'Proceso',
  generalSearch: 'Buscar',
  search: 'Buscar entidad o NIT',
  details: 'Detalles',
  searchQuery: 'Buscar solicitud',
  changePassword: 'Cambio de contraseña',
  changePlan: 'Cambio de plan',
  consultation: 'Consultas',
  termsAndPolicies: 'Condiciones y políticas',
  tryAnotherValidationMethod: 'Probar otro método',
  deleteAccount: 'Eliminar cuenta',
  blockEntity: 'Bloqueo para la entidad:',
  messageSendMail:
    'Te hemos enviado un código a tu correo electrónico para realizar la firma digital del derecho de petición. Ingresa el código:',
  readedAcepted: 'He leído y acepto',
  terms1: 'términos y condiciones',
  terms2: 'políticas de uso',
  requiredFields: 'Campos obligatorios',
  validate: 'Validar',
  resend: 'Reenviar código',
  iconInfo: 'icon Info',
  iconLupa: 'icon lupa',
  block: 'Bloquear',
  allow: 'Permitir',
  cancel: 'Cancelar',
  id: 'ID',
  typeRequest: 'Tipo de solicitud',
  company: 'Empresa',
  NIT: 'NIT',
  applicant: 'Solicitante',
  state: 'Estado',
  askChangePlan: 'Cambiar de plan',
  requestPayment: 'Solicitar pago',
  iconBloqueado: 'icon-bloqueado',
  iconProceso: 'icon-enproceso',
  leftArrow: 'left_white_arrow',
  entertainmentSports: 'Entretenimiento / Deportes',
  continue: 'Continuar',
  entitiesDetails:
    'Qué debo hacer si una empresa me sigue llamando después de bloquearla?',
  textEntitieDetails:
    'Si deseas que una empresa elimine tus datos de su base de datos y ya has bloqueado su acceso a tu información, puedes presentar una reclamación haciendo clic en el botón correspondiente.',
  textEntitieDetails2:
    'Este proceso es seguro y garantiza la protección de tu información personal. Además, recibirás actualizaciones sobre el estado de tu solicitud a través del número de radicado que se enviará a tu correo electrónico.',
  ok: 'OK',
  logoEntitie: 'logo/entidad',
  data: 'Datos',
  entitie: 'Entidad:',
  Estado: 'Estado:',
  dateQueri: 'Fecha de solicitud',
  dateBlock: 'Fecha de bloqueo',
  historial: 'Historial',
  dateAuth: 'Fecha Autorización',
  dateEnd: 'Fecha Vencimiento',
  stateProcess: 'Estado del proceso',
  download: 'Descargable',
  termExpiration: 'Vencimiento de término',
  Authorization: 'Autorización',
  finalized: 'Finalizado',
  iconDownload: 'descarga-icon',
  infoClaim:
    'Si la entidad te sigue enviando información, realiza un reclamo por contacto no permitido en el botón de "Reclamar".',
  claim: 'Reclamar',
  errorMessage: 'Página no encontrada...',
  indicators: 'Indicadores',
  text1:
    'Dependiendo del proceso que estés realizando, podrás verificar el estado de tu trámite a través de los siguientes indicadores:',
  states: 'Estado',
  text2:
    'Te permite conocer el avance de la solicitud de bloqueo de datos por parte de la empresa.',
  iconoPendiente: 'icono pendiente',
  iconoFinalizado: 'icono finalizado',
  iconoAutorizado: 'icono autorizado',
  iconoBloqueado: 'icono bloqueado',
  iconText1: 'Solicitud Pendiente',
  iconText2: 'Solicitud Finalizada',
  processes: 'Proceso:',
  text3:
    'Te muestra si la entidad está autorizada o bloqueada para acceder a tus datos personales.',
  authorizedEntity: 'Entidad Autorizada',
  blockedEntity: 'Entidad Bloqueada',
  filter: 'Filtrar',
  customerSupport: 'Atención al cliente',
  nonBlocked: 'Sin Bloquear',
  authorized: 'Autorizado',
  blocked: 'Bloqueado',
  maxCompaniesReached:
    'Has superado el máximo de solicitudes de bloqueo (3). ¿Deseas realizar el pago para bloquear la entidad o cambiar tu plan y tener bloqueos ilimitados?',
  maxCompaniesReachedTemp:
    'Has superado el máximo de solicitudes de bloqueo (3). ¿Deseas cambiar tu plan y tener bloqueos ilimitados?',
  mustAccept: 'Debes ingresar el código y aceptar términos y políticas',
  incorrectCode: 'Código incorrecto, inténtalo otra vez.'
}

export const categoriesBuyerStrings = {
  titleParagraph:
    'A continuación encontrarás categorías relacionadas con lo que te puede interesar en tu base de datos personalizada',
  titleGeneralParagraph:
    'A continuación encontrarás categorías relacionadas con lo que te puede interesar, selecciona por lo menos 2 categorías:',
  categoriesGenderStrings: {
    male: 'Hombre',
    female: 'Mujer',
    other: 'Otro',
    any: 'Cualquier género',
    gender: 'Género',
    amount: 'Cantidad de registros por selección actual: ',
    continue: 'Continuar',
    edit: 'Editar',
    ok: 'OK'
  },
  categoriesAgeStrings: {
    kidAge: '10 - 25',
    teenAge: '26 - 35',
    adultAge: '36 - 50',
    seniorAge: '51 - En adelante',
    age: 'Edad',
    amount: 'Cantidad de registros por selección actual: ',
    continue: 'Continuar',
    edit: 'Editar',
    ok: 'OK'
  },
  categoriesLocationStrings: {
    country: 'País',
    colombia: 'Colombia',
    state: 'Departamento',
    city: 'Ciudad',
    Bogota: 'Bogotá',
    Medellin: 'Medellín',
    Cali: 'Cali',
    Bucaramanga: 'Bucaramanga',
    Pasto: 'Pasto',
    location: 'Ubicación',
    amount: 'Cantidad de registros por selección actual: ',
    continue: 'Continuar',
    edit: 'Editar',
    ok: 'OK'
  },
  next: 'Siguiente',
  continue: 'Continuar',
  back: 'Volver'
}

export const accountBuyerString = {
  data: 'Datos',
  acquireDatabases: 'Adquirir bases de datos',
  activeDatabases: 'Base de datos activas',
  tel: 'Teléfono empresarial',
  businessMail: 'Correo empresarial',
  legalRepresentative: 'Representante legal',
  iconDatabase: 'icono base de datos',
  iconPhone: 'icono teléfono',
  iconEamil: 'icono email',
  cc: 'CC',
  notValidatedTitle: 'Tu cuenta está en proceso de verificación.',
  notValidatedBody: 'Se te notificará por correo cuando tu cuenta haya sido autorizada para utilizar los servicios de tudata.'
}

export const buyDBSelectUsersBuyerString = {
  users1: 'Usuarios',
  users2: 'usuarios',
  usersQuantity: 'Cantidad usuarios',
  next: 'Siguiente',
  usersNeeded: '¿Cuántos usuarios necesitas?',
  currentlyThere: 'Actualmente hay:',
  selectUsersQuantity:
    'Selecciona la cantidad de usuarios que necesitas y que estén disponibles en esta base de datos.'
}

export const buyDBResumeBuyerString = {
  DDBB: 'Base de datos',
  description: 'Descripción',
  databaseEntity: 'Base de datos para la entidad ',
  dataBaseSimple: 'Base de datos Simple',
  dataBaseSpecialized: 'Base de datos Especializada'
}

export const buyDBFinalResumeBuyerString = {
  data: 'Datos',
  price: 'Precio',
  DDBB: 'Base de datos',
  spacedDash: ' - ',
  usersQuantity: 'Cantidad de usuarios: ',
  next: 'Siguiente',
  pay: 'Pagar',
  haveReadAndAccept: 'He leído y acepto ',
  termsAndConditions: 'términos y condiciones',
  error: 'Ha ocurrido un error durante la compra, por favor vuelve a generar el proceso.',
  cancel: 'Cancelar',
  accept: 'Aceptar',
  planBDSimple: 'planBDSimple',
  simple: 'Simple',
  specilized: 'Especializada',
  iva: 1.19
}

export const buyDBUploadReceiptBuyerString = {
  dataForPayment: 'Datos para el pago',
  price: 'Precio',
  uploadReceipt: 'Subir comprobante',
  uploadedReceipt: 'Comprobante subido exitosamente',
  accept: 'Aceptar',
  enviar: 'Enviar',
  goToLoopay: 'Pagar por ',
  requestProcessPart1: (
    <span>
      Tu solicitud está en proceso. Ten presente este <br /> número para que
      puedas dar seguimiento en el <br /> panel de
    </span>
  ),
  requestProcessPart2: 'Atención al cliente.',
  ok: 'OK',
  filedNumber: 'Radicado No. :',
  allowedExtensions:
    'La extensión del archivo debe ser alguna de las siguientes: ',
  paymentMethod: 'Base de datos - Simple',
  usersAmount: 'Cantidad de usuarios: ',
  symbol: '$',
  iconCheck: 'icon-check',
  purchase: 'Compra',
  renewal: 'Renovación',
  active: 'Activa'
}

export const successfulTransactionBuyerString = {
  successfulTransaction: '¡Compra finalizada!',
  havePurchased: 'Has comprado la Base de datos ',
  tudata: 'tudata',
  useDDBB:
    'Estamos validando el comprobante de pago, a tu correo llegará la confirmación y las instrucciones para descargar la Base de Datos que compraste',
  continue: 'Continuar'
}
export const successfulRegisterBuyerString = {
  successfulRegister: '¡Registro finalizado!',
  validatingData: 'Estamos validando tus datos',
  tudata: 'tudata',
  checkEmail:
    'Revisas tu correo, allí llegará un código para validar el ingreso a ',
  continue: 'Continuar'
}

export const customerSupportString = {
  enterYourRegistrationNumber: 'Ingresa número de radicado',
  checkRequest:
    'Aquí puedes consultar las solicitudes con el número de radicado que te enviamos, lo puedes encontrar en el correo electrónico que registraste.',
  newPqrs: 'Nueva PQRS',
  faq: 'Preguntas frecuentes',
  filingNumber: 'Radicado No. : ',
  ok: 'OK',
  notValidRadicatedOrUser: 'Número de radicado o usuario no corresponde',
  iconMagnifyingGlass: 'icono lupa',
  iconInfo: 'info icon'
}

export const newPqrsBuyerString = {
  pqrsType: 'Tipo de PQRS',
  requestSelection: 'Seleccione su solicitud',
  paragraphLine1:
    'Para generar tu PQR, puedes elegir alguna de estas opciones, dependiendo de tu solicitud.',
  paragraphLine2Part1: 'Petición:',
  paragraphLine2Part2:
    ' Solicita el cambio de tu información como correo, teléfono o cualquier otro.',
  paragraphLine3Part1: 'Queja:',
  paragraphLine3Part2:
    ' Reporta cualquier problema que tengas con la aplicación.',
  paragraphLine4Part1: 'Reclamo:',
  paragraphLine4Part2:
    ' ¿Los problemas no se han solucionado? Realiza un reclamo.',
  paragraphLine5Part1: 'Sugerencias:',
  paragraphLine5Part2:
    ' Escríbenos tus opiniones para mejorar y darte lo mejor.',
  filedNumber: 'Radicado No. :',
  requestProcessPart1: (
    <span>
      Tu solicitud está en proceso. Ten presente este <br /> número para que
      puedas dar seguimiento en el <br /> panel de
    </span>
  ),
  requestProcessPart2: 'Atención al cliente.',
  description: 'Descripción',
  clear: 'Limpiar',
  send: 'Enviar',
  ok: 'OK',
  descriptionPlaceholder:
    'La descripción debe contener mínimo 40 caracteres. Recuerda no dejar espacios en blanco o saltos de línea (Enter) al inicio de la descripción.',
  paragraphLine6Part1: 'Felicitaciones:',
  paragraphLine6Part2: ' Comparte tus experiencias positivas y éxitos con nuestra aplicación. ¿Has tenido una gran experiencia que quisieras elogiar o compartir? ¡Nos encantaría saberlo!',
  amount: '/1800'
}

export const loginBuyerString = {
  welcome: '¡Bienvenido!',
  portal: 'Portal Compradores',
  back: 'Volver',
  login: 'Inicia sesión',
  welcomeText: 'Bienvenido de vuelta, por favor ingresa a tu cuenta',
  forgotPassword: '¿Olvidaste tu contraseña?',
  getIn: 'Ingresar',
  wanna: ' Quiero ',
  register: ' registrarme ',
  Email: 'Correo electrónico',
  password: 'Contraseña',
  spanFormatEmail: 'Email con formato incorrecto',
  spanFormatPass:
    'La contraseña debe contener mínimo 8 caracteres, mayúsculas, minúsculas, números y símbolos (! # $ % & * , . : ? @ [ ] ^ _ { | } ~).',
  tuDataLogo: 'Logo tudata',
  backArrow: 'flecha_volver',
  ok: 'OK',
  remember: 'Recordarme'
}

export const companyRegistrationBuyerString = {
  welcome: '¡Bienvenido!',
  portal: 'Portal Compradores',
  completeYourProfile: 'Completa tu perfil',
  back: 'Volver',
  areYouRegistered: '¿Ya estás registrado?',
  logIn: 'Inicia sesión',
  nameCommercialAlly: '*Nombre comercial de la entidad',
  companyLogo: 'Logo de la entidad',
  noFilesSelected: 'Ningún archivo seleccionado',
  companyName: '*Nombre empresarial',
  nit: '*NIT',
  requiredInput: '*Campo obligatorio',
  addres: 'Dirección de correspondencia',
  contactPersonName: 'Nombre persona de contacto',
  phone: 'Teléfono',
  attachDocument: 'Anexar RUT o cámara y comercio',
  continue: 'Continuar',
  sendRegister: 'Enviar registro',
  email: 'Email',
  password: 'Contraseña',
  department: '*Departamento',
  city: '*Ciudad',
  allowedExtensions:
    'La extensión del archivo debe ser alguna de las siguientes: ',
  lengthNit: 'El NIT debe contener 9 dígitos.',
  ok: 'OK',
  mistake: 'Error: '
}

export const companyRegistration2BuyerString = {
  legalRepresentative: '*Nombre representante legal',
  idRepresentatitve: '*Tipo de identificación del representante',
  idNumber: '*No de id',
  contactPerson: '*Nombre persona de contacto',
  dirCorrespondence: '*Dirección de correspondencia',
  telNotification: '*Teléfono para notificación',
  emailNotification: '*Email para notificación',
  password: '*Contraseña',
  repeatPassword: '*Repetir contraseña',
  ok: 'OK',
  buyerIdExists:
    'El número de identidad ingresado ya se encuentra registrado. Verifícalo nuevamente, por favor.',
  companyWithBuyer: 'Esta compañía ya tiene un comprador asociado.',
  emailNotMatch:
    'El email digitado no pertenece a la compañía actual. Verifícalo, por favor.',
  emailNotFound:
    'El email digitado no pertenece a la compañía actual ni a ninguna otra. Verifícalo, por favor.',
  phoneNotMatch:
    'El número de teléfono digitado no pertenece a la compañía actual. Verifícalo, por favor.',
  phoneNotFound:
    'El número de teléfono digitado no pertenece a la compañía actual ni a ninguna otra. Verifícalo, por favor.',
  expeditionCity: '*Ciudad de expedición',
  notValidCity:
    ' no es una ciudad de expedición válida. Verifica de nuevo, por favor.',
  phoneValidationByButton:
    'Parece que el teléfono digitado no pertenece a la compañía actual o quizás esta compañía ya tiene un comprador asociado. Vuelve a digitarlo, por favor.',
  emailValidationByButton:
    'Parece que el email digitado no pertenece a la compañía actual o quizás esta compañía ya tiene un comprador asociado. Vuelve a digitarlo, por favor.',
  minIdNumFormat: 'El número de identificación debe ser mayor o igual a 10000',
  maxIdNumFormat: 'El número de identificación debe menor o igual a 2000000000',
  passportIdFormat:
    'El número de pasaporte debe contener 10 caracteres en total entre números y letras.',
  error: 'Error:'
}

export const landingPageBuyerString = {
  us: 'Nosotros',
  contact: 'Contacto',
  FAQ: 'FAQ',
  getIn: 'Ingresar',
  logoTuData: 'Logo de TuData',
  FAQTitle: 'Preguntas frecuentes',
  usTitle: 'Nosotros',
  aboutUs: '¿Quiénes somos?',
  mision: 'Misión',
  vision: 'Visión'
}

export const contactBuyerString = {
  leaveUs: '¡Déjanos',
  message: 'tu mensaje!',
  name: 'Nombre completo',
  mail: 'Correo',
  cell: 'Teléfono',
  subject: 'Asunto',
  send: 'Enviar',
  thanks: '¡Gracias por tu mensaje!',
  weWillContactYou:
    'Nos pondremos en contacto contigo pronto. Por favor, asegúrate de revisar tanto tu bandeja de entrada como la carpeta de correo no deseado en tu correo electrónico.',
  ok: 'OK',
  amount: '/1800',
  descriptionPlaceholder: 'La descripción debe contener mínimo 40 caracteres. Recuerda no dejar espacios en blanco o saltos de línea (Enter) al inicio de la descripción.'
}

export const usModalBuyerString = {
  usText1: 'Más que una aplicación o un startup, ',
  tudata: 'tudata',
  usText2:
    ' es una solución legal al abuso de la posición dominante de empresas comerciales en el tratamiento y/o comercialización de datos personales de los usuarios, quienes a través de la aplicación podrán permitir o bloquear el uso de sus datos personales y recibir una retribución económica por esto. Por medio de solicitudes y derechos de petición, trabajaremos para brindarte la seguridad que tu información se merece.',
  usMision:
    'Bajo la estructura y organización de nuestra plataforma, pretendemos llegar a todas las personas de distintos estratos socioeconómicos, con la finalidad de incentivar y crear consciencia sobre la importancia del manejo y administración de sus datos personales. A través de TuData los usuarios podrán defender, proteger y gestionar el uso de sus datos personales, que se encuentran bajo la posición dominante de las empresas que comercializan sus datos sin que medie previa autorización. Es por esto que buscamos devolverle el poder de los datos personales a sus titulares, bajo mecanismos de protección legal que garanticen el libre desarrollo del derecho fundamental de Habeas data al tiempo que puedan obtener una retribución económica por su comercialización.',
  usVision:
    'Nuestra visión va encaminada en convertirnos en la empresa número uno en Colombia y Latinoamérica, con amplio reconocimiento a nivel internacional en la defensa del derecho fundamental de Habeas Data, pionera en la administración y comercialización de datos personales bajo el control total de su titular.',
  ok: 'Volver'
}

export const homeBuyerString = {
  welcome: '¡Bienvenido ',
  description: '',
  tudata: ' a Tudata!',
  logo: 'Logo',
  portal: 'Compradores'
}

export const firstSignInBuyerStrings = {
  enterCode: 'Ingresa el código que llegó a tu correo',
  back: 'Volver',
  header: (
    <span>
      Para validar tu usuario, debes ingresar el código <br /> que llegó a tu
      correo, este proceso se realiza una sola vez.
    </span>
  ),
  putYourCodeHere: 'Ingresa el código aquí',
  send: 'Enviar',
  wrongCode: 'Código incorrecto, inténtalo otra vez.',
  maxAttempts:
    'Ha excedido el límite de intentos de ingreso con un código de verificación incorrecto, debe esperar un tiempo para recuperar el acceso.',
  ok: 'OK'
}

export const passwordRecoveryBuyerStrings = {
  email: 'Correo electrónico',
  changeYourPassword: 'Cambia tu contraseña',
  changePassword: 'Cambia contraseña',
  passwordChangeSuccess: '¡Cambio de contraseña exitoso!',
  goToLogin: 'Por favor, accede nuevamente a la opción de "Inicio de sesión".',
  enterPassword:
    'Ingresa la nueva contraseña. (Debe tener mínimo 8 caracteres)',
  error: 'No fue posible cambiar la contraseña.',
  emailNotFound:
    'Correo no encontrado. Asegúrate de escribir correctamente el correo con el que te registraste.',
  password: 'Contraseña',
  confirmPassword: 'Confirmar contraseña',
  continue: 'Continuar',
  wrongPassword1:
    'Ingresa una combinación de al menos ocho números, letras y signos de puntuación',
  wrongPassword2: 'Las contraseñas no coinciden. Vuelve a intentarlo',
  wrongCode: 'Código incorrecto, inténtalo otra vez.',
  successfulPasswordChange1: '¡Cambio de contraseña exitoso!',
  successfulPasswordChange2:
    'Por favor, accede nuevamente a la opción de "Inicio de sesión".',
  registeredEmail: 'Escribe el correo con el que te registraste',
  codeSent:
    'Hemos enviado un código al correo registrado para que puedas recuperar tu contraseña.',
  veryWell: '¡Muy Bien!',
  logIn: 'Iniciar sesión',
  back: 'Volver',
  send: 'Enviar',
  ok: 'OK',
  maxAttempts:
    'Ha excedido el límite de intentos de ingreso con un código de verificación incorrecto, debe esperar un tiempo para recuperar el acceso.',
  backArrow: 'flecha_volver'
}

export const faqBuyerString = {
  general: [
    {
      question: '¿Dónde puedo acceder a Tudata?',
      answer:
        'Descargando la App en App Store para IOS, Google Play para Android o a través de la página web www.tudata.com.co'
    },
    {
      question: '¿Cuántos tipos de usuario tiene Tudata?',
      answer: 'Tiene 4 tipos de usuario ideales para todas las necesidades.'
    },
    {
      question: '¿Cuáles son los tipos de usuario?',
      answer: 'Data +, Data Premium, Data Control y Data Empresa.'
    }
  ],
  user: [
    {
      question: '¿Qué datos necesito para registrarme en Tudata?',
      answer:
        'Debes registrarte con tu nombre completo, cédula de ciudadanía, correo electrónico y número de teléfono de celular.'
    },
    {
      question: '¿Qué privilegios tengo al descargarme Tudata?',
      answer:
        'Ganar dinero que verás reflejado en “tu bolsillo" virtual mientras tienes control absoluto de tus datos personales.'
    },
    {
      question: '¿Cómo puedo registrarme en Tudata?',
      answer:
        'En nuestra App o a través de la página web deberás ingresar tus datos (nombre, cédula, fecha de nacimiento, correo electrónico y teléfono) y recibirás un correo electrónico con una clave de confirmación, la cual te permitirá el acceso a todos los beneficios que sólo TUDATA te da.'
    },
    {
      question: '¿Cómo puedo bloquear empresas?',
      answer:
        'Seleccionando las empresas que desees bloquear desde tu aplicación móvil Tudata o a través de la página web www.tudata.com.co'
    },
    {
      question:
        '¿Qué debo hacer para comercializar mis datos y generar ingresos?',
      answer:
        'Deberás ser usuario Data Premium puesto que, a través de este, empresas de tu selección podrán enviarte ofertas comerciales de tu interés, por cada una de ellas que use tu información recibirás una suma de dinero en Tu Bolsillo Virtual.'
    },
    {
      question: '¿Qué tipo de datos puedo comercializar para generar ingresos?',
      answer:
        '¡Todos! Porque tus datos son tu decisión. La comercialización de tus datos aplica para datos públicos, privados, semiprivado y sensibles'
    },
    {
      question:
        '¿Cómo puedo hacerle seguimiento a las acciones legales presentadas en contra de las entidades que comercializan mis datos sin autorización?',
      answer:
        'A través del aplicativo web ingresando tu usuario podrás evidenciar el estado de cada una de tus peticiones en el acápite de mis solicitudes.'
    },
    {
      question: '¿Qué es el plan de recompensas?',
      answer:
        'Es un plan que recompensa monetariamente a los usuarios por informar a Tudata cuando las empresas siguen enviando información comercial después de culminar el término de autorización estipulado por los usuarios.'
    },
    {
      question: '¿En qué ocasiones aplica el Plan de Recompensas?',
      answer:
        'Una vez terminado el plazo de autorización del uso y comercialización de tus datos, si las empresas de las cuales permitiste recibir información comercial continúan enviándote publicidad, puedes informar a TUDATA y por este reporte se te pagará una suma de dinero que podrás ver en tu Bolsillo Virtual.'
    },
    {
      question: '¿Cómo funciona el Plan de Recompensas?',
      answer:
        'Por cada vez que el usuario informe a Tudata haber recibido información comercial a través de correo electrónico, llamada o SMS de cualquier empresa que no tenga autorización habiendo culminado el plazo de la autorización para el uso de sus datos recibirá por parte de TUDATA una recompensa la cual se verá reflejada en Tu Bolsillo Virtual.'
    },
    {
      question: '¿Por cuánto tiempo puedo comercializar mis datos personales?',
      answer:
        'Inicialmente se celebra un contrato por tres (3) meses y durante ese tiempo no puedes retirar la autorización del tratamiento y comercialización de tus datos personales. Posteriormente puedes renovar este término o abstenerte de otorgar autorización a Tudata.'
    },
    {
      question:
        '¿Es necesario saber el término de autorización del uso y tratamiento de mis datos personales?',
      answer:
        'Sí, de esta manera sabes cual es el plazo en el que autorizas a empresas a enviarte su información comercial. Ya que después de culminado el término pactado, si la empresa con previa autorización sigue enviando información comercial puedes acceder al Plan Recompensa.'
    },
    {
      question:
        '¿Cómo puedo solicitar el cese de la comercialización de mis datos personales?',
      answer:
        'En virtud de la Ley 1581 de 2012 todo usuario podrá solicitar en cualquier momento el cese de la comercialización de los datos.'
    },
    {
      question:
        '¿Cada cuánto puedo generar ingresos por la comercialización de mis datos?',
      answer:
        'Cada vez que una empresa use tu autorización para el manejo de tus datos recibirás dinero en “tu bolsillo” virtual. Dependiendo del usuario al que estés suscrito, el monto de remuneración varía entre quinientos ($500) y ochocientos ($800) pesos.'
    },
    {
      question: '¿Cómo puedo redimir el saldo que se encuentra en mi bolsillo?',
      answer:
        'El saldo de dinero que tienes disponible lo podrás usar a través de la plataforma SUPERGIROS MOVIL donde podrás retirar el dinero en efectivo, jugar apuestas deportivas o juegos de suerte y azar. O si prefieres podrás abonar tu saldo a tu factura MOVISTAR.'
    },
    {
      question:
        'Si soy persona natural, ¿qué usuario de Tudata puedo escoger?',
      answer: 'Data +, Data Premium y Data Control.'
    },
    {
      question: 'Si soy persona jurídica, ¿qué usuario de Tudata es ideal?',
      answer: 'Data Empresa'
    },
    {
      question: '¿Cómo Tudata accede a mi información?',
      answer:
        'Tudata solo podrá acceder a tu información si aceptas los términos y condiciones, y otorgas a la APP la comercialización de tus datos personales.'
    },
    {
      question: '¿En qué está enfocada la modalidad de DATA +?',
      answer:
        'Data + está enfocada en la autorización de los usuarios mientras bloquean o permiten que empresas les envíen información comercial.'
    },
    {
      question: '¿Al permitir autorización en DATA + recibo algún beneficio?',
      answer:
        'Adicional al pago en tu bolsillo virtual, recibirás un mensaje de texto o correo electrónico informando cual empresa pagó por tu autorización y el tiempo máximo de uso.'
    },
    {
      question: '¿Qué protección tengo en DATA + contra las empresas?',
      answer:
        'Tudata ofrece protección total a todos los usuarios DATA + en todas las instancias, cuando estos soliciten acompañamiento legal.'
    },
    {
      question: '¿Qué significa bloquear en DATA +?',
      answer:
        'Restringir la recepción de SMS, llamadas, correos electrónicos de las empresas que buscan enviarte información comercial.'
    },
    {
      question: '¿Qué significa permitir en DATA +?',
      answer:
        'Permitir el uso y comercialización de tus datos por empresas que buscan enviarte información comercial.'
    },
    {
      question: '¿Qué beneficios tiene ser usuario DATA +?',
      answer:
        'De manera parcial bloqueará a todas las empresas que usen tu información sin tu autorización y de esta manera cesar con el recibo de SMS, llamadas, mensajes de WhatsApp o correos electrónicos ofreciendo publicidad y además podrás permitir recibir de empresas que sean de tu interés, información comercial previo un pago en dinero que verás reflejado en Tu Bolsillo Virtual.'
    },
    {
      question: '¿Qué beneficios tiene ser usuario DATA PREMIUM?',
      answer:
        'En este usuario podrás permitir que empresas de tu selección puedan conocer más de ti y así ofrecerte servicios o productos que realmente deseas. Aquí podrás escoger la información que deseas compartir y por cada vez que una empresa use tu autorización recibirás dinero en Tu Bolsillo Virtual.'
    },
    {
      question: '¿Cómo funciona DATA PREMIUM?',
      answer:
        'El funcionamiento de DATA PREMIUM se da a través de la autorización del usuario y la comercialización de sus datos personales con empresas de su preferencia. Aquí podrás escoger la información que deseas compartir y aumentar la posibilidad de generar aún más ingresos.'
    },
    {
      question: '¿Qué tipo de información entregan los usuarios DATA PREMIUM?',
      answer:
        'Los usuarios otorgan información personal más detallada como lo es: edad, sexo, tendencias políticas, hobbies, entre otras.'
    },
    {
      question:
        'Al permitir autorización en DATA Premium, ¿cuánto dinero recibo en mi bolsillo virtual?',
      answer:
        'Recibirás dinero cada vez que las empresas utilicen tu autorización, tu dinero verá reflejado en “tu bolsillo” virtual.'
    },
    {
      question: '¿Puedo escoger la información que comparto en DATA Premium?',
      answer:
        'Sí, los usuarios premium pueden escoger la información que desean compartir y así aumentar la posibilidad de generar más ingresos a su cuenta.'
    },
    {
      question: '¿Qué beneficios tiene ser usuario DATA CONTROL?',
      answer:
        'Si no deseas recibir ingresos por el uso de tu información personal y deseas bloquear o defender tus derechos de Habeas Data, Tudata te defenderá de cualquier empresa que use tu información por un único valor. Aquí te regalamos la defensa de 3 bloqueos, a partir de la cuarta empresa deberás pagar por cada empresa que desees bloquear.'
    },
    {
      question: '¿Cuánto ingresa a mi bolsillo virtual en DATA CONTROL?',
      answer:
        'Los usuarios Data Control no reciben ingresos por el uso de su información personal'
    },
    {
      question: '¿Cuántos bloqueos tengo permitido en DATA CONTROL?',
      answer:
        'Data Control ofrece la defensa de tres (3) bloqueos a empresas de tu preferencia de los usuarios por el valor inicial de la suscripción'
    },
    {
      question:
        '¿Qué tengo que hacer si quiero bloquear más de 3 empresas en DATA CONTROL?',
      answer:
        'A partir de la cuarta empresa que deseas bloquear, deberás pagar un monto específico por cada empresa.'
    },
    {
      question: '¿Cómo puedo cambiar de usuario?',
      answer:
        'Dentro de la aplicación encontraras la opción para hacer el cambio del tipo de usuario y dentro del aplicativo web a través de este enlace:'
    },
    {
      question:
        '¿Puedo utilizar mi dinero de “tu bolsillo” para pagar la suscripción de Data Control?',
      answer:
        'No, la redención del dinero no aplica para el pago de los planes.'
    }
  ],
  company: [
    {
      question: '¿A qué empresas está enfocado Data Empresa?',
      answer: 'A personas naturales, empresarios y pequeñas empresas.'
    },
    {
      question: '¿Qué beneficios tiene ser DATA EMPRESA?',
      answer:
        'Podrás adquirir bases de datos específicas de personas que se encuentran interesadas en adquirir los productos o servicios que tu empresa ofrece.'
    },
    {
      question: '¿Que ofrece Data Empresa a sus usuarios?',
      answer:
        'Tudata le envía las cantidades de usuarios que deseen para su base de datos y con las preferencias que necesiten'
    },
    {
      question: '¿Por cuánto tiempo me puedo suscribir a DATA EMPRESA?',
      answer: 'La suscripción de Data Empresa es anual (12 meses).'
    }
  ]
}

export const deleteAccountBuyerString = {
  ok: 'OK'
}

export const bannerBuyerString = {
  logo: 'logo empresa'
}

export const selectUserBuyerString = {
  typeOfPlan: 'El tipo de plan no es planBDSimple ni planBDEspecializada'
}

export const frequentQuestionsBuyerString = {
  buyersPortal: '¿Qué es el Portal de Compradores en tudata?',
  responseBuyerPortal:
    'El Portal de Compradores es una plataforma dentro de "tudata" que permite a empresas buscar y adquirir bases de datos de usuarios que han autorizado la compartición de su información personal. Ofrecemos dos tipos de bases de datos: simples y especializadas.',
  specializedDatabase:
    '¿Cuáles son las ventajas de utilizar una base de datos especializada en mis actividades de negocio?',
  userInformation:
    'Una base de datos especializada proporciona información detallada sobre las preferencias de los usuarios, lo que permite a las empresas llevar a cabo campañas y estrategias más enfocadas.',
  databasesReceived: '¿Dónde recibo la base de datos adquiridas?',
  receivedByEmail:
    'La base de datos adquirida llegará al correo registrado por la entidad por medio de un link. Estará disponible por un tiempo de tres meses, una vez pase ese tiempo, se debe hacer una renovación si el usuario así lo desea.',
  usersPrivacy:
    '¿Cómo se garantiza la privacidad de los usuarios en la venta de bases de datos en tudata?',
  tudataCommitment:
    'Tudata se compromete a cumplir con las regulaciones de privacidad vigentes y proteger los datos de los usuarios. Las bases de datos solo se venden a empresas que cumplen con estrictas políticas de privacidad y protección de datos.',
  resolveDoubts:
    '¿Dónde puedo obtener asistencia o hacer preguntas adicionales sobre el Portal de Compradores de "tudata"?',
  additionalQuestions:
    'Si tienes preguntas adicionales o necesitas asistencia, puedes ponerte en contacto con nuestro equipo de soporte a través de la sección de "Atención al cliente" en el Portal de Compradores.'
}

export const databaseFunctionsBuyerString = {
  undefinedPlan: 'El tipo de plan no es planBDSimple ni planBDEspecializada'
}

export const loginFunctionsBuyerString = {
  userNotExits: 'Usuario o contraseña incorrectos',
  attemptsLimit:
    'Ha excedido el límite de intentos de ingreso con una credencial incorrecta, debe esperar un tiempo para recuperar el acceso',
  accountInElimination:
    'La cuenta asociada a este correo está en proceso de eliminación, si desea recuperar su cuenta realice su solicitud a través del apartado contáctanos.',
  incorrectPassword: 'Contraseña incorrecta'
}

export const setNewPasswordBuyerString = {
  incorrectFormat: 'Contraseñas no coinciden o no cumplen formato'
}

export const queriesBuyerString = {
  contentNull: 'Error: content is null',
  notStringValues: 'Error: there is not-string values',
  authenticationError: 'Error de autenticación',
  error: 'Error:',
  serverError: 'Server error',
  clientError: 'Client error',
  tehereAreNoPqrs: 'No fue posible traer las pqrs',
  idNum: 'Error: idNum is null',
  idNumNotString: 'Error: idNum is not a string value',
  companyNull: 'Error: companyNit or companyEmail is null',
  companyNotString: 'Error: companyNit or companyEmail is not a string value',
  companyPhoneNull: 'Error: companyNit or companyPhone is null',
  companyNotString2: 'Error: companyNit or companyPhone is not a string value',
  inactive: 'Error: INACTIVE_SESSION',
  expired: 'Error: SESSION_EXPIRED'
}

export const homeStepsBuyerString = {
  basicInformation:
    'Bienvenido al panel de "Cuenta". Aquí podrás encontrar un resumen de tu información básica y las bases de datos activas.',
  entityInformation: 'Aquí está tu información básica de la entidad',
  databaseList:
    'Bienvenido al Panel de "Base de Datos". Aquí encontrarás un listado de bases de datos, tanto activas como vencidas',
  selectedDatabase:
    'Puedes seleccionar una base de datos y verificar a detalle cada una.',
  settingsPanel:
    'Bienvenido al panel de "Ajustes". Aquí podrás personalizar tu experiencia en la aplicación y encontrar toda la información relacionada con tus opciones y preferencias.',
  AdditionalInformation:
    'Aquí podrás leer las políticas de privacidad y términos y condiciones de uso de la aplicación.',
  password: 'Aquí podrás cambiar y registrar tu nueva contraseña.',
  frequentQuestions:
    'Aquí podrás encontrar respuestas a las preguntas más frecuentes, realizar PQRS y verificar el estado de tus solicitudes por medio del número de radicado.',
  details:
    'Dentro de esta pantalla de detalles de la base de datos, puedes interactuar con el botón según su estado.'
}

export const userquerisBuyerString = {
  valueNull: 'Error: there are null values',
  valuesNotString: 'Error: there are values that are not a string',
  notValueString: 'Error: the value is not a string',
  incorrectFormat: 'Error: there are values that are not the right format',
  databaseOrIDNull: 'Error: databaseType or companyId is null',
  databaseTypeIsNotNumber: 'Error: databaseType or count is not a number',
  databaseIsNotNumber: 'Error: databaseType is not a number',
  companyIdIsNotString: 'Error: companyId is not a string',
  error: 'Error:',
  countIsNotNumber: 'Error: count is not a number',
  radicateNumNull: 'Error: radicateNum is null',
  radicateNumIsNotString: 'Error: radicateNum is not string',
  buyerIdNull: 'Error: buyerId is null',
  buyerIdNotString: 'Error: buyerId is not string',
  databaseIdOrAmountNull: 'Error: databaseId or amount is null',
  databaseIdNotNumber: 'Error: databaseId or amount is not a number'
}

export const navFooterBuyerString = {
  return: 'Volver'
}

export const BaseLayoutBuyerString = {
  deleteAccount: 'Eliminar cuenta',
  notValidatedTitle: 'Tu cuenta está en proceso de verificación.',
  notValidatedBody: 'Se te notificará por correo cuando tu cuenta haya sido autorizada para utilizar los servicios de tudata.'
}

export const validateFunctionsBuyerString = {
  maxFileExceededPart1: 'Tu archivo pesa',
  maxFileExceededPart2: 'y supera el tamaño máximo permitido. El tamaño máximo para archivos multimedia es de',
  maxFileExceededPart3: 'y para archivos estáticos es de',
  measurementUnits: 'MB'
}

export const loopayCodeString = {
  entityCode: 'Código de bloqueo par la entidad: ',
  codeSent: 'Hemos enviado un código a tu correo electrónico para que puedas finalizar la compra.',
  enterCode: 'Ingresa el código en el campo inferior:',
  sendCode: 'Enviar código'
}
