import icon from '../../../../assets/icons/icon_empresas.svg'

export const showCompanyLogo = async (contextInfo, setLogoURL) => {
  if (Object.keys(contextInfo).length !== 0) {
    const logo = ''
    if (!logo) {
      try {
        const localLogo = contextInfo.sessionInfo.findBuyer.buyer.companyId.companyLogoPath
        setLogoURL(localLogo)
      } catch (error) {
        setLogoURL(icon)
      }
    } else {
      setLogoURL(logo)
    }
  }
}
