import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { BuyDBResume } from '../../components/BuyDBResume/BuyDBResume'

// Context
import { DataContext } from '../../../../context/DataContext'

// Utils
import { buyDBSelectUsersBuyerString } from '../../../../utils/strings'

import {
  handleChangeUsersToBuy,
  handleSubmit
} from './BuyDbSelectUsersFunctions'
// import { CategoriesContext } from '../../../../context/CategoriesContext'
import './BuyDBSelectUsers.css'
import { AuthContext } from '../../../../context/AuthContext'

export const BuyDBSelectUsers = () => {
  const { contextInfo, setContextInfo } = useContext(DataContext)
  const authContext = useContext(AuthContext)
  const [isRequesting, setIsRequesting] = useState(false)

  const navigate = useNavigate()
  const [usersToBuy, setUsersToBuy] = useState('')

  return (
    <section className="buyDBSelectUsers">
      <BuyDBResume planType={contextInfo.planType} />
      <div className="buyDBSelectUsersQuantity">
        <div className="buyDBSelectUsersLeft">
          <h2 className="buyDBSelectUsersLeftTitle buyDBSelectUsersTitle">
            {buyDBSelectUsersBuyerString.users1}
          </h2>
          <p className="buyDBSelectUsersLeftDescription">
            {buyDBSelectUsersBuyerString.selectUsersQuantity}
          </p>
          <p className="buyDBSelectUsersAvailability">
            {buyDBSelectUsersBuyerString.currentlyThere}{' '}
            <span className="buyDBSelectUsersAvailableUsers">
              {contextInfo.usersWhoWishToContactThem}
            </span>{' '}
            {buyDBSelectUsersBuyerString.users2}
          </p>
        </div>
        <div className="buyDBSelectUsersRight">
          <h2 className="buyDBSelectUsersRightTitle buyDBSelectUsersTitle">
            {buyDBSelectUsersBuyerString.usersQuantity}
          </h2>
          <div className="buyDBSelectUsersRightSubcontainer">
            <input
              className="buyDBSelectUsersQuantityInput"
              type="text"
              placeholder={buyDBSelectUsersBuyerString.usersNeeded}
              value={usersToBuy}
              onChange={(e) =>
                handleChangeUsersToBuy(
                  e,
                  contextInfo.usersWhoWishToContactThem,
                  setUsersToBuy
                )
              }
            />
            <button
              disabled={(usersToBuy <= 0 && usersToBuy === '') || isRequesting}
              className={`${
                usersToBuy > 0 && usersToBuy !== '' && !isRequesting
                  ? 'buyDBSelectUsersNextButton'
                  : 'buyDBSelectUsersNextButtonDisabled'
              }`}
              onClick={() =>
                handleSubmit(
                  Number(usersToBuy),
                  navigate,
                  setContextInfo,
                  contextInfo,
                  contextInfo.personalize,
                  authContext,
                  usersToBuy,
                  isRequesting,
                  setIsRequesting
                )
              }
            >
              {buyDBSelectUsersBuyerString.next}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
