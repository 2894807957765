import { homeStepsBuyerString } from './strings'

export const HomeStepsBuyer = [
  {
    content: homeStepsBuyerString.basicInformation,
    target: '#Account',
    placement: 'right-start',
    disableBeacon: true
  },
  {
    content: homeStepsBuyerString.entityInformation,
    target: '#Data',
    placement: 'bottom-start'
  }
]

export const DataBaseStepsBuyerString = [
  {
    content: homeStepsBuyerString.databaseList,
    target: '#DataBases',
    placement: 'right-start',
    disableBeacon: true
  },
  {
    content: homeStepsBuyerString.selectedDatabase,
    target: '#Listado',
    placement: 'bottom-start'
  }
]

export const ConfigStepsBuyerString = [
  {
    content: homeStepsBuyerString.settingsPanel,
    target: '#Config',
    placement: 'right-end',
    disableBeacon: true
  },
  {
    content: homeStepsBuyerString.AdditionalInformation,
    target: '#Conditions',
    placement: 'bottom-start'
  },
  {
    content: homeStepsBuyerString.password,
    target: '#ChangePassword',
    placement: 'bottom-start'
  },
  {
    content: homeStepsBuyerString.frequentQuestions,
    target: '#FAQ',
    placement: 'bottom-start'
  }
]

export const DetailDBStepsBuyerString = [
  {
    content: homeStepsBuyerString.details,
    target: '#AcceptBtn',
    placement: 'right-start',
    disableBeacon: true
  }
]
