import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { Modal } from '../../components/Modal/Modal'
import { useModal } from '../../hooks/useModal'
import IconCheck from '../../assets/icons/icon_bien.svg'
import {
  newPqrsBuyerString,
  passwordChangeBuyerString
} from '../../utils/strings'
import {
  optionsList,
  toggleSelectedOption,
  handleChange,
  onClickClean,
  handleDropdownClick,
  getPqrs,
  handlePqrsSubmit,
  handleDescriptionOnBlur
} from './NewPQRSFunctions'
import arrowIcon from '../../assets/icons/icon_flecha-8.png'
import './NewPQRS.css'
import { DataContext } from '../../context/DataContext'
import { AuthContext } from '../../context/AuthContext'

export const NewPQRS = () => {
  const initialValues = {
    description: null,
    file: []
  }
  const { contextInfo } = useContext(DataContext)
  const authContext = useContext(AuthContext)
  const { sessionData } = authContext
  const { buyerId } = sessionData
  const [values, setValues] = useState(initialValues)
  const [deactivateCleanButton, setDeactivateCleanButton] = useState(true)
  const [deactivateSendButton, setDeactivateSendButton] = useState(true)
  const [textDropdownToggle, setTextDropdownToggle] = useState(
    newPqrsBuyerString.requestSelection
  )
  const [optionSelected, setOptionSelected] = useState(
    optionsList.map((e) => false)
  )
  const [rotated, setRotated] = useState(false)
  const [pqrsId, setPqrsId] = useState(null)
  const [radicateNum, setRadicateNum] = useState(0)
  const [pqrsList, setpqrsList] = useState()
  const [showModal, handleShow] = useModal()
  const [showDropdownIncorrectFormat, setShowDropdownIncorrectFormat] =
    useState('hideObject')
  const [showContentIncorrectFormat, setShowContentIncorrectFormat] =
    useState('hideObject')
  const [isRequesting, setIsRequesting] = useState(false)
  const navigate = useNavigate()
  let pqrsListOnFront

  useEffect(() => {
    getPqrs(authContext, setpqrsList)
  }, [])

  if (pqrsList) {
    pqrsListOnFront = pqrsList
  } else {
    pqrsListOnFront = optionsList
  }

  const handleSubmit = (
    deactivateSendButton,
    isRequesting,
    setIsRequesting
  ) => {
    if (deactivateSendButton || isRequesting) return

    setDeactivateSendButton(true)
    handlePqrsSubmit(
      pqrsId,
      values.description,
      contextInfo,
      handleShow,
      setRadicateNum,
      setShowDropdownIncorrectFormat,
      setShowContentIncorrectFormat,
      setDeactivateSendButton,
      buyerId,
      setIsRequesting
    )
  }

  return (
    <section className="newPqrsMainContainer">
      <div>
        <h2 className="newPqrsH2">{newPqrsBuyerString.pqrsType}</h2>
        <div className="newPqrsSubContainer1">
          <Dropdown onClick={() => handleDropdownClick(setRotated, rotated)}>
            <Dropdown.Toggle className="requestTypeButton">
              {textDropdownToggle}
              <img
                className={`arrowIcon ${rotated ? 'arrowIconRotate' : ''}`}
                src={arrowIcon}
                alt="arrow icon"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu className="requestTypeDropdownMenu">
              {pqrsListOnFront.filter((pqrs, index) => [0, 1, 2, 3, 4, 8].includes(index)).map((pqrs) => {
                return (
                  <Dropdown.Item
                    key={pqrs.pqrsId}
                    className={`selectType-option ${
                      optionSelected[pqrs.pqrsId - 1] ? 'optionSelected' : ''
                    }`}
                    onClick={() =>
                      toggleSelectedOption(
                        pqrs.pqrsId,
                        optionSelected,
                        setOptionSelected,
                        setTextDropdownToggle,
                        pqrsListOnFront,
                        setPqrsId,
                        setDeactivateCleanButton
                      )
                    }
                  >
                    <p>{pqrs.pqrsName}</p>
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
            <span className={showDropdownIncorrectFormat}>
              {passwordChangeBuyerString.requiredField}
            </span>
          </Dropdown>
          <div className="newPqrsParagraphContainer">
            <p className="newPqrsParagraph1 firstParagraph">
              {newPqrsBuyerString.paragraphLine1}
            </p>
            <p className="newPqrsParagraph2">
              <span className="newPqrsSubtitle">
                {newPqrsBuyerString.paragraphLine2Part1}
              </span>
              {newPqrsBuyerString.paragraphLine2Part2}
            </p>
            <p className="newPqrsParagraph2">
              <span className="newPqrsSubtitle">
                {newPqrsBuyerString.paragraphLine3Part1}
              </span>
              {newPqrsBuyerString.paragraphLine3Part2}
            </p>
            <p className="newPqrsParagraph2">
              <span className="newPqrsSubtitle">
                {newPqrsBuyerString.paragraphLine4Part1}
              </span>
              {newPqrsBuyerString.paragraphLine4Part2}
            </p>
            <p className="newPqrsParagraph2">
              <span className="newPqrsSubtitle">
                {newPqrsBuyerString.paragraphLine5Part1}
              </span>
              {newPqrsBuyerString.paragraphLine5Part2}
            </p>
            <p className="newPqrsParagraph2">
              <span className="newPqrsSubtitle">
                {newPqrsBuyerString.paragraphLine6Part1}
              </span>
              {newPqrsBuyerString.paragraphLine6Part2}
            </p>
          </div>
        </div>
        <h2 className="newPqrsH2">{newPqrsBuyerString.description}</h2>
        <textarea
          name="NuevaPQR-textarea"
          id="NuevaPQR-textarea"
          className="newPqrsTextArea"
          value={values.description || ''}
          onChange={(e) =>
            handleChange(
              e,
              setValues,
              setDeactivateSendButton,
              setDeactivateCleanButton,
              values,
              initialValues
            )
          }
          onKeyDown={(e) =>
            handleChange(
              e,
              setValues,
              setDeactivateSendButton,
              setDeactivateCleanButton,
              values,
              initialValues
            )
          }
          onBlur={(e) =>
            handleDescriptionOnBlur(
              e,
              values,
              setValues,
              setDeactivateSendButton
            )
          }
          placeholder={newPqrsBuyerString.descriptionPlaceholder}
        ></textarea>
        <p className="newPqrsTextAreaLengthCounter">{`${
          !values.description ? 0 : values.description?.length
        }${newPqrsBuyerString.amount}`}</p>
        <span className={showContentIncorrectFormat}>
          {passwordChangeBuyerString.requiredField}
        </span>
        <div className="newPqrsButtonContainer">
          <button
            onClick={() =>
              onClickClean(
                initialValues,
                setValues,
                setDeactivateCleanButton,
                setDeactivateSendButton,
                deactivateCleanButton,
                isRequesting,
                setTextDropdownToggle
              )
            }
            disabled={deactivateCleanButton || isRequesting}
            className="newPqrsButtonStructure newPqrsButton"
          >
            {newPqrsBuyerString.clear}
          </button>
          <button
            onClick={() =>
              handleSubmit(deactivateSendButton, isRequesting, setIsRequesting)
            }
            disabled={deactivateSendButton || isRequesting}
            className="newPqrsButtonStructure newPqrsButton"
          >
            {newPqrsBuyerString.send}
          </button>
          <Modal isOpen={showModal}>
            <Modal.Content>
              <div className="newPqrsModalHeader">
                <img src={IconCheck} className="modalIcon" alt="icon-check" />
                <p>
                  {newPqrsBuyerString.filedNumber}
                  {radicateNum}
                </p>
              </div>
              <p className="newPqrsConsultationFirst">
                {newPqrsBuyerString.requestProcessPart1}{' '}
                <span className="newPqrsConsultationSecond">
                  {newPqrsBuyerString.requestProcessPart2}
                </span>
              </p>
            </Modal.Content>
            <Modal.Buttons>
              <button
                className="Modal-greenBtn"
                onClick={() => navigate('../../Ajustes/AtencionAlCliente')}
              >
                {newPqrsBuyerString.ok}
              </button>
            </Modal.Buttons>
          </Modal>
        </div>
      </div>
    </section>
  )
}
