import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Account } from './views/Account/Account'
import { BuyersBase } from './views/BuyersBase/BuyersBase'
import { Settings } from './views/Settings/Settings'
import { BuyDBSelectUsers } from './views/Account/views/BuyDBSelectUsers/BuyDBSelectUsers'
import { DataBasePanel } from './views/DataBase/views/DataBasePanel/DataBasePanel'
import { DataBaseDetail } from './views/DataBase/views/DataBaseDetail/DataBaseDetail'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { ConditionsAndPolicies } from './views/ConditionsAndPolicies/ConditionsAndPolicies'
import { PasswordChange } from './views/PasswordChange/PasswordChange'
import { DataBaseChoose } from './views/DataBase/views/DataBaseChoose/DataBaseChoose'
import { CategoriesGender } from './views/Categories/views/CategoriesGender/CategoriesGender'
import { CategoriesAge } from './views/Categories/views/CategoriesAge/CategoriesAge'
import { CategoriesLocation } from './views/Categories/views/CategoriesLocation/CategoriesLocation'
import { BuyDBUploadReceipt } from './views/Account/views/BuyDBUploadReceipt/BuyDBUploadReceipt'
import { SuccessfulTransaction } from './views/Account/views/SuccessfulTransaction/SuccessfulTransaction'
import { DeleteAccount } from './views/DeleteAccount/DeleteAccount'
import { CustomerSupport } from './views/CustomerSupport/CustomerSupport'
import { NewPQRS } from './views/NewPQRS/NewPQRS'
import { CategoriesGeneral } from './views/Categories/views/CategoriesGeneral/CategoriesGeneral'
import { SubCategories } from './views/Categories/views/SubCategories/SubCategories'
import { SubCategoriesItems } from './views/Categories/views/SubCategoriesItems/SubCategoriesItems'
import { YourChoices } from './views/Categories/views/YourChoices/YourChoices'
import { LogIn } from './views/LogIn/LogIn'
import { CompanyRegistration } from './views/CompanyRegistration/CompanyRegistration'
import { DataBasePrice } from './views/DataBase/views/DataBasePrice/DataBasePrice'
import { LandingPage } from './views/LandingPage/LandingPage'
import { Home } from './views/LandingPage/components/Home/Home'
import { CompanyRegistration2 } from './views/CompanyRegistration2/CompanyRegistration2'
import { SuccessfulRegister } from './views/SuccessfulRegister/SucessfulRegister'
import { VerificationCode } from './views/VerificationCode/VerificationCode'
import { DataProvider } from './context/DataContext'
import { FAQ } from './views/LandingPage/components/FAQ/FAQ'
import { UsView } from './views/LandingPage/components/UsView/UsView'
import { Contact } from './views/LandingPage/components/Contact/Contact'
import { NotFound } from './views/NotFound/NotFound'
import { PasswordRecovery } from './views/PasswordRecovery/PasswordRecovery'
import { SetEmail } from './views/PasswordRecovery/SetEmail/SetEmail'
import { PasswordRecoveryVerificationCode } from './views/PasswordRecovery/PasswordRecoveryVerificationCode/PasswordRecoveryVerificationCode'
import { SetNewPassword } from './views/PasswordRecovery/SetNewPassword/SetNewPassword'
import { PasswordRecoverySuccess } from './views/PasswordRecovery/PasswordRecoverySuccess/PasswordRecoverySuccess'
import { CategoriesProvider } from './context/CategoriesContext'
import { AuthProvider } from './context/AuthContext'
import { CompanyRegistration3 } from './views/CompanyRegistration3/CompanyRegistration3'
import { Amplify } from 'aws-amplify'
import amplifyconfig from './amplifyconfiguration.json'
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute'
import { PublicRoute } from './components/PublicRoute/PublicRoute'
import { Amplify as AmplifyClass } from './utils/amplify'
import { LoopayCode } from './views/LoopayCode/LoopayCode'
import { ScriptProvider } from './context/ScriptsContext'

Amplify.configure(amplifyconfig)

function App () {
  const amplify = new AmplifyClass()
  amplify.cleanToken()
  amplify.sign()
  return (
    <DataProvider>
      <AuthProvider>
        <CategoriesProvider>
          <ScriptProvider>
            <Routes>

              <Route path="/*" element={
                <PrivateRoute>
                  <Routes>
                    <Route path='buyer/:id' element={<BuyersBase />}>
                      <Route index element={<Account />} />
                      <Route path='Cuenta' element={<Account />} />
                      <Route path='BaseDeDatos/CompraBaseDatosPaso1' element={<BuyDBSelectUsers />} />
                      <Route path='BaseDeDatos/CompraBaseDatosPaso2' element={<BuyDBUploadReceipt />} />
                      <Route path='BaseDeDatos/CodigoLoopay' element={<LoopayCode />} />
                      <Route path='BaseDeDatos' element={<DataBasePanel />} />
                      <Route path='BaseDeDatos/DetalleDB' element={<DataBaseDetail />} />
                      <Route path='Ajustes' element={<Settings />}>
                        <Route path='CondicionesYPoliticas' element={<ConditionsAndPolicies />} />
                        <Route path='CambioDeContrasena' element={<PasswordChange />} />
                        <Route path='CondicionesYPoliticas/Eliminarcuenta' element={<DeleteAccount />} />
                        <Route path='AtencionAlCliente' element={<CustomerSupport />} />
                        <Route path='AtencionAlCliente/Nuevapqrs' element={<NewPQRS />} />
                      </Route>
                    </Route>
                    <Route path='/SeleccionPlan' element={<DataBaseChoose />} />
                    <Route path='/SeleccionPlan/:planType' element={<DataBasePrice />} />
                    <Route path='buyer/:id/BaseDeDatos/SeleccionPlan' element={<DataBaseChoose />} />
                    <Route path='buyer/:id/BaseDeDatos/SeleccionPlan/:planType' element={<DataBasePrice />} />
                    <Route path='/CategoriasBDGenero' element={<CategoriesGender />} />
                    <Route path='/CategoriasBDEdad' element={<CategoriesAge />} />
                    <Route path='/CategoriasBDUbicacion' element={<CategoriesLocation />} />
                    <Route path='/CategoriasBDGeneral' element={<CategoriesGeneral />} />
                    <Route path='/SubCategoriasBD' element={<SubCategories />} />
                    <Route path='/SubCategoriasItemsBD' element={<SubCategoriesItems />} />
                    <Route path='/TusEleccionesBD' element={<YourChoices />} />
                    <Route path='/TransaccionExitosa' element={<SuccessfulTransaction />} />
                  </Routes>
                </PrivateRoute>
              } />

              <Route path="/login" element={
                <PublicRoute>
                  <LogIn />
                </PublicRoute>
              } />

              <Route path="/RegistroEmpresa" element={
                <PublicRoute>
                  <CompanyRegistration />
                </PublicRoute>
              } />

              <Route path="/RegistroEmpresa2" element={
                <PublicRoute>
                  <CompanyRegistration2 />
                </PublicRoute>
              } />

              <Route path="/RegistroEmpresa3" element={
                <PublicRoute>
                  <CompanyRegistration3 />
                </PublicRoute>
              } />

              <Route path="/VerificationCode" element={
                <PublicRoute>
                  <VerificationCode />
                </PublicRoute>
              } />

              <Route path="/RegistroExitoso" element={
                <PublicRoute>
                  <SuccessfulRegister />
                </PublicRoute>
              } />

              <Route path="/RecuperarContrasena/*" element={
                <PublicRoute>
                  <Routes>
                    <Route path="/*" element={<PasswordRecovery />} >
                      <Route index element={<SetEmail />} />
                      <Route path='SetEmail' element={<SetEmail />} />
                      <Route path='PasswordRecoveryVerificationCode' element={<PasswordRecoveryVerificationCode />} />
                      <Route path='SetNewPassword' element={<SetNewPassword />} />
                      <Route path='PasswordRecoverySuccess' element={<PasswordRecoverySuccess />} />
                    </Route>
                  </Routes>
                </PublicRoute>
              } />

              <Route path='/' element={<LandingPage />} >
                <Route index element={
                  <PublicRoute>
                    <Home />
                  </PublicRoute>
                } />
                <Route path='/FAQ' element={
                  <PublicRoute>
                    <FAQ />
                  </PublicRoute>
                } />
                <Route path='/Nosotros' element={
                  <PublicRoute>
                    <UsView />
                  </PublicRoute>
                } />
                <Route path='/Contacto' element={
                  <PublicRoute>
                    <Contact />
                  </PublicRoute>
                } />
              </Route>

              <Route path='*' element={<NotFound />} />
            </Routes>
          </ScriptProvider>
        </CategoriesProvider>
      </AuthProvider>
    </DataProvider>
  )
}

export default App
