import { activateRequestButton, handleRequestButton } from '../../../utils/functions'
import { passwordRecoveryBuyerStrings } from '../../../utils/strings'
import { buyerPasswordRecoveryCodeVerify } from '../../../utils/userqueries'

export const handleSubmit = async (
  e,
  code,
  navigate,
  contextInfo,
  setShowWrongCode,
  setCode,
  handleShow,
  setModalMessage,
  isRequesting,
  setIsRequesting
) => {
  setShowWrongCode('showSpanPass')
  e.preventDefault()
  if (code.length < 6 || isRequesting) return

  const timeOutId = handleRequestButton(setIsRequesting)

  if (code.length < 6) {
    activateRequestButton(setIsRequesting, timeOutId)
    setShowWrongCode('incorrectFormat')
    return 0
  }

  handleRequestButton(setIsRequesting, timeOutId)
  const codeVerificationResponse = await buyerPasswordRecoveryCodeVerify(contextInfo.emailPasswordRecovery, code)

  if (codeVerificationResponse?.body?.includes('WRONG_CODE')) {
    activateRequestButton(setIsRequesting, timeOutId)
    setShowWrongCode('incorrectFormat')
    setCode('')
    activateRequestButton(setIsRequesting, timeOutId)
  } else if (codeVerificationResponse?.data?.code?.includes('BUYER_NOT_ENABLE_TRY_IN_ONE_MINUTE')) {
    setShowWrongCode('incorrectFormat')
    setCode('')
    setModalMessage(passwordRecoveryBuyerStrings.maxAttempts)
    handleShow()
  }

  if (codeVerificationResponse.body === 'CODE_VALIDATED') {
    navigate('/RecuperarContrasena/SetNewPassword')
  }
}

export const handleInputChange = ({ setCode, e, index, firstInput, setFirstInput }) => {
  const pivot = e.target.value.charAt(0)
  const value = /^[0-9]+$/.test(pivot) ? pivot : ''

  setCode(
    (prev) => prev.substring(0, index) + value + prev.substring(index + 1)
  )

  if (index === 0 && value) {
    setFirstInput(e.target)
  }

  if (index < 5 && value) {
    e.target.nextElementSibling.focus()
  } else if (index === 5 && value) {
    firstInput.focus()
  }
}

export const closeModalMaxAttempts = (navigate, handleClose) => {
  navigate('../../login')
  handleClose()
}
