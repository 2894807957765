import { handleRequestButton } from '../../../../utils/functions'
import {
  getDBPrice,
  getDataBase,
  getDemandCode
} from '../../../../utils/userqueries'
import { DataBaseBuyerStrings } from '../../../../utils/strings'

export const dateFormat = (fechaUtcStr) => {
  const fechaUtc = new Date(fechaUtcStr)
  // Aplicar la diferencia de zona horaria para obtener la hora local de Colombia (UTC-5)
  const fechaColombia = new Date(fechaUtc.getTime() - 5 * 60 * 60 * 1000)

  // Obtener las partes de la fecha (mes, día, año)
  const mes = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
    fechaColombia
  )
  const dia = fechaColombia.getDate()
  const año = fechaColombia.getFullYear()

  // Formatear la fecha en el estilo deseado (Oct 18 2023)
  const fechaFormateada = `${dia} ${mes} ${año}`
  return fechaFormateada
}

export const handleClickAcceptButton = async (
  navigate,
  dbType,
  amountOfUsers,
  authContext,
  contextInfo,
  setContextInfo,
  isRequesting,
  setIsRequesting
) => {
  if (isRequesting) return

  let databaseId = null
  if (dbType === DataBaseBuyerStrings.dataBaseChooseStrings.DBSimple) { databaseId = 1 }
  if (dbType === DataBaseBuyerStrings.dataBaseChooseStrings.DBSpecialized) { databaseId = 2 }

  handleRequestButton(setIsRequesting)
  const response = await getDBPrice(databaseId, amountOfUsers, authContext)

  if (response.response) {
    setContextInfo({
      ...contextInfo,
      planType:
        dbType === DataBaseBuyerStrings.dataBaseChooseStrings.DBSimple
          ? DataBaseBuyerStrings.dataBaseChooseStrings.planDBSimple
          : DataBaseBuyerStrings.dataBaseChooseStrings.planDBSpecialized,
      DBPrice: response.data.price,
      numberOfUsersToBuy: amountOfUsers,
      isADatabaseRenewal: true
    })
  }

  navigate('../BaseDeDatos/SeleccionPlan')
}

export const handleDownloadDB = async (
  isRequesting,
  setIsRequesting,
  shoppingHistoryId,
  authContext
) => {
  if (isRequesting) return null

  setIsRequesting(true)
  const resGetDatabase = await getDataBase(shoppingHistoryId, authContext)
  if (resGetDatabase.response) {
    const dbLink = resGetDatabase.body.download
    window.open(dbLink, '_blank')
    setIsRequesting(false)
  } else {
    setIsRequesting(false)
  }
}

export const handleInsertCode = async (contextInfo, authContext, navigate) => {
  const { sessionInfo } = contextInfo
  const requestData = {
    databaseType: 1,
    count: 0
  }
  await getDemandCode(sessionInfo, authContext, requestData)
  navigate('../BaseDeDatos/CodigoLoopay')
}
