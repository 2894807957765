import React, { useContext, useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import logo from '../../assets/icons/logo.svg'
import { passwordRecoveryBuyerStrings } from '../../utils/strings'
import { DataContext } from '../../context/DataContext'
import arrowIcon from '../../assets/icons/icon_flecha_blanco.svg'
import './PasswordRecovery.css'

export const PasswordRecovery = () => {
  const { contextInfo } = useContext(DataContext)
  const [mainMessage, setMainMessage] = useState('')
  const { pathname } = useLocation()

  useEffect(() => {
    if (contextInfo.passwordRecoverylocation === 'SetEmail') {
      setMainMessage(passwordRecoveryBuyerStrings.registeredEmail)
    }

    if (contextInfo.passwordRecoverylocation === 'PasswordRecoveryVerificationCode') {
      setMainMessage(passwordRecoveryBuyerStrings.veryWell)
    }

    if (contextInfo.passwordRecoverylocation === 'SetNewPassword') {
      setMainMessage(passwordRecoveryBuyerStrings.changeYourPassword)
    }

    if (contextInfo.passwordRecoverylocation === 'PasswordRecoverySuccess') {
      setMainMessage(passwordRecoveryBuyerStrings.changeYourPassword)
    }
  }, [contextInfo])

  return (
    <section className="passwordRecoveryContainer">
      <div className="passwordRecoveryLSideContainer">
        <img src={logo} className="passwordRecoveryTudatalogo" alt='App logo' />
        <div className="passwordRecoveryLSideText">
          {mainMessage}
        </div>
        {
          !pathname.includes('PasswordRecoverySuccess') &&
          <Link to='/login' className='passwordRecoveryReturnParagraph'><img className='returnArrow' src={arrowIcon} alt={passwordRecoveryBuyerStrings.backArrow} />{passwordRecoveryBuyerStrings.back}</Link>
        }
      </div>
      <div className="passwordRecoveryRSideContainer">
        <div className='passwordRecoveryRSideCodeFormContainer'>
          <Outlet />
        </div>
      </div>
    </section>
  )
}
