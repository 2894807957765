import React, { useContext, useEffect, useState } from 'react'

import './Banner.css'
import { showCompanyLogo } from './BannerFunctions'
import { DataContext } from '../../../../context/DataContext'
import { bannerBuyerString } from '../../../../utils/strings'

export const Banner = ({ enterpriseName }) => {
  const { contextInfo } = useContext(DataContext)
  const [logoURL, setLogoURL] = useState()

  useEffect(() => {
    showCompanyLogo(contextInfo, setLogoURL)
  }, [])

  return (
    <div className="banner">
      <div className="bannerImgContainer">
        <img className="logoImg" src={logoURL} alt={bannerBuyerString.logo} />
      </div>
      <h2 className="bannerEnterpriseName">{enterpriseName}</h2>
    </div>
  )
}
