import React from 'react'
import './Modal.css'

export const Modal = ({ isOpen, children }) => {
  if (!isOpen) {
    return null
  }
  return (
    <section
      className={`ModalBackground ${!isOpen ? 'modalClose' : 'modalOpen'}`}
    >
      <div className="ModalBody">{children}</div>
    </section>
  )
}

const Content = ({ children, width }) => {
  return <div className={`ModalBody-content ${!width ? '' : width}`}>{children}</div>
}

const Buttons = ({ children, margin }) => {
  return <div className={`ModalBody-buttons ${!margin ? '' : margin}`}>{children}</div>
}

Modal.Content = Content
Modal.Buttons = Buttons
