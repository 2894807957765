import {
  getCities,
  getStates,
  validateNumberOfSelectionsbyCity
} from '../../../../utils/userqueries'
import { categoriesBuyerStrings } from '../../../../utils/strings'

export const getPlaces = async (places, setPlaces, locationSelected) => {
  const states = await getStates()
  const cities = await getCities()
  const citiesFiltered = cities.cities.filter(
    (city) => city.stateId.stateId === Number(locationSelected.state)
  )
  setPlaces({ ...places, cities: citiesFiltered, states: states.states })
}

export const handleClickContinueBtn = async (
  selection,
  categoriesSelected,
  setCategoriesSelected,
  locationSelected,
  authContext,
  setModalData,
  modalData,
  handleShow
) => {
  const { categoriesLocationStrings } = categoriesBuyerStrings
  const { country, state, city } = selection

  if (!country || !state || isNaN(city) || !city) return

  setCategoriesSelected({ ...categoriesSelected, city: locationSelected.city })
  const amount = await validateNumberOfSelectionsbyCity(
    authContext,
    locationSelected.city
  )
  // modal alert continuar / corregir
  setModalData({
    ...modalData,
    message: categoriesLocationStrings.amount
  })
  setModalData({ ...modalData, amount: amount?.count })
  handleShow()
}
