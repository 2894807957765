import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form } from 'react-bootstrap'

import { DataContext } from '../../context/DataContext'
import logo from '../../assets/icons/logo.svg'
import arrowIcon from '../../assets/icons/icon_flecha_blanco.svg'
import {
  companyRegistrationBuyerString,
  passwordChangeBuyerString
} from '../../utils/strings'
import {
  createRegistrationDataList,
  handleChangeCompanyCommercialAndBusinessName,
  verificationInput,
  handleStateAndCity,
  handleChangeLogoDocument,
  handleChangeNitValue,
  handleChangeRutDocument,
  handleChangeState,
  handleSubmit
} from '../CompanyRegistration/CompanyRegistrationFunctions'
import './CompanyRegistration.css'
import { AuthContext } from '../../context/AuthContext'
import { Modal } from '../../components/Modal/Modal'
import { useModal } from '../../hooks/useModal'

export const CompanyRegistration = () => {
  const { contextInfo, setContextInfo, initialDataContextState } =
    useContext(DataContext)
  const { sessionData, setSessionData, initialAuthContextState } =
    useContext(AuthContext)
  const { companyComertialName } = sessionData

  const navigate = useNavigate()

  const [values, setValues] = useState({
    commercialName: '',
    companyName: '',
    logoDocument: null,
    nitValue: '',
    rutDocument: null,
    statesList: [],
    citiesList: [],
    department: undefined
  })

  const [showModal, handleShow, handleClose] = useModal()

  const [logoFile, setLogoFile] = useState(null)
  const [logoName, setLogoName] = useState(null)

  const [RUTFile, setRUTFile] = useState(null)
  const [RUTName, setRUTName] = useState(null)

  const [modalMessage, setModalMessage] = useState('')

  useEffect(() => {
    setContextInfo({ ...initialDataContextState, activePage: true })
    setSessionData(initialAuthContextState)
    createRegistrationDataList(values, setValues)
  }, [])

  const [showIncorrectFormat, setShowIncorrectFormat] = useState({
    commercialName: 'showSpanPass',
    companyName: 'showSpanPass',
    logo: 'showSpanPass',
    nit: 'showSpanPass',
    department: 'showSpanPass',
    city: 'showSpanPass',
    rut: 'showSpanPass'
  })

  return (
    <div className="companyRegistrationMainContainer">
      <section className="companyRegistrationSubContainer1">
        <div className="companyRegistrationImgPragraphContainer">
          <img
            src={logo}
            className="companyRegistrationSubContainer1Logo"
            alt={companyRegistrationBuyerString.tuDataLogo}
          ></img>
          <p className="companyRegistrationContainer1Paragraph">
            {companyRegistrationBuyerString.completeYourProfile}
          </p>
        </div>
        <h2 className="companyRegistrationContainer1H2">
          {companyRegistrationBuyerString.welcome}
          <br />
          {companyRegistrationBuyerString.portal}
        </h2>
        <Link to="/" className="compayRegistrationReturnParagraph">
          <img
            className="returnArrow"
            src={arrowIcon}
            alt={companyRegistrationBuyerString.backArrow}
          />
          {companyRegistrationBuyerString.back}
        </Link>
      </section>
      <section className="companyRegistrationSubContainer2">
        <Link to="/login" className="companyRegistrationLogInLink1">
          {companyRegistrationBuyerString.areYouRegistered}{' '}
          <span className="companyRegistrationLogInLink2">
            {companyRegistrationBuyerString.logIn}
          </span>
        </Link>

        <div className="companyRegistrationInputs">
          <p className="obligatoryField">
            {companyRegistrationBuyerString.requiredInput}
          </p>
          <div className="companyRegistrationInputsContainer">
            <Form.Control
              className="companyRegistrationContainer2Form"
              autoComplete="off"
              id="companyComertialName"
              name="companyComertialName"
              type="text"
              maxLength="48"
              placeholder={companyRegistrationBuyerString.nameCommercialAlly}
              required
              value={values.commercialName}
              onChange={(e) =>
                handleChangeCompanyCommercialAndBusinessName(
                  e,
                  contextInfo,
                  setContextInfo,
                  sessionData,
                  setSessionData,
                  values,
                  setValues
                )
              }
              onBlur={(e) =>
                verificationInput(
                  companyComertialName,
                  'commercialName',
                  showIncorrectFormat,
                  setShowIncorrectFormat,
                  e,
                  values,
                  setValues,
                  contextInfo,
                  setContextInfo
                )
              }
            />
            <span className={showIncorrectFormat.commercialName}>
              {passwordChangeBuyerString.requiredField}
            </span>
          </div>
          <div className="companyRegistrationInputsContainer">
            <Form.Control
              className="companyRegistrationContainer2Form"
              autoComplete="off"
              id="companyBussinesName"
              name="companyBussinesName"
              type="text"
              maxLength="48"
              placeholder={companyRegistrationBuyerString.companyName}
              required
              value={values.companyName}
              onChange={(e) =>
                handleChangeCompanyCommercialAndBusinessName(
                  e,
                  contextInfo,
                  setContextInfo,
                  sessionData,
                  setSessionData,
                  values,
                  setValues
                )
              }
              onBlur={(e) =>
                verificationInput(
                  contextInfo.companyBussinesName,
                  'companyName',
                  showIncorrectFormat,
                  setShowIncorrectFormat,
                  e,
                  values,
                  setValues,
                  contextInfo,
                  setContextInfo
                )
              }
            />
            <span className={showIncorrectFormat.companyName}>
              {passwordChangeBuyerString.requiredField}
            </span>
          </div>
          <div className="companyRegistrationInputsContainer">
            <div className="companyRegistrationInputButtonContainer">
              <label
                htmlFor="fileInputImg"
                className="companyRegistrationFileCustomButton"
              >
                *{companyRegistrationBuyerString.companyLogo}
              </label>
              <input
                type="file"
                id="fileInputImg"
                className="hidden"
                accept=".jpg,.jpeg,.png"
                name="logo"
                required
                onChange={(e) =>
                  handleChangeLogoDocument(
                    e,
                    values,
                    setValues,
                    contextInfo,
                    setContextInfo,
                    showIncorrectFormat,
                    setShowIncorrectFormat,
                    setLogoName,
                    setLogoFile,
                    setModalMessage,
                    handleShow
                  )
                }
              />
              {values.logoDocument === null
                ? (
                <div className="companyRegistrationfileName">
                  {companyRegistrationBuyerString.noFilesSelected}
                </div>
                  )
                : (
                    values.logoDocument && (
                  <div className="companyRegistrationfileName">{logoName}</div>
                    )
                  )}
            </div>
            <span className={showIncorrectFormat.logo}>
              {passwordChangeBuyerString.requiredField}
            </span>
          </div>
          <div className="companyRegistrationInputsContainer">
            <Form.Control
              className={'companyRegistrationContainer2Form'}
              autoComplete="off"
              id="companyNit"
              name="companyNit"
              type="text"
              value={values.nitValue}
              maxLength={9}
              placeholder={companyRegistrationBuyerString.nit}
              onChange={(e) =>
                handleChangeNitValue(
                  e,
                  contextInfo,
                  setContextInfo,
                  values,
                  setValues,
                  showIncorrectFormat,
                  setShowIncorrectFormat
                )
              }
              onBlur={(e) =>
                handleChangeNitValue(
                  e,
                  contextInfo,
                  setContextInfo,
                  values,
                  setValues,
                  showIncorrectFormat,
                  setShowIncorrectFormat
                )
              }
              required
              onKeyDown={(e) => {
                if (
                  e.key === 'e' ||
                  e.key === '+' ||
                  e.key === '.' ||
                  e.key === ',' ||
                  e.key === '-' ||
                  e.key === 'ArrowDown' ||
                  e.key === 'ArrowUp'
                ) {
                  e.preventDefault()
                }
              }}
            />
            <span className={showIncorrectFormat.nit}>
              {companyRegistrationBuyerString.lengthNit}
            </span>
          </div>
          <div className="companyRegistrationInputsContainer">
            <Form.Select
              size="sm"
              className="companyRegistrationContainer2Form"
              onClick={(e) =>
                handleStateAndCity(
                  e,
                  contextInfo,
                  setContextInfo,
                  values,
                  setValues
                )
              }
              onChange={(e) =>
                handleChangeState(
                  e,
                  contextInfo,
                  setContextInfo,
                  values,
                  setValues
                )
              }
              required
              name="departmentId"
              onBlur={(e) =>
                verificationInput(
                  values.department,
                  'department',
                  showIncorrectFormat,
                  setShowIncorrectFormat,
                  e,
                  values,
                  setValues,
                  contextInfo,
                  setContextInfo
                )
              }
            >
              <option key="0department" hidden value="">
                {companyRegistrationBuyerString.department}
              </option>
              {values.statesList.map((item) => (
                <option
                  key={item.stateId + item.stateName}
                  value={item.stateId}
                >
                  {item.stateName}
                </option>
              ))}
            </Form.Select>
            <span className={showIncorrectFormat.department}>
              {passwordChangeBuyerString.requiredField}
            </span>
          </div>
          <div className="companyRegistrationInputsContainer">
            <Form.Select
              size="sm"
              className="companyRegistrationContainer2Form"
              onChange={(e) =>
                handleStateAndCity(
                  e,
                  contextInfo,
                  setContextInfo,
                  values,
                  setValues
                )
              }
              required
              name="cityId"
              disabled={
                values.department === '0' || !values.department ? true : ''
              }
              onBlur={(e) =>
                verificationInput(
                  contextInfo.cityId || contextInfo.cityId !== undefined,
                  'city',
                  showIncorrectFormat,
                  setShowIncorrectFormat,
                  e,
                  values,
                  setValues,
                  contextInfo,
                  setContextInfo
                )
              }
            >
              <option key="0department" hidden value="">
                {companyRegistrationBuyerString.city}
              </option>
              {values.citiesList
                .sort((a, b) => a.cityName.localeCompare(b.cityName))
                .filter((city) => {
                  if (city.stateId.stateId === parseInt(values.department)) {
                    return true
                  } else {
                    return false
                  }
                })
                .map((item) => (
                  <option key={item.cityId + item.cityName} value={item.cityId}>
                    {item.cityName}
                  </option>
                ))}
            </Form.Select>
            <span className={showIncorrectFormat.city}>
              {passwordChangeBuyerString.requiredField}
            </span>
          </div>
          <div className="companyRegistrationInputsContainer">
            <div className="companyRegistrationInputButtonContainer">
              <label
                htmlFor="fileInputDoc"
                className="companyRegistrationFileCustomButton"
              >
                *{companyRegistrationBuyerString.attachDocument}
              </label>
              <input
                type="file"
                id="fileInputDoc"
                className="hidden"
                accept=".jpg,.jpeg,.png,.pdf"
                name="RUToCCFile"
                required
                onChange={(e) =>
                  handleChangeRutDocument(
                    e,
                    values,
                    setValues,
                    contextInfo,
                    setContextInfo,
                    showIncorrectFormat,
                    setShowIncorrectFormat,
                    setRUTName,
                    setRUTFile,
                    setModalMessage,
                    handleShow
                  )
                }
              />
              {values.rutDocument === null
                ? (
                <div className="companyRegistrationfileName">
                  {companyRegistrationBuyerString.noFilesSelected}
                </div>
                  )
                : (
                    values.rutDocument && (
                  <div className="companyRegistrationfileName">{RUTName}</div>
                    )
                  )}
            </div>
            <span className={showIncorrectFormat.rut}>
              {passwordChangeBuyerString.requiredField}
            </span>
          </div>
        </div>
        <button
          onClick={() =>
            handleSubmit(
              contextInfo,
              companyComertialName,
              setContextInfo,
              navigate,
              showIncorrectFormat,
              setShowIncorrectFormat,
              values.logoDocument,
              values.rutDocument,
              values.department
            )
          }
          className="companyRegistrationContinueButton"
        >
          {companyRegistrationBuyerString.continue}
        </button>
      </section>
      <Modal isOpen={showModal}>
        <Modal.Content>
          {(!logoFile || !RUTFile) && <p>{modalMessage}</p>}
        </Modal.Content>
        <Modal.Buttons>
          {(!logoFile || !RUTFile) && (
            <button className="Modal-greenBtn" onClick={handleClose}>
              {companyRegistrationBuyerString.ok}
            </button>
          )}
        </Modal.Buttons>
      </Modal>
    </div>
  )
}
