import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  firstSignInBuyerStrings,
  passwordChangeBuyerString,
  passwordRecoveryBuyerStrings
} from '../../../utils/strings'
import { Form } from 'react-bootstrap'
import * as functions from './PasswordRecoveryVerificationCodeFunctions.js'
import { DataContext } from '../../../context/DataContext'
import { Modal } from '../../../components/Modal/Modal.jsx'
import { useModal } from '../../../hooks/useModal'
import './../PasswordRecovery.css'
import { handleCode, handlePaste } from '../../../utils/functions.jsx'

export const PasswordRecoveryVerificationCode = () => {
  const { contextInfo } = useContext(DataContext)
  const { setContextInfo } = useContext(DataContext)
  const [showWrongCode, setShowWrongCode] = useState('showSpanPass')
  const [, setFirstInput] = useState(null)
  const [code, setCode] = useState('')
  const navigate = useNavigate()

  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState(null)
  const [isRequesting, setIsRequesting] = useState(false)

  useEffect(() => {
    setContextInfo({
      ...contextInfo,
      PasswordRecoveryVerificationCode: code,
      passwordRecoverylocation: 'PasswordRecoveryVerificationCode'
    })
  }, [code])

  return (
    <div>
      <Form className="firstSignInRSideCodeForm">
        <p className="passwordRecoveryRSideTopText">
          {passwordRecoveryBuyerStrings.codeSent}
        </p>
        <span className={showWrongCode}>
          {passwordRecoveryBuyerStrings.wrongCode}
        </span>
        <div className="firstSignInRSideInputsGroup">
          {Array.from(new Array(6), (_, i) => i).map((index) => (
            <Form.Control
              key={`input_${index}`}
              className="firstSignInRSideInput"
              type="text"
              onChange={(e) => handleCode(e, index, code, setCode)}
              onPaste={(e) => handlePaste(e, index, code, setCode)}
              value={code[index] || ''}
              required
              maxLength="1"
              ref={index === 0 ? (input) => setFirstInput(input) : null}
            />
          ))}
        </div>
        <span className="firstSignInRsideCodeAdvice">
          {firstSignInBuyerStrings.putYourCodeHere}
        </span>
        <button
          disabled={code.length < 6 || isRequesting}
          className="firstSignInRSideSendBtn"
          onClick={(e) =>
            functions.handleSubmit(
              e,
              code,
              navigate,
              contextInfo,
              setShowWrongCode,
              setCode,
              handleShow,
              setModalMessage,
              isRequesting,
              setIsRequesting
            )
          }
        >
          {passwordChangeBuyerString.changePassword}
        </button>
      </Form>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            onClick={() =>
              functions.closeModalMaxAttempts(navigate, handleClose)
            }
            className="Modal-greenBtn"
          >
            {passwordRecoveryBuyerStrings.ok}
          </button>
        </Modal.Buttons>
      </Modal>
    </div>
  )
}
