import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../assets/icons/logo.svg'
import arrowIcon from '../../assets/icons/icon_flecha_blanco.svg'
import { firstSignInBuyerStrings } from '../../utils/strings'
import { closeModalMaxAttempts, handleSubmit } from './VerificationCodeFunctions'
import { Form } from 'react-bootstrap'
import './VerificationCode.css'
import { DataContext } from '../../context/DataContext'
import { AuthContext } from '../../context/AuthContext'
import { Modal } from '../../components/Modal/Modal'
import { useModal } from '../../hooks/useModal'
import { handleCode, handlePaste } from '../../utils/functions'

export const VerificationCode = () => {
  const { contextInfo, setContextInfo } = useContext(DataContext)
  const { sessionData, setSessionData, handleShowInactiveSessionModal } = useContext(AuthContext)

  const [code, setCode] = useState('')
  const [verificationCodeClass, setVerificationCodeClass] = useState('')
  const navigate = useNavigate()

  const [, setFirstInput] = useState(null)
  const [isAValidCode, setIsAValidCode] = useState(true)
  const [isRequesting, setIsRequesting] = useState(false)

  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState(null)

  useEffect(() => {
    if (!isAValidCode) {
      setCode('')
    }
  }, [isAValidCode])
  return (
    <section className="firstSignInContainer">
      <div className="firstSignInLSideContainer">
        <img src={logo} className="firstSignInTudatalogo" alt='App logo' />
        <div className="firstSignInLSideText">
          {firstSignInBuyerStrings.enterCode}
        </div>
        <div className="firstSignInLSideBackContainer">
          <Link to="/" className="firstSignInLSideBackLink">
            <img
              className="firstSignInReturnArrow"
              src={arrowIcon}
              alt='arrow icon'
            />
            {firstSignInBuyerStrings.back}
          </Link>
        </div>
      </div>
      <div className="firstSignInRSideContainer">
        <p className='firstSignInRSideTopText'>{firstSignInBuyerStrings.header}</p>
        <span className={`firstSignInRSideCodeMessage ${verificationCodeClass}`}>
          {firstSignInBuyerStrings.wrongCode}
        </span>
        <div className='firstSignInRSideCodeFormContainer'>
          <Form className='firstSignInRSideCodeForm'>
            <div className='firstSignInRSideInputsGroup'>
              {Array.from(new Array(6), (_, i) => i).map((index) => (
                <Form.Control
                  key={`input_${index}`}
                  className='firstSignInRSideInput'
                  type='text'
                  onChange={(e) => handleCode(e, index, code, setCode)}
                  onPaste={(e) => handlePaste(e, index, code, setCode)}
                  value={code[index] || ''}
                  required
                  maxLength="1"
                  ref={index === 0 ? (input) => setFirstInput(input) : null}
                />
              ))}
            </div>
            <span className='firstSignInRsideCodeAdvice'>
              {firstSignInBuyerStrings.putYourCodeHere}
            </span>
            <button
              className="firstSignInRSideSendBtn"
              onClick={(e) =>
                handleSubmit(
                  e,
                  setVerificationCodeClass,
                  code,
                  setCode,
                  navigate,
                  contextInfo,
                  setContextInfo,
                  setIsAValidCode,
                  sessionData,
                  setSessionData,
                  isRequesting,
                  setIsRequesting,
                  handleShow,
                  setModalMessage,
                  handleShowInactiveSessionModal
                )}
              disabled={code.length < 6 || isRequesting}>
              {firstSignInBuyerStrings.send}
            </button>
          </Form>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button onClick={() => closeModalMaxAttempts(navigate, handleClose)} className="Modal-greenBtn">
            {firstSignInBuyerStrings.ok}
          </button>
        </Modal.Buttons>
      </Modal>
    </section >
  )
}
