import React, { useEffect, useState } from 'react'
import './SubCategoriesItemsCard.css'

export const SubCategoriesItemsCard = (props) => {
  const [textLength, setTextLength] = useState('longText')

  const { elementsData, elementSelected, setElementSelected, isAlreadySelected } = props

  useEffect(() => {
    if (props.name.length > 30) setTextLength('longText')
    else if (props.name.length > 12) setTextLength('mediumText')
    else setTextLength('shortText')
  }, [props.name.length])

  const handleElementsSelected = () => {
    if (isAlreadySelected) {
      const elementDeleted = elementSelected.filter((element) => element.elementName !== props.name)
      setElementSelected(elementDeleted)
    } else {
      const newCatSelected = elementsData.find((data) => data.elementName === props.name)
      setElementSelected([...elementSelected, newCatSelected])
    }
  }
  return (
    <button className={`SubCategoriesItemsListCategoryItem ${isAlreadySelected ? 'isCheckedBackground' : ''} ${textLength}`} onClick={handleElementsSelected}>
        <p>{props.name}</p>
    </button>
  )
}
