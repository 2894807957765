import React, { useState, useContext, useEffect, createRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Anchor, Form } from 'react-bootstrap'
import logo from '../../assets/icons/logo.svg'
import openEyeIcon from '../../assets/icons/icon_pass2.svg'
import closeEyeIcon from '../../assets/icons/icon_pass1.svg'
import arrowIcon from '../../assets/icons/icon_flecha_blanco.svg'
import {
  companyRegistration2BuyerString,
  companyRegistrationBuyerString,
  loginBuyerString,
  passwordChangeBuyerString
} from '../../utils/strings'
import {
  eyeShowPassword,
  hadleCompanyFormChange,
  handleSubmit
} from './CompanyRegistration3Functions'
import { maxSize, validatePassFormat } from '../../utils/validateFunctions'
import { DataContext } from '../../context/DataContext'
import { AuthContext } from '../../context/AuthContext'
import './CompanyRegistration3.css'
import ReCAPTCHA from 'react-google-recaptcha'

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY

export const CompanyRegistration3 = () => {
  const captcha = createRef()
  const { contextInfo, setContextInfo } = useContext(DataContext)
  const { companyEmail, buyerCredentialPass } = contextInfo
  const { sessionData } = useContext(AuthContext)
  const {
    companyComertialName,
    buyerAddress,
    buyerLegalRepresentative,
    buyerContactName,
    buyerRepresentativeIdNum
  } = sessionData
  const [eyeIcon1, setEyeIcon1] = useState(openEyeIcon)
  const [eyeIcon2, setEyeIcon2] = useState(openEyeIcon)
  const [showRePasswordIncorrectFormat, setShowRePasswordIncorrectFormat] =
    useState('showSpanPass')
  const [showIncorrectFormatPass, setshowIncorrectFormatPass] =
    useState('showSpanPass')
  const [showPasswordIncorrectFormat, setShowPasswordIncorrectFormat] =
    useState('showSpanPass')
  const [isRequesting, setIsRequesting] = useState(false)
  const navigate = useNavigate()
  const [areValidCredentials, setAreValidCredentials] = useState({
    credentialPass: false,
    credentialRePass: false
  })

  const [reCaptcha, setReCaptcha] = useState()

  const captchaChange = () => {
    setReCaptcha(captcha.current.getValue())
  }

  useEffect(() => {
    setContextInfo({
      ...contextInfo,
      buyerCredentialPass: '',
      buyerCredentialRePass: ''
    })
  }, [])

  return (
    <div className="companyRegistration2MainContainer">
      <section className="companyRegistration2SubContainer1">
        <div className="companyRegistration2ImgPragraphContainer">
          <img
            src={logo}
            className="companyRegistration2SubContainer1Logo"
            alt={companyRegistrationBuyerString.tuDataLogo}
          ></img>
          <p className="companyRegistration2Container1Paragraph">
            {companyRegistrationBuyerString.completeYourProfile}
          </p>
        </div>
        <h2 className="companyRegistration2Container1H2">
          {companyRegistrationBuyerString.welcome}
          <br />
          {companyRegistrationBuyerString.portal}
        </h2>
        <Link to={'../RegistroEmpresa2'} className="compayRegistrationReturnParagraph">
          <img
            className="returnArrow"
            src={arrowIcon}
            alt={companyRegistrationBuyerString.backArrow}
          />
          {companyRegistrationBuyerString.back}
        </Link>
      </section>
      <section className="companyRegistration2SubContainer2">
        <Link to="/login" className="companyRegistration2LogInLink1">
          {companyRegistrationBuyerString.areYouRegistered}{' '}
          <span className="companyRegistration2LogInLink2">
            {companyRegistrationBuyerString.logIn}
          </span>
        </Link>
        <div className="companyRegistration2Inputs">
          <Form
            className="companyRegistrationForm"
            onSubmit={(event) =>
              handleSubmit(
                event,
                contextInfo,
                companyComertialName,
                buyerAddress,
                buyerLegalRepresentative,
                buyerContactName,
                companyEmail,
                buyerRepresentativeIdNum,
                buyerCredentialPass,
                setShowPasswordIncorrectFormat,
                setShowRePasswordIncorrectFormat,
                navigate,
                isRequesting,
                setIsRequesting,
                areValidCredentials,
                reCaptcha
              )
            }
          >
            <div className="companyRegistration3Inputs">
              <div>
                <Form.Label className="companyRegistration2Container2FormPassword">
                  <Form.Control
                    className="companyRegistration2Container2Form"
                    autoComplete="off"
                    type="password"
                    onInput={(e) => maxSize(e, 'password')}
                    placeholder={companyRegistration2BuyerString.password}
                    name="buyerCredentialPass"
                    onChange={(e) =>
                      hadleCompanyFormChange(
                        e,
                        contextInfo,
                        setContextInfo,
                        setShowRePasswordIncorrectFormat,
                        setAreValidCredentials,
                        areValidCredentials,
                        buyerCredentialPass,
                        setShowPasswordIncorrectFormat,
                        setshowIncorrectFormatPass
                      )
                    }
                    onBlur={() => {
                      if (!buyerCredentialPass) {
                        setShowPasswordIncorrectFormat('incorrectFormat')
                        setAreValidCredentials({
                          ...areValidCredentials,
                          credentialPass: false
                        })
                      } else {
                        setShowPasswordIncorrectFormat('showSpanPass')
                        setAreValidCredentials({
                          ...areValidCredentials,
                          credentialPass: true
                        })
                      }
                      if (validatePassFormat(buyerCredentialPass)) {
                        setshowIncorrectFormatPass('showSpanPass')
                        setAreValidCredentials({
                          ...areValidCredentials,
                          credentialPass: true
                        })
                      } else {
                        setshowIncorrectFormatPass('incorrectFormat')
                        setAreValidCredentials({
                          ...areValidCredentials,
                          credentialPass: false
                        })
                      }
                    }}
                  />
                  <Anchor
                    className="showPassButtonInicioSesion"
                    onClick={(e) => {
                      eyeShowPassword(
                        'buyerCredentialPass',
                        setEyeIcon1,
                        openEyeIcon,
                        closeEyeIcon
                      )
                    }}
                  >
                    <img
                      className="companyRegistration2EyeIcon"
                      src={eyeIcon1}
                      alt="showPass"
                    />
                  </Anchor>
                </Form.Label>
                <span className={showPasswordIncorrectFormat}>
                  {passwordChangeBuyerString.requiredField}
                </span>
                &nbsp;
                <span className={showIncorrectFormatPass}>
                  {loginBuyerString.spanFormatPass}
                </span>
              </div>
              <div>
                <Form.Label className="companyRegistration2Container2FormPassword">
                  <Form.Control
                    className="companyRegistration2Container2Form"
                    autoComplete="off"
                    type="password"
                    onInput={(e) => maxSize(e, 'password')}
                    placeholder={companyRegistration2BuyerString.repeatPassword}
                    name="buyerCredentialRePass"
                    onChange={(e) =>
                      hadleCompanyFormChange(
                        e,
                        contextInfo,
                        setContextInfo,
                        setShowRePasswordIncorrectFormat,
                        setAreValidCredentials,
                        areValidCredentials,
                        buyerCredentialPass,
                        setShowPasswordIncorrectFormat,
                        setshowIncorrectFormatPass
                      )
                    }
                    onBlur={() => {
                      if (!contextInfo.buyerCredentialRePass) {
                        setShowRePasswordIncorrectFormat('incorrectFormat')
                        setAreValidCredentials({
                          ...areValidCredentials,
                          credentialRePass: false
                        })
                      } else {
                        setShowRePasswordIncorrectFormat('showSpanPass')
                        setAreValidCredentials({
                          ...areValidCredentials,
                          credentialRePass: true
                        })
                      }
                    }}
                  />
                  <Anchor
                    className="showPassButtonInicioSesion"
                    onClick={(e) => {
                      eyeShowPassword(
                        'buyerCredentialRePass',
                        setEyeIcon2,
                        openEyeIcon,
                        closeEyeIcon
                      )
                    }}
                  >
                    <img
                      className="companyRegistration2EyeIcon"
                      src={eyeIcon2}
                      alt="showPass"
                    />
                  </Anchor>
                </Form.Label>
                <span className={showRePasswordIncorrectFormat}>
                  {buyerCredentialPass !== contextInfo.buyerCredentialRePass
                    ? passwordChangeBuyerString.notSame
                    : passwordChangeBuyerString.requiredField}
                </span>
              </div>
            </div>
            <ReCAPTCHA
              ref={captcha}
              sitekey={RECAPTCHA_KEY}
              onChange={captchaChange}
              size="normal"
              badge="inline"
            />
            <button
              type="submit"
              className="companyRegistration2ContinueButton"
              disabled={
                !areValidCredentials.credentialPass ||
                !areValidCredentials.credentialRePass ||
                isRequesting ||
                !reCaptcha
              }
            >
              {companyRegistrationBuyerString.sendRegister}
            </button>
          </Form>
        </div>
      </section>
    </div>
  )
}
