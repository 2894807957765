import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './NavFooter.css'

import backIcon from '../../assets/icons/icon_volver.svg'
import { navFooterBuyerString } from '../../utils/strings'
import { AuthContext } from '../../context/AuthContext'

export const NavFooter = ({ children, noBackLinkClass }) => {
  const { pathname, state } = useLocation()
  const [routingPath, setRoutingPath] = useState('')
  const { sessionData } = useContext(AuthContext)
  const { buyerId } = sessionData

  useEffect(() => {
    if (pathname.endsWith('Eliminarcuenta')) {
      setRoutingPath(`/buyer/${buyerId}/Ajustes/CondicionesYPoliticas`)
    } else if (pathname.endsWith('DetalleDB')) {
      setRoutingPath(`/buyer/${buyerId}/BaseDeDatos`)
    } else if (pathname.endsWith('CompraBaseDatosPaso3')) {
      setRoutingPath(`/buyer/${buyerId}/BaseDeDatos`)
    } else if (pathname.endsWith('CompraBaseDatosPaso1') && !state) {
      setRoutingPath(`/buyer/${buyerId}/BaseDeDatos/SeleccionPlan/planBDSimple`)
    } else if (pathname.endsWith('CompraBaseDatosPaso1') && state) {
      setRoutingPath('/TusEleccionesBD')
    } else if (pathname.endsWith('CompraBaseDatosPaso2')) {
      setRoutingPath(`/buyer/${buyerId}/BaseDeDatos/CompraBaseDatosPaso1`)
    } else if (pathname.endsWith('Nuevapqrs')) {
      setRoutingPath(`/buyer/${buyerId}/Ajustes/AtencionAlCliente`)
    } else {
      setRoutingPath(-1)
    }
  }, [pathname])

  return (
    <div className='navFooter'>
      <Link to={routingPath} className={`navFooterBackLink ${noBackLinkClass}`}>
        <img className='navFooterBackLinkImage' src={backIcon} alt='back-icon' />
        <span className='navFooterBackLinkText'>{`${navFooterBuyerString.return}`}</span>
      </Link>
      <div className='navFooterButtonsContainer'>
        {children}
      </div>
    </div>
  )
}
