import { activateRequestButton, handleRequestButton } from '../../utils/functions'
import { identDocuments, validateBuyerRepresentativeIdNumQuery, validateEmailInRegistrationForm, validatePhoneInRegistrationForm } from '../../utils/queries'
import { companyRegistration2BuyerString } from '../../utils/strings'
import { getCities } from '../../utils/userqueries'
import { onlySpanishLetters, validateCellphone, validateEmailFormat, hasRepeatedCharactersAtMiddle, validateNames, validateNoWhiteSpacesAtBeginning } from '../../utils/validateFunctions'

export const hadleCompanyFormChange = (e, contextInfo, setContextInfo, setEmailValue, sessionData, setSessionData) => {
  const value = e.target.value
  let formattedValue = ''
  if (value === ' ' || value === '  ') {
    return ''
  }
  if (hasRepeatedCharactersAtMiddle(value)) {
    return
  }

  if (validateNames(value) && validateNoWhiteSpacesAtBeginning(value)) {
    setContextInfo({ ...contextInfo, [e.target.name]: value })
  }
  if (e.target.name === 'companyEmail') {
    setEmailValue(e.target.value)
    setContextInfo({ ...contextInfo, [e.target.name]: e.target.value })
  } else if (e.target.name === 'buyerLegalRepresentative' || e.target.name === 'buyerContactName') {
    if (onlySpanishLetters(e.target.value) || e.target.value === '' || e.target.value === ' ') {
      formattedValue = formattedValue + e.target.value
      setContextInfo({ ...contextInfo, [e.target.name]: formattedValue })
      setSessionData({ ...sessionData, [e.target.name]: formattedValue })
    }
  } else {
    setContextInfo({ ...contextInfo, [e.target.name]: e.target.value })
    setSessionData({ ...sessionData, [e.target.name]: e.target.value })
  }
}

export const handleEmailOnBlur = async (
  contextInfo,
  setContextInfo,
  setShowEmailIncorrectFormat,
  setModalMessage,
  handleShow,
  setEmailValue,
  setEmailValidatedByQuery
) => {
  if (!validateEmailFormat(contextInfo.companyEmail)) {
    setShowEmailIncorrectFormat('incorrectFormat')
  } else {
    setShowEmailIncorrectFormat('showSpanPass')

    const response = await validateEmailInRegistrationForm(contextInfo.companyNit, contextInfo.companyEmail)

    if (response.data?.code) {
      const errorCode = response.data.code

      if (errorCode.includes('BUYER_ALREADY_EXIST')) {
        setModalMessage(companyRegistration2BuyerString.companyWithBuyer)
        handleShow()
        setEmailValue('')
        setContextInfo({ ...contextInfo, companyEmail: '' })
        setShowEmailIncorrectFormat('incorrectFormat')
        setEmailValidatedByQuery(false)
      } else if (errorCode.includes('EMAIL_NOT_ALLOW')) {
        setModalMessage(companyRegistration2BuyerString.emailNotMatch)
        handleShow()
        setEmailValue('')
        setContextInfo({ ...contextInfo, companyEmail: '' })
        setShowEmailIncorrectFormat('incorrectFormat')
        setEmailValidatedByQuery(false)
      } else if (errorCode.includes('EMAIL_NOT_FOUND')) {
        setModalMessage(companyRegistration2BuyerString.emailNotFound)
        handleShow()
        setEmailValue('')
        setContextInfo({ ...contextInfo, companyEmail: '' })
        setShowEmailIncorrectFormat('incorrectFormat')
        setEmailValidatedByQuery(false)
      }
    } else if (response.response) {
      setEmailValidatedByQuery(true)
      return response.response
    }
  }
}

export const hadleCompanyFormChange2 = (e, contextInfo, setContextInfo, setIdInputValue, typeID, sessionData, setSessionData) => {
  const val = e.target.value
  const formattedValue = val.replace(/[^0-9]/g, '')
  if (typeID === 'number') {
    if (formattedValue.length < 10) {
      setIdInputValue(formattedValue)
      // setContextInfo({ ...contextInfo, [e.target.name]: formattedValue })
      setSessionData({ ...sessionData, [e.target.name]: formattedValue })
    } else {
      setIdInputValue(formattedValue.slice(0, 10))
      // setContextInfo({ ...contextInfo, [e.target.name]: formattedValue.slice(0, 10) })
      setSessionData({ ...sessionData, [e.target.name]: formattedValue.slice(0, 10) })
    }
  } else if (typeID === 'string') {
    setIdInputValue(val)
    // setContextInfo({ ...contextInfo, [e.target.name]: val })
    setSessionData({ ...sessionData, [e.target.name]: val })
  }
}

export const handleExpeditionCityChange = (e, contextInfo, setContextInfo, setExpeditionCity) => {
  const value = e.target.value
  setExpeditionCity(value)
  setContextInfo({ ...contextInfo, [e.target.name]: value })
}

export const handlePhoneInputChange = (e, contextInfo, setContextInfo, setPhoneValue) => {
  const phoneValue = validateCellphone(e)
  if (phoneValue) {
    setPhoneValue(phoneValue)
    setContextInfo({ ...contextInfo, [e.target.name]: phoneValue })
  }
}

export const handleOnBlurExpeditionCity = (
  e,
  expeditionCity,
  setExpeditionCity,
  citiesList,
  setModalMessage,
  handleShow,
  contextInfo,
  setContextInfo
) => {
  // Documentación: Los métodos .normalize('NFD').replace(/[\u0300-\u036f]/g, '') se utilizan para quitar tildes de una cadena de caracteres

  const city = citiesList.find(
    (e) =>
      e.cityName
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim() ===
      expeditionCity
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
  )

  if (!city) {
    if (
      expeditionCity
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .includes(
          'Bogotá'
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
        )
    ) {
      setExpeditionCity('Bogotá')
      setContextInfo({ ...contextInfo, [e.target.name]: 'Bogotá' })
      return
    }
  }

  if (city) {
    setExpeditionCity(city.cityName)
    setContextInfo({ ...contextInfo, [e.target.name]: city.cityName })
  } else if (!city && expeditionCity !== '') {
    setModalMessage(`${expeditionCity}${companyRegistration2BuyerString.notValidCity}`)
    handleShow()
    setExpeditionCity('')
  }
}

export const handlePhoneOnBlur = async (
  contextInfo,
  setContextInfo,
  setShowNotificationPhoneIncorrectFormat,
  setModalMessage,
  handleShow,
  setPhoneValue,
  setPhoneValidatedByQuery
) => {
  if (!contextInfo.companyPhone || contextInfo.companyPhone.length !== 10) {
    setShowNotificationPhoneIncorrectFormat('incorrectFormat')
  } else {
    setShowNotificationPhoneIncorrectFormat('showSpanPass')

    const response = await validatePhoneInRegistrationForm(contextInfo.companyNit, contextInfo.companyPhone)

    if (response.data?.code) {
      const errorCode = response.data.code

      if (errorCode.includes('BUYER_ALREADY_EXIST')) {
        setModalMessage(companyRegistration2BuyerString.companyWithBuyer)
        handleShow()
        setPhoneValue('')
        setContextInfo({ ...contextInfo, companyPhone: '' })
        setShowNotificationPhoneIncorrectFormat('incorrectFormat')
        setPhoneValidatedByQuery(false)
      } else if (errorCode.includes('PHONE_NOT_ALLOW')) {
        setModalMessage(companyRegistration2BuyerString.phoneNotMatch)
        handleShow()
        setPhoneValue('')
        setContextInfo({ ...contextInfo, companyPhone: '' })
        setShowNotificationPhoneIncorrectFormat('incorrectFormat')
        setPhoneValidatedByQuery(false)
      } else if (errorCode.includes('PHONE_NOT_FOUND')) {
        setModalMessage(companyRegistration2BuyerString.phoneNotFound)
        handleShow()
        setPhoneValue('')
        setContextInfo({ ...contextInfo, companyPhone: '' })
        setShowNotificationPhoneIncorrectFormat('incorrectFormat')
        setPhoneValidatedByQuery(false)
      }
    } else if (response.response) {
      setPhoneValidatedByQuery(true)
      return response.response
    }
  }
}

export const hadleSelectCompanyFormChange = (e, contextInfo, setContextInfo, setTypeId) => {
  const idType = e.target.value
  idType === '1' || idType === '2' ? setTypeId('number') : setTypeId('string')
  setContextInfo({ ...contextInfo, [e.target.name]: e.target.value })
}

export const createRegistrationDataList = async ({ setIdentList }) => {
  try {
    const dataList = await identDocuments()
    setIdentList(dataList.documents)
  } catch (error) {
    console.log(`${companyRegistration2BuyerString.error} ${error}`)
  }
}

export const formVerification = async (
  contextInfo,
  buyerAddress,
  buyerLegalRepresentative,
  buyerContactName,
  buyerRepresentativeIdNum,
  setShowLegalRepresentativeIncorrectFormat,
  setShowTypeIdentIncorrectFormat,
  setShowIdNumberIncorrectFormat,
  setShowExpeditionCityIncorrectFormat,
  setShowContactPersonIncorrectFormat,
  setShowAdressIncorrectFormat,
  setShowNotificationPhoneIncorrectFormat,
  setShowEmailIncorrectFormat,
  navigate,
  lastTimeOutId,
  setIsRequesting
) => {
  if (!buyerLegalRepresentative) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowLegalRepresentativeIncorrectFormat('incorrectFormat')
  } else {
    setShowLegalRepresentativeIncorrectFormat('showSpanPass')
  }
  if (!contextInfo.buyerRepresentativeIdType) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowTypeIdentIncorrectFormat('incorrectFormat')
  } else {
    setShowTypeIdentIncorrectFormat('showSpanPass')
  }
  if (!buyerRepresentativeIdNum) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowIdNumberIncorrectFormat('incorrectFormat')
  } else {
    setShowIdNumberIncorrectFormat('showSpanPass')
  }
  if (!contextInfo.buyerExpeditionCity) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowExpeditionCityIncorrectFormat('incorrectFormat')
  } else {
    setShowExpeditionCityIncorrectFormat('showSpanPass')
  }
  if (!buyerContactName) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowContactPersonIncorrectFormat('incorrectFormat')
  } else {
    setShowContactPersonIncorrectFormat('showSpanPass')
  }
  if (!buyerAddress) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowAdressIncorrectFormat('incorrectFormat')
  } else {
    setShowAdressIncorrectFormat('showSpanPass')
  }
  if (!contextInfo.companyPhone || contextInfo.companyPhone.toString().length !== 10) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowNotificationPhoneIncorrectFormat('incorrectFormat')
  } else {
    setShowNotificationPhoneIncorrectFormat('showSpanPass')
  }
  if (!contextInfo.companyEmail) {
    activateRequestButton(setIsRequesting, lastTimeOutId)
    setShowEmailIncorrectFormat('incorrectFormat')
  } else {
    setShowEmailIncorrectFormat('showSpanPass')
  }

  if (
    buyerLegalRepresentative &&
    contextInfo.buyerRepresentativeIdType &&
    buyerRepresentativeIdNum &&
    contextInfo.buyerExpeditionCity &&
    buyerContactName &&
    buyerAddress &&
    contextInfo.companyPhone &&
    contextInfo.companyPhone.toString().length === 10 &&
    contextInfo.companyEmail
  ) {
    navigate('../RegistroEmpresa3')
  }
}

export const eyeShowPassword = (inputName, setEyeIcon, openEyeIcon, closeEyeIcon) => {
  const element = document.getElementsByName(inputName)[0]
  if (element.type === 'password') {
    element.type = 'text'
    setEyeIcon(closeEyeIcon)
  } else {
    element.type = 'password'
    setEyeIcon(openEyeIcon)
  }
  element.focus()
}

export const validateBuyerRepresentativeIdNum = async (
  setShowIdNumberIncorrectFormat,
  handleShow,
  setModalMessage,
  setIdInputValue,
  buyerRepresentativeIdNum,
  sessionData,
  setSessionData,
  setIdValidatedByQuery,
  buyerRepresentativeIdType
) => {
  const validFormat = validateFormatBuyerRepresentativeIdNum(
    buyerRepresentativeIdNum,
    setShowIdNumberIncorrectFormat,
    buyerRepresentativeIdType,
    setIdValidatedByQuery,
    setModalMessage,
    handleShow,
    setIdInputValue,
    setSessionData,
    sessionData
  )

  if (validFormat) {
    setShowIdNumberIncorrectFormat('showSpanPass')
    const validateBuyerRepresentativeIdNumResponse = await validateBuyerRepresentativeIdNumQuery(buyerRepresentativeIdNum)

    if (validateBuyerRepresentativeIdNumResponse.response) {
      setIdValidatedByQuery(false)
      setModalMessage(companyRegistration2BuyerString.buyerIdExists)
      handleShow()
      setShowIdNumberIncorrectFormat('incorrectFormat')
      setIdInputValue('')
      setSessionData({ ...sessionData, buyerRepresentativeIdNum: '' })
    } else {
      setIdValidatedByQuery(true)
    }
  }
}

export const validateFormatBuyerRepresentativeIdNum = (
  buyerRepresentativeIdNum,
  setShowIdNumberIncorrectFormat,
  buyerRepresentativeIdType,
  setIdValidatedByQuery,
  setModalMessage,
  handleShow,
  setIdInputValue,
  setSessionData,
  sessionData
) => {
  if (!buyerRepresentativeIdNum) {
    setShowIdNumberIncorrectFormat('incorrectFormat')
    return false
  } else {
    if (buyerRepresentativeIdType === '1' || buyerRepresentativeIdType === '2') {
      if ((buyerRepresentativeIdNum >= 10000 && buyerRepresentativeIdNum <= 2000000000) === false) {
        if (buyerRepresentativeIdNum < 10000) {
          setIdValidatedByQuery(false)
          setModalMessage(companyRegistration2BuyerString.minIdNumFormat)
          handleShow()
          setShowIdNumberIncorrectFormat('incorrectFormat')
          setIdInputValue('')
          setSessionData({ ...sessionData, buyerRepresentativeIdNum: '' })
          return false
        } else if (buyerRepresentativeIdNum > 2000000000) {
          setIdValidatedByQuery(false)
          setModalMessage(companyRegistration2BuyerString.maxIdNumFormat)
          handleShow()
          setShowIdNumberIncorrectFormat('incorrectFormat')
          setIdInputValue('')
          setSessionData({ ...sessionData, buyerRepresentativeIdNum: '' })
          return false
        }
      }
    }

    if (buyerRepresentativeIdType === '3') {
      const hasNumbersAndLetters = /^(?=.*[a-zA-Z])(?=.*\d).+/
      if (!(buyerRepresentativeIdNum.length === 10 && hasNumbersAndLetters.test(buyerRepresentativeIdNum))) {
        setIdValidatedByQuery(false)
        setModalMessage(companyRegistration2BuyerString.passportIdFormat)
        handleShow()
        setShowIdNumberIncorrectFormat('incorrectFormat')
        setIdInputValue('')
        setSessionData({ ...sessionData, buyerRepresentativeIdNum: '' })
        return false
      }
    }
    return true
  }
}

export const createDataList = async (setCitiesList) => {
  try {
    const citiesList = await getCities()
    setCitiesList(citiesList.cities)
  } catch (error) {
    console.error(error)
  }
}

export const areAllInputsValidatedByQueriesInTrue = (idValidatedByQuery, phoneValidatedByQuery, emailValidatedByQuery) => {
  if (!idValidatedByQuery) {
    return 'id'
  } else if (!phoneValidatedByQuery) {
    return 'phone'
  } else if (!emailValidatedByQuery) {
    return 'email'
  } else {
    return true
  }
}

export const validateBuyerAddress = (buyerAddress, setShowAdressIncorrectFormat) => {
  const regex = /^[a-zA-Z0-9\sáéíóúüñÁÉÍÓÚÜÑ#]*$/
  if (!regex.test(buyerAddress)) {
    setShowAdressIncorrectFormat('incorrectFormat')
  } else {
    setShowAdressIncorrectFormat('showSpanPass')
  }
}

export const showModalOnNotInputsValidated = async (
  areAllInputsValid,
  setShowIdNumberIncorrectFormat,
  handleShow,
  setModalMessage,
  setIdInputValue,
  buyerRepresentativeIdNum,
  sessionData,
  setSessionData,
  setIdValidatedByQuery,
  setPhoneValidatedByQuery,
  setEmailValidatedByQuery,
  contextInfo,
  setContextInfo,
  setShowNotificationPhoneIncorrectFormat,
  setPhoneValue,
  setShowEmailIncorrectFormat,
  setEmailValue,
  idValidatedByQuery,
  phoneValidatedByQuery,
  emailValidatedByQuery,
  lastTimeOutId,
  setIsRequesting,
  buyerRepresentativeIdType
) => {
  let timeOutId = handleRequestButton(setIsRequesting, lastTimeOutId)
  if (areAllInputsValid === 'id' || areAllInputsValid === 'phone' || areAllInputsValid === 'email') {
    await validateBuyerRepresentativeIdNum(
      setShowIdNumberIncorrectFormat,
      handleShow,
      setModalMessage,
      setIdInputValue,
      buyerRepresentativeIdNum,
      sessionData,
      setSessionData,
      setIdValidatedByQuery,
      buyerRepresentativeIdType
    )
    timeOutId = handleRequestButton(setIsRequesting, timeOutId)
    await handlePhoneOnBlur(
      contextInfo,
      setContextInfo,
      setShowNotificationPhoneIncorrectFormat,
      setModalMessage,
      handleShow,
      setPhoneValue,
      setPhoneValidatedByQuery
    )

    timeOutId = handleRequestButton(setIsRequesting, timeOutId)
    await handleEmailOnBlur(
      contextInfo,
      setContextInfo,
      setShowEmailIncorrectFormat,
      setModalMessage,
      handleShow,
      setEmailValue,
      setEmailValidatedByQuery
    )

    activateRequestButton(setIsRequesting, timeOutId)
    return idValidatedByQuery && phoneValidatedByQuery && emailValidatedByQuery
  } else {
    activateRequestButton(setIsRequesting, timeOutId)
    return true
  }
}

export const validateInputs = async (
  setShowIdNumberIncorrectFormat,
  handleShow,
  setModalMessage,
  setIdInputValue,
  buyerRepresentativeIdNum,
  sessionData,
  setSessionData,
  setIdValidatedByQuery,
  setPhoneValidatedByQuery,
  setEmailValidatedByQuery,
  contextInfo,
  setContextInfo,
  setShowNotificationPhoneIncorrectFormat,
  setPhoneValue,
  setShowEmailIncorrectFormat,
  setEmailValue,
  idValidatedByQuery,
  phoneValidatedByQuery,
  emailValidatedByQuery,
  lastTimeOutId,
  setIsRequesting,
  buyerRepresentativeIdType
) => {
  const areAllInputsValid = areAllInputsValidatedByQueriesInTrue(idValidatedByQuery, phoneValidatedByQuery, emailValidatedByQuery)

  const timeOutId = handleRequestButton(setIsRequesting, lastTimeOutId)
  await showModalOnNotInputsValidated(
    areAllInputsValid,
    setShowIdNumberIncorrectFormat,
    handleShow,
    setModalMessage,
    setIdInputValue,
    buyerRepresentativeIdNum,
    sessionData,
    setSessionData,
    setIdValidatedByQuery,
    setPhoneValidatedByQuery,
    setEmailValidatedByQuery,
    contextInfo,
    setContextInfo,
    setShowNotificationPhoneIncorrectFormat,
    setPhoneValue,
    setShowEmailIncorrectFormat,
    setEmailValue,
    idValidatedByQuery,
    phoneValidatedByQuery,
    emailValidatedByQuery,
    timeOutId,
    setIsRequesting,
    buyerRepresentativeIdType
  )
}

const CompanyRegistration2Functions = {
  hadleCompanyFormChange,
  handleEmailOnBlur,
  hadleCompanyFormChange2,
  hadleSelectCompanyFormChange,
  createRegistrationDataList,
  formVerification,
  eyeShowPassword,
  handlePhoneInputChange,
  handlePhoneOnBlur,
  validateBuyerRepresentativeIdNum,
  handleExpeditionCityChange,
  handleOnBlurExpeditionCity,
  createDataList
}

export default CompanyRegistration2Functions
