import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// Assets
import whiteBackIcon from '../../../../assets/icons/icon_flecha_blanco.svg'
import appLogo from '../../../../assets/icons/logo.svg'
import simpleDBIcon from '../../../../assets/icons/icon_bd.svg'
import specializedDBIcon from '../../../../assets/icons/icon_bd2.svg'

// Context
import { AuthContext } from '../../../../context/AuthContext'

// Utils
import { DataBaseBuyerStrings } from '../../../../utils/strings'

import './DataBaseChoose.css'
import { handleOnClickSelectBtn } from './DataBaseChooseFunctions'

export const DataBaseChoose = () => {
  const navigate = useNavigate()
  const [planChoosed, setPlanChoosed] = useState(null)
  const { sessionData } = useContext(AuthContext)
  const { buyerId } = sessionData
  const { dataBaseChooseStrings } = DataBaseBuyerStrings

  return (
    <section id='DataBaseChoose'>
      <div className='DataBaseChooseContent'>
        <div className='DataBaseChooseLogoBox'>
          <img src={appLogo} alt="app-logo" />
        </div>
        <div className='DataBaseChooseTitle'>
          <h1>{dataBaseChooseStrings.selectDB}</h1><br />
        </div>
        <div className='DataBaseChooseCardsBox'>
          <input type="radio" id="simplePlanChoose" name="choosePlan" onChange={() => setPlanChoosed('planBDSimple')} />
          <label htmlFor="simplePlanChoose" className={`DataBaseChooseLeftCard ${planChoosed === 'planBDSimple' ? 'PlanSelected' : ''}`}>
            <div className='DataBaseChooseLeftCardImage'>
              <img src={simpleDBIcon} alt="simbledbicon" />
            </div>
            <div className='DataBaseChooseLeftCardText'>
              <div className='DataBaseChooseLeftCardTextTitles'>
                <h2>{dataBaseChooseStrings.dataBase}</h2>
                <h2>{dataBaseChooseStrings.DBSimple}</h2>
              </div>
              <p>{dataBaseChooseStrings.descriptionDBSimple}</p>
            </div>
          </label>
          <input type="radio" id="specializedPlanChoose" name="choosePlan" onChange={() => setPlanChoosed('planBDEspecializada')} />
          <label htmlFor="specializedPlanChoose" className={`DataBaseChooseRightCard ${planChoosed === 'planBDEspecializada' ? 'PlanSelected' : ''}`}>
            <div className='DataBaseChooseRightCardImage'>
              <img src={specializedDBIcon} alt="specializeddbicon" />
            </div>
            <div className='DataBaseChooseRightCardText'>
              <div className='DataBaseChooseRightCardTextTitles'>
                <h2>{dataBaseChooseStrings.dataBase}</h2>
                <h2>{dataBaseChooseStrings.DBSpecialized}</h2>
              </div>
              <p>{dataBaseChooseStrings.descriptionDBSpecialized}</p>
            </div>
          </label>
        </div>
        <div className='DataBaseChooseFooter'>
          <div className='DataBaseChooseFooterBack'>
            <Link to={`/buyer/${buyerId}/BaseDeDatos`}>
              <img src={whiteBackIcon} alt="back-icon" />
              <span>{dataBaseChooseStrings.back}</span>
            </Link>
          </div>
          <div className='DataBaseChooseFooterButton'>
            <button
              disabled={!planChoosed}
              onClick={() => handleOnClickSelectBtn(planChoosed, navigate)}>
              {dataBaseChooseStrings.select}
            </button>
          </div>
          <div className='DataBaseChooseFooterSkip'>
            <Link to={`../buyer/${buyerId}/Cuenta`}>
              <span>{dataBaseChooseStrings.skip}</span>
              <img src={whiteBackIcon} alt="back-icon" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
