import React from 'react'
import { Layout } from '../components/Layout/Layout'
import { NoLayout } from '../components/NoLayout/NoLayout'
import { layoutDataStrings } from '../../../utils/strings'

// -------- Data --------

const settingsLayoutData = [
  { text: layoutDataStrings.politics, id: 'Conditions', to: 'Ajustes/CondicionesYPoliticas', pathToActiveStyle: 'CondicionesYPoliticas' },
  { text: layoutDataStrings.changePassword, id: 'ChangePassword', to: 'Ajustes/CambioDeContrasena', pathToActiveStyle: 'CambioDeContrasena' },
  { text: layoutDataStrings.clientAttend, id: 'FAQ', to: 'Ajustes/AtencionAlCliente', pathToActiveStyle: 'AtencionAlCliente' }
]

const buyDataBaseLayoutData = [
  { text: layoutDataStrings.list, id: 'Listado', to: null, pathToActiveStyle: '/CompraBaseDatos' }
]

const dataBaseLayoutData = [
  { text: layoutDataStrings.list, id: 'Listado', to: null, pathToActiveStyle: '/BaseDeDatos' }
]

// -------- Rendering --------

export const pathAndLayout = [
  { path: '/Ajustes', layout: <Layout data={settingsLayoutData} /> },
  { path: '/BaseDeDatos', layout: <Layout data={dataBaseLayoutData} /> },
  { path: '/Cuenta/CompraBaseDatos', layout: <Layout data={buyDataBaseLayoutData} /> },
  { path: '/Cuenta', layout: <NoLayout /> }
]
