import React from 'react'
import './CategoriesCard.css'
import { useLocation } from 'react-router-dom'

export const CategoriesCard = (props) => {
  const { categorySelected, setCategorySelected, isAlreadySelected } = props
  const { pathname } = useLocation()

  const handleCategorySelected = () => {
    if (isAlreadySelected || (pathname.endsWith('Genero') && categorySelected.length === 3)) {
      if (pathname.endsWith('Genero') && props.id === 4) setCategorySelected([])
      else {
        const categoryDeleted = categorySelected.filter((category) => category.name !== props.name)
        setCategorySelected(categoryDeleted)
      }
    } else {
      if (pathname.endsWith('Genero') && props.id === 4) {
        const allGenders = props.categoryData.filter((gender) => gender.id !== 4)
        setCategorySelected(allGenders)
      } else if (pathname.endsWith('Genero') && props.id !== 4 && categorySelected.length > 2) {
        const catSelected = props.categoryData.find((data) => data.name === props.name)
        setCategorySelected([catSelected])
      } else {
        const catSelected = props.categoryData.find((data) => data.name === props.name)
        setCategorySelected([...categorySelected, catSelected])
      }
    }
  }
  return (
    <button className='CategoriesCard' onClick={handleCategorySelected}>
        <img className={`CategoriesCard${props.name}Img`} src={props.image} alt="KidImg" />
        <span className={`CategoriesCardSpan ${isAlreadySelected || (pathname.endsWith('Genero') && categorySelected.length === 3) ? 'isChecked' : ''}`}>{props.cardName ? props.cardName : props.name}</span>
    </button>
  )
}
