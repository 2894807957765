import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink, Outlet, useNavigate, useLocation } from 'react-router-dom'
import logoApp from '../../assets/icons/logo.svg'
import logoConfiguracion from '../../assets/icons/icon_ajustes.svg'
import iconCerrar from '../../assets/icons/icon_cerrar.svg'
import logoRequests from '../../assets/icons/icon_bd3.svg'
import LogoEmpresas from '../../assets/icons/icon_empresas.svg'
import { Modal } from '../../components/Modal/Modal'
import { useModal } from '../../hooks/useModal'
import { indexBuyerString } from '../../utils/strings'
import { BaseLayout } from '../../components/BaseLayout/BaseLayout'
import './BuyersBase.css'

export const BuyersBase = () => {
  const location = useLocation()
  const [showModal, handleShow, handleClose] = useModal()
  const navigate = useNavigate()

  const removeData = () => {
    navigate('/')
  }

  return (
    <section className="mainContainerBaseCompradores">
      <Container>
        <Row>
          <Col className="colMenuCompradores" md="auto">
            <div className="subContainerMenuCompradores1">
              <div className="mainLogoContainer">
                <img
                  className="logoApp"
                  src={logoApp}
                  alt={indexBuyerString.logoTuData}
                />
                {/* <p className="logoParagraph">{indexString.buyers}</p> */}
              </div>
              <NavLink
                id="Account"
                to={'Cuenta'}
                className={({ isActive }) =>
                  `navbar-item ${isActive ? 'item-selected' : ''}`
                }
              >
                <img
                  className="iconoPersonaBaseC"
                  src={LogoEmpresas}
                  alt="logo_persona"
                />
                <p className="paragraphSolicitudes">{indexBuyerString.account}</p>
              </NavLink>

              <NavLink
                id="DataBases"
                to={'BaseDeDatos'}
                className={({ isActive }) =>
                  `navbar-item ${isActive ? 'item-selected' : ''}`
                }
              >
                <img
                  className="iconoPersonaBaseC"
                  src={logoRequests}
                  alt={indexBuyerString.logoPerson}
                />
                <p className="paragraphSolicitudes">{indexBuyerString.dataBase}</p>
              </NavLink>
            </div>

            <div className="subContainerMenu2">
              <NavLink
                id="Config"
                // onClick={onClickAjustes({ setCuentaSelected, document, indexString })}
                to={'Ajustes/CondicionesYPoliticas'}
                // className={({ isActive }) => `navbar-item ${isActive ? 'item-selected' : ''}`}
                className={`navbar-item ${
                  location.pathname.includes('Ajustes') ? 'item-selected' : ''
                }`}
              >
                <img
                  className="iconoPersonaBaseC"
                  src={logoConfiguracion}
                  alt="Logo-Ajustes"
                />
                <p className="paragraphSolicitudes">{indexBuyerString.settings}</p>
              </NavLink>
              <div className="logoutContainer" onClick={handleShow}>
                <img src={iconCerrar} alt="logout-btn" />
                <p className="logoutText">{indexBuyerString.logOut}</p>
              </div>
            </div>
            <Modal isOpen={showModal}>
              <Modal.Content>
                <p>{indexBuyerString.logOutAsking}</p>
              </Modal.Content>
              <Modal.Buttons>
                <button onClick={handleClose} className="Modal-normalBtn">
                  {indexBuyerString.cancel}
                </button>
                <button onClick={removeData} className="Modal-orangeBtn">
                  {indexBuyerString.accept}
                </button>
              </Modal.Buttons>
            </Modal>
          </Col>

          <Col className="colDatos" md="auto">
            <div className="subContainer">
              <BaseLayout>
                <Outlet />
              </BaseLayout>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
