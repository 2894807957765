import React, { useContext, useEffect, useState } from 'react'
import appLogo from '../../../../assets/icons/logo.svg'
import whiteBackIcon from '../../../../assets/icons/icon_flecha_blanco.svg'
import { categoriesBuyerStrings } from '../../../../utils/strings'
import { CategoriesCard } from '../../components/CategoriesCard/CategoriesCard'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getElements } from '../../../../utils/queries'
import { filterCategories } from '../../../../utils/functions'
import { CategoriesContext } from '../../../../context/CategoriesContext'
import './CategoriesGeneral.css'
import { AuthContext } from '../../../../context/AuthContext'
import { validateNumberOfSelectionsbyCategory } from '../../../../utils/userqueries'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'

export const CategoriesGeneral = () => {
  const authContext = useContext(AuthContext)
  const { sessionData } = authContext
  const { authTk } = sessionData
  const [categories, setCategories] = useState([])
  const [generalCatSelected, setGeneralCatSelected] = useState([])
  const [modalData, setModalData] = useState(0)
  const [showModal, handleShow, handleClose] = useModal()

  const { categoriesSelected, setCategoriesSelected } =
    useContext(CategoriesContext)
  const { state: planType } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const getCategories = async () => {
      const elements = await getElements(authContext)
      if (elements) {
        const categories = await filterCategories(elements)
        setCategories(categories)
      }
    }

    if (authTk) {
      getCategories()
    }
  }, [])

  const handleNextCategory = async (selection) => {
    if (selection.length < 2) return

    setCategoriesSelected({ ...categoriesSelected, generalCatSelected })
    const searchParams = {
      ...categoriesSelected,
      categories: generalCatSelected.map((item) => item.id)
    }
    delete searchParams.generalCatSelected
    const amount = await validateNumberOfSelectionsbyCategory(
      authContext,
      searchParams
    )
    // modal alert continuar / corregir
    setModalData(amount?.count)
    handleShow()
  }

  useEffect(() => {
    if (categoriesSelected.generalCatSelected) {
      const catAlreadySelected = categories.filter((cat) => categoriesSelected.generalCatSelected.some((item) => item.id === cat.id))
      setGeneralCatSelected(catAlreadySelected)
    }
  }, [categories])

  const isAlreadySelected = (id) => {
    const isSelected = generalCatSelected.some((item) => item.id === id)
    if (isSelected) return true
    else return false
  }

  return (
    <section id="CategoriesGeneral">
      <div className="CategoriesGeneralContent">
        <div className="CategoriesGeneralLogoBox">
          <img src={appLogo} alt="appLogo" />
        </div>
        <div className="CategoriesGeneralText">
          <p>{categoriesBuyerStrings.titleGeneralParagraph}</p>
        </div>
        <div className="CategoriesGeneralGrid">
          {categories.map((data) => (
            <CategoriesCard
              key={data.id}
              name={data.name}
              image={data.icon}
              categoryData={categories}
              categorySelected={generalCatSelected}
              setCategorySelected={setGeneralCatSelected}
              isAlreadySelected={isAlreadySelected(data.id)}
            />
          ))}
        </div>
        <div className="CategoriesGeneralFooter">
          <div className="CategoriesGeneralFooterContent">
            <Link to="../CategoriasBDEdad">
              <img
                className="CategoriesGeneralFooterBackBtn"
                src={whiteBackIcon}
                alt="nextIcon"
              />
              <p>{categoriesBuyerStrings.back}</p>
            </Link>
            <button
              onClick={() => handleNextCategory(generalCatSelected)}
              className="CategoriesGeneralFooterNextBtn"
              disabled={generalCatSelected.length < 2}
            >
              <p>{categoriesBuyerStrings.next}</p>
              <img src={whiteBackIcon} alt="nextIcon" />
            </button>
          </div>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>Cantidad de registros por selección actual: {modalData}</p>
        </Modal.Content>
        <Modal.Buttons>
          {modalData === 0
            ? (
            <button className="Modal-greenBtn" onClick={handleClose}>
              OK
            </button>
              )
            : (
            <>
              <button className="Modal-normalBtn" onClick={handleClose}>
                Editar
              </button>
              <button
                className="Modal-orangeBtn"
                onClick={() =>
                  navigate('/SubCategoriasBD', { state: planType })
                }
              >
                Continuar
              </button>
            </>
              )}
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
