import { useLocation } from 'react-router-dom'

export const useLayoutByLocation = (pathAndComponent) => {
  const location = useLocation()

  for (let i = 0; i < pathAndComponent.length; i++) {
    if (location.pathname.includes(pathAndComponent[i].path)) {
      return pathAndComponent[i].layout
    }
  }
}
