import {
  activateRequestButton,
  handleRequestButton
} from '../../utils/functions'
import { getPQRS, postPQRS } from '../../utils/queries'
import { newPqrsBuyerString } from '../../utils/strings'
import {
  deleteSpacing,
  hasRepeatedCharactersAtMiddle,
  validateNoWhiteSpacesAtBeginning
} from '../../utils/validateFunctions'
import { optionsLists } from '../../utils/objects'

export const optionsList = optionsLists

export const getPqrs = async (authContext, setpqrsList) => {
  const pqrsList = await getPQRS(authContext)
  pqrsList && setpqrsList(pqrsList.body)
}

export const toggleSelectedOption = (
  pqrsId,
  optionSelected,
  setOptionSelected,
  setTextDropdownToggle,
  pqrsListOnFront,
  setPqrsId,
  setDeactivateCleanButton
) => {
  setOptionSelected(
    pqrsListOnFront.map((e, i) => {
      i++
      if (i === pqrsId) {
        setTextDropdownToggle(e.pqrsName)
        // setValidDropdown(true)
        setPqrsId(pqrsId)
        // handleDeactivateSendButton(validTextarea, true)
        setDeactivateCleanButton(false)
        return !optionSelected[pqrsId - 1]
      } else {
        return false
      }
    })
  )
}

export const handleChange = (
  e,
  setValues,
  setDeactivateSendButton,
  setDeactivateCleanButton,
  values,
  initialValues
) => {
  const regexMinChars = /^[\s\S]{40,}$/
  const regexPuntuationMarks = /[^\w\s]{3}/

  const subject = e.target.value
  if (subject.length > 1800) return

  if (validateNoWhiteSpacesAtBeginning(subject)) {
    if (hasRepeatedCharactersAtMiddle(subject)) {
      return
    }
    setValues({ ...values, description: e.target.value })
  }

  if (
    regexMinChars.test(e.target.value) &&
    !regexPuntuationMarks.test(e.target.value) &&
    e.target.value.length > 39
  ) {
    setDeactivateSendButton(false)
  } else {
    setDeactivateSendButton(true)
  }

  if (e.target.value.length > 0) {
    setDeactivateCleanButton(false)
  } else if (e.target.value.length < 1 || initialValues.description === null) {
    setDeactivateCleanButton(true)
  }
}

export const onClickClean = (
  initialValues,
  setValues,
  setDeactivateCleanButton,
  setDeactivateSendButton,
  deactivateCleanButton,
  isRequesting,
  setTextDropdownToggle
) => {
  if (deactivateCleanButton || isRequesting) return

  setValues(initialValues)
  setDeactivateCleanButton(true)
  setDeactivateSendButton(true)
  setTextDropdownToggle(newPqrsBuyerString.requestSelection)
}

export const handleClick = (
  setTextareaValue,
  setButtonState,
  setButtonClass
) => {
  setTextareaValue('')
  setButtonState(true)
  setButtonClass('newPqrsButtonDisabled')
}

export const handleDropdownClick = (setRotated, rotated) => {
  setRotated(!rotated)
}

export const handlePqrsSubmit = async (
  pqrsId,
  content,
  contextInfo,
  handleShow,
  setRadicateNum,
  setShowDropdownIncorrectFormat,
  setShowContentIncorrectFormat,
  setDeactivateSendButton,
  buyerId,
  setIsRequesting
) => {
  const timeOutId = handleRequestButton(setIsRequesting)
  if (pqrsId === null) {
    setShowDropdownIncorrectFormat('incorrectFormat')
    setDeactivateSendButton(false)
    activateRequestButton(setIsRequesting, timeOutId)
  } else {
    setShowDropdownIncorrectFormat('hideObject')
  }
  if (content === '') {
    setShowContentIncorrectFormat('incorrectFormat')
    setDeactivateSendButton(false)
    activateRequestButton(setIsRequesting, timeOutId)
  } else {
    setShowContentIncorrectFormat('hideObject')
  }

  if (pqrsId !== null && content !== '') {
    const pqrsSubmit = await postPQRS(pqrsId, content, buyerId, contextInfo)
    setRadicateNum(pqrsSubmit.radicatedNum)
    setDeactivateSendButton(false)
    handleShow(true)
  }
}

export const handleDescriptionOnBlur = (
  e,
  values,
  setValues,
  setDeactivateSendButton
) => {
  setValues({ ...values, description: deleteSpacing(e.target.value) })

  if (e.target.value.length > 39) {
    setDeactivateSendButton(false)
  } else {
    setDeactivateSendButton(true)
  }
}
