import { getPurchasedDB, postPQRS } from '../../../../utils/queries'
import { fileExtension, validateDataBaseExpiration } from '../../../../utils/validateFunctions'
import { buyDBUploadReceiptBuyerString } from '../../../../utils/strings'
import { activateRequestButton, handleRequestButton } from '../../../../utils/functions'
import { getDemandCode } from '../../../../utils/userqueries'

const sendPqrsDbPurchase = async (
  dbPurchaseJSON,
  contextInfo,
  setContextInfo,
  buyerId,
  companyId,
  file,
  lastTimeOutId,
  setIsRequesting,
  authContext
) => {
  let content = JSON.stringify({ ...dbPurchaseJSON, type: buyDBUploadReceiptBuyerString.purchase })

  if (contextInfo.isADatabaseRenewal) {
    content = JSON.stringify({
      buyerId,
      companyId,
      type: buyDBUploadReceiptBuyerString.renewal
    })
  }

  const timeOutId = handleRequestButton(setIsRequesting, lastTimeOutId)
  const postPQRSResponse = await postPQRS(8, content, authContext, contextInfo, file)

  handleRequestButton(setIsRequesting, timeOutId)
  const dbpurchased = await getPurchasedDB(null, null, null, null, authContext)

  let databasesStates = []
  let numberOfActiveDataBases = 0

  if (dbpurchased?.body?.length > 0) {
    databasesStates = validateDataBaseExpiration(dbpurchased?.body)
    if (databasesStates.length > 0) {
      numberOfActiveDataBases = databasesStates.filter(item => item === buyDBUploadReceiptBuyerString.active).length
    }
  }

  setContextInfo({
    ...contextInfo,
    dbpurchased: dbpurchased?.body,
    databasesStates,
    numberOfActiveDataBases,
    isADatabaseRenewal: false
  })

  return postPQRSResponse
}

export const acceptBtnModal = (
  modalMessageChecked,
  navigate,
  authContext,
  dataContext,
  isRequesting,
  setIsRequesting,
  price,
  handleShow2) => {
  if (!modalMessageChecked) {
    return null
  } else {
    createPayment(
      navigate,
      authContext,
      dataContext,
      isRequesting,
      setIsRequesting,
      price,
      handleShow2
    )
  }
}

export const storeBill = (
  e,
  setVoucherFile,
  setFile,
  setModalMessage,
  handleShow
) => {
  fileExtension(
    e,
    setVoucherFile,
    setFile,
    buyDBUploadReceiptBuyerString.allowedExtensions,
    setModalMessage,
    handleShow
  )
}

export const handleClickSendBtn = async (
  handleShow,
  dbPurchaseJSON,
  contextInfo,
  setContextInfo,
  buyerId,
  companyId,
  file,
  setRadicateNum,
  isRequesting,
  setIsRequesting
) => {
  if (!file || isRequesting) return

  const timeOutId = handleRequestButton(setIsRequesting)
  const sendPqrsDbPurchaseResponse = await sendPqrsDbPurchase(
    dbPurchaseJSON,
    contextInfo,
    setContextInfo,
    buyerId,
    companyId,
    file,
    timeOutId,
    setIsRequesting
  )

  if (sendPqrsDbPurchaseResponse.data.radicatedNum) {
    setRadicateNum(sendPqrsDbPurchaseResponse.data.radicatedNum)
  }
  handleShow()
}

export const createPayment = async (
  navigate,
  authContext,
  dataContext,
  isRequesting,
  setIsRequesting,
  price,
  handleShow2
) => {
  if (isRequesting) return

  const timeOutId = handleRequestButton(setIsRequesting)

  const { sessionData } = authContext
  const { contextInfo } = dataContext
  const { sessionInfo } = contextInfo
  const { companyId } = sessionData
  const { numberOfUsersToBuy: count, dbPurchaseJSON } = contextInfo
  const { databaseType, personalize } = dbPurchaseJSON

  const requestData = {
    companyId,
    databaseType,
    personalize,
    count,
    price: price * 1.19
  }

  const createPayinProcess = await getDemandCode(sessionInfo, authContext, requestData)

  const { response } = createPayinProcess

  if (response) {
    window.open(createPayinProcess.data, '_blank')
    navigate('../BaseDeDatos/CodigoLoopay')
  } else {
    handleShow2()
  }

  activateRequestButton(setIsRequesting, timeOutId)
}
