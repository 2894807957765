import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import appLogo from '../../../../assets/icons/logo.svg'
import colombiaImg from '../../../../assets/images/icon_colombia.png'
import whiteBackIcon from '../../../../assets/icons/icon_flecha_blanco.svg'
import { categoriesBuyerStrings } from '../../../../utils/strings'
import { CategoriesContext } from '../../../../context/CategoriesContext'
import { AuthContext } from '../../../../context/AuthContext'
import { getPlaces, handleClickContinueBtn } from './CategoriesLocationFunctions'
import './CategoriesLocation.css'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'

export const CategoriesLocation = () => {
  const authContext = useContext(AuthContext)
  const [showModal, handleShow, handleClose] = useModal()
  const { sessionData } = authContext
  const { buyerId } = sessionData
  const { categoriesSelected, setCategoriesSelected } = useContext(CategoriesContext)

  const navigate = useNavigate()
  const { state: planType } = useLocation()
  const { categoriesLocationStrings } = categoriesBuyerStrings

  const [places, setPlaces] = useState({
    states: [],
    cities: []
  })
  const [modalData, setModalData] = useState({
    message: categoriesLocationStrings.amount,
    amount: 0
  })
  const [locationSelected, setLocationSelected] = useState({
    country: '',
    state: '',
    city: ''
  })

  useEffect(() => {
    if (locationSelected.country !== '') {
      getPlaces(places, setPlaces, locationSelected)
    } else {
      setPlaces({ states: [], cities: [] })
      setLocationSelected({ country: '', state: '', city: '' })
      return
    }

    if (locationSelected.state !== '') {
      getPlaces(places, setPlaces, locationSelected)
    } else {
      setLocationSelected({ ...locationSelected, state: '', city: '' })
    }
  }, [locationSelected.country, locationSelected.state, locationSelected.city])

  return (
    <section id='CategoriesLocation'>
      <div className='CategoriesLocationContent'>
        <div className='CategoriesLocationLogoBox'>
          <img src={appLogo} alt="appLogo" />
        </div>
        <div className='CategoriesLocationText'>
          <p>{categoriesBuyerStrings.titleParagraph}</p>
        </div>
        <div className='CategoriesLocationArea'>
          <div className="CategoriesLocationSelects">
            <select className='CategoriesLocationSelect' onChange={(e) => setLocationSelected({ ...locationSelected, country: e.target.value })}>
              <option className='CategoriesLocationOption' value="">{categoriesLocationStrings.country}</option>
              <option className='CategoriesLocationOption' value="Colombia">{categoriesLocationStrings.colombia}</option>
            </select>
            <select className='CategoriesLocationSelect' onChange={(e) => setLocationSelected({ ...locationSelected, state: e.target.value })}>
              <option className='CategoriesLocationOption' value="">{categoriesLocationStrings.state}</option>
              {places.states.map((state) => (
                <option key={state.stateId} className='CategoriesLocationOption' value={state.stateId}>{state.stateName}</option>
              ))
              }
            </select>
            <select className='CategoriesLocationSelect' onChange={(e) => setLocationSelected({ ...locationSelected, city: Number(e.target.value) })}>
              <option className='CategoriesLocationOption' value="">{categoriesLocationStrings.city}</option>
              {locationSelected.country !== '' && places.cities.map((city) => (
                <option key={city.cityId} className='CategoriesLocationOption' value={city.cityId}>{city.cityName}</option>
              ))
              }
            </select>
          </div>
          <div className="CategoriesLocationCountryImage">
            <img src={colombiaImg} alt="colombiaImg" />
          </div>
        </div>
        <div className='CategoriesLocationFooter'>
          <div className='CategoriesLocationFooterContent'>
            <div>
              <Link to={`/buyer/${buyerId}/BaseDeDatos/SeleccionPlan/planBDEspecializada`}>
                <img className='CategoriesLocationFooterBackBtn' src={whiteBackIcon} alt="nextIcon" />
                <p>{categoriesBuyerStrings.back}</p>
              </Link>
            </div>
            <p>{categoriesLocationStrings.location}</p>
            <div>
              <button
                onClick={() =>
                  handleClickContinueBtn(
                    locationSelected,
                    categoriesSelected,
                    setCategoriesSelected,
                    locationSelected,
                    authContext,
                    setModalData,
                    modalData,
                    handleShow
                  )}
                className='CategoriesLocationFooterNextBtn'
                disabled={!locationSelected.country || !locationSelected.state || isNaN(locationSelected.city) || !locationSelected.city}>
                <p>{categoriesBuyerStrings.next}</p>
                <img src={whiteBackIcon} alt="nextIcon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalData.message}{modalData.amount}</p>
        </Modal.Content>
        <Modal.Buttons>
          {modalData.amount === 0
            ? <button className='Modal-greenBtn' onClick={handleClose}>{categoriesLocationStrings.ok}</button>
            : <>
                <button className='Modal-normalBtn' onClick={handleClose}>{categoriesLocationStrings.edit}</button>
                <button className='Modal-orangeBtn' onClick={() => navigate('/CategoriasBDGenero', { state: planType })}>{categoriesLocationStrings.continue}</button>
              </>
          }
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
