import { activateRequestButton, handleRequestButton, passwordEncrypt } from '../../../utils/functions'
import { setNewPasswordBuyerString } from '../../../utils/strings'
import { buyerSetNewPassword } from '../../../utils/userqueries'

export const hadleFormChange = (e, contextInfo, setContextInfo) => {
  setContextInfo({ ...contextInfo, [e.target.name]: e.target.value })
}

export const handleSubmit = async (
  e,
  navigate,
  contextInfo,
  setShowNotEqualPasswords,
  handleShowModal,
  setModalMessage,
  inputValue2,
  newPassword,
  conditions,
  isRequesting,
  setIsRequesting,
  inputValues
) => {
  e.preventDefault()
  if (!(conditions.isNewPasswordValid && (inputValues.inputValue2 === inputValues.inputValue3)) || isRequesting) return

  let timeOutId = handleRequestButton(setIsRequesting)
  if (!(conditions.isNewPasswordValid && (inputValue2 === newPassword))) {
    activateRequestButton(setIsRequesting, timeOutId)
    return
  }

  setShowNotEqualPasswords('showSpanPass')

  if (contextInfo.passwordRecovery !== contextInfo.rePasswordRecovery) {
    activateRequestButton(setIsRequesting, timeOutId)
    setShowNotEqualPasswords('incorrectFormat')
  } else {
    const newPasswordEncrypted = passwordEncrypt(newPassword)
    timeOutId = handleRequestButton(setIsRequesting, timeOutId)
    const setNewPasswordResponse = await buyerSetNewPassword(contextInfo.emailPasswordRecovery, newPasswordEncrypted)

    if (!setNewPasswordResponse.response) {
      activateRequestButton(setIsRequesting, timeOutId)
      setModalMessage(setNewPasswordBuyerString.incorrectFormat)
      handleShowModal()
    } else {
      navigate('/RecuperarContrasena/PasswordRecoverySuccess')
    }
  }
}

export const eyeShowPassword = (inputName, setEyeIcon, openEyeIcon, closeEyeIcon) => {
  const element = document.getElementsByName(inputName)[0]
  if (element.type === 'password') {
    element.type = 'text'
    setEyeIcon(closeEyeIcon)
  } else {
    element.type = 'password'
    setEyeIcon(openEyeIcon)
  }
  element.focus()
}
