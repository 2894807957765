import React from 'react'
import './SubCategoriesCard.css'

export const SubCategoriesCard = (props) => {
  const { categorySelected, setCategorySelected, isAlreadySelected } = props

  const handleCategorySelected = () => {
    if (isAlreadySelected) {
      const categoryDeleted = categorySelected.filter((category) => category.name !== props.name)
      setCategorySelected(categoryDeleted)
    } else {
      const newCatSelected = props.categoryData.find((data) => data.name === props.name)
      setCategorySelected([...categorySelected, newCatSelected])
    }
  }
  return (
    <button onClick={handleCategorySelected} className={`SubCategoriesListCategoryItem ${isAlreadySelected ? 'isCheckedBackground' : ''}`}>
        <img src={props.image} alt="item-icon" />
        <p>{props.name}</p>
    </button>
  )
}
