import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { passwordRecoveryBuyerStrings } from '../../../utils/strings'
import * as functions from './PasswordRecoverySuccessFunctions'
import { Form } from 'react-bootstrap'
import { DataContext } from '../../../context/DataContext'
import './../PasswordRecovery.css'

export const PasswordRecoverySuccess = () => {
  const { contextInfo } = useContext(DataContext)
  const { setContextInfo } = useContext(DataContext)
  const navigate = useNavigate()
  useEffect(() => {
    setContextInfo({ ...contextInfo, passwordRecoverylocation: 'PasswordRecoverySuccess' })
  }, [])

  return (
    <Form className='passwordRecoveryRSideCodeForm'>
      <p className='fw-bold passwordRecoveryRSideTopText'>{passwordRecoveryBuyerStrings.passwordChangeSuccess}</p>
      <p className='passwordRecoveryRSideTopText'>{passwordRecoveryBuyerStrings.goToLogin}</p><br />
      <button className="passwordRecoveryRSideSendBtn" onClick={(e) => functions.handleSubmit(e, navigate)}>
        {passwordRecoveryBuyerStrings.logIn}
      </button>
    </Form>
  )
}
