import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { faqBuyerString, landingPageBuyerString } from '../../../../utils/strings'
import './FAQ.css'

export const FAQ = () => {
  const lista = [...faqBuyerString.general, ...faqBuyerString.user]

  return (
    <div className="contendFAQ">
      <div className="FAQcontainer">
        <label className="labelFAQ">{landingPageBuyerString.FAQTitle}</label>
        <Accordion className="contendAccordionFAQ" defaultActiveKey="">
          {lista.map((item, id) => (
            <Accordion.Item
              eventKey={item}
              key={id + item}
              className="accordionItem"
            >
              <Accordion.Header className="accordionQuestion">
                {item.question}
              </Accordion.Header>
              <Accordion.Body className="accordionBody">
                {item.answer}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  )
}
