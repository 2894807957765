import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import logo from '../../assets/icons/logo.svg'

import arrowIcon from '../../assets/icons/icon_flecha_blanco.svg'
import { Link, useNavigate } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { companyRegistration2BuyerString, companyRegistrationBuyerString, passwordChangeBuyerString } from '../../utils/strings'
import CompanyRegistration2Functions, { createDataList, validateBuyerAddress, validateInputs } from './CompanyRegistration2Functions'

import { Modal } from '../../components/Modal/Modal'
import { useModal } from '../../hooks/useModal'

import './CompanyRegistration2.css'
import { AuthContext } from '../../context/AuthContext'
import { handleRequestButton } from '../../utils/functions'

export const CompanyRegistration2 = () => {
  const { contextInfo, setContextInfo } = useContext(DataContext)
  const { sessionData, setSessionData } = useContext(AuthContext)
  const { buyerAddress, buyerLegalRepresentative, buyerContactName, buyerRepresentativeIdNum } = sessionData
  const [phoneValue, setPhoneValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [identList, setIdentList] = useState([])
  const [typeID, setTypeId] = useState(null)
  const [idInputValue, setIdInputValue] = useState('')
  const [expeditionCity, setExpeditionCity] = useState('')
  const [citiesList, setCitiesList] = useState([])
  const navigate = useNavigate()

  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState('')

  const [submitEvent, setSubmitEvent] = useState(null)
  const [idValidatedByQuery, setIdValidatedByQuery] = useState(false)
  const [phoneValidatedByQuery, setPhoneValidatedByQuery] = useState(false)
  const [emailValidatedByQuery, setEmailValidatedByQuery] = useState(false)

  useEffect(() => {
    setContextInfo({
      ...contextInfo,
      companyEmail: '',
      buyerLegalRepresentative: '',
      buyerRepresentativeIdType: '',
      buyerExpeditionCity: '',
      buyerContactName: '',
      buyerAddress: '',
      companyPhone: ''
    })
    setSessionData({
      ...sessionData,
      buyerLegalRepresentative: '',
      buyerContactName: '',
      buyerRepresentativeIdNum: '',
      buyerAddress: ''
    })
    CompanyRegistration2Functions.createRegistrationDataList({
      setIdentList
    })

    createDataList(setCitiesList)
  }, [])

  const [isRequesting, setIsRequesting] = useState(false)

  const [showLegalRepresentativeIncorrectFormat, setShowLegalRepresentativeIncorrectFormat] = useState('showSpanPass')
  const [showTypeIdentIncorrectFormat, setShowTypeIdentIncorrectFormat] = useState('showSpanPass')
  const [showIdNumberIncorrectFormat, setShowIdNumberIncorrectFormat] = useState('showSpanPass')
  const [showExpeditionCityIncorrectFormat, setShowExpeditionCityIncorrectFormat] = useState('showSpanPass')
  const [showContactPersonIncorrectFormat, setShowContactPersonIncorrectFormat] = useState('showSpanPass')
  const [showAdressIncorrectFormat, setShowAdressIncorrectFormat] = useState('showSpanPass')
  const [showNotificationPhoneIncorrectFormat, setShowNotificationPhoneIncorrectFormat] = useState('showSpanPass')
  const [showEmailIncorrectFormat, setShowEmailIncorrectFormat] = useState('showSpanPass')

  useEffect(() => {
    if (idValidatedByQuery && phoneValidatedByQuery && emailValidatedByQuery && submitEvent !== null) {
      handleSubmit(submitEvent, idValidatedByQuery, phoneValidatedByQuery, emailValidatedByQuery, setIsRequesting, contextInfo.buyerRepresentativeIdType)
    } else {
      setSubmitEvent(null)
    }
  }, [idValidatedByQuery, phoneValidatedByQuery, emailValidatedByQuery])

  const handleSubmit = async (
    event,
    idValidatedByQuery,
    phoneValidatedByQuery,
    emailValidatedByQuery,
    isRequesting,
    setIsRequesting,
    buyerRepresentativeIdType
  ) => {
    event.preventDefault()
    if (isRequesting) return

    let timeOutId = handleRequestButton(setIsRequesting)
    if (!(idValidatedByQuery && phoneValidatedByQuery && emailValidatedByQuery)) {
      await validateInputs(
        setShowIdNumberIncorrectFormat,
        handleShow,
        setModalMessage,
        setIdInputValue,
        buyerRepresentativeIdNum,
        sessionData,
        setSessionData,
        setIdValidatedByQuery,
        setPhoneValidatedByQuery,
        setEmailValidatedByQuery,
        contextInfo,
        setContextInfo,
        setShowNotificationPhoneIncorrectFormat,
        setPhoneValue,
        setShowEmailIncorrectFormat,
        setEmailValue,
        idValidatedByQuery,
        phoneValidatedByQuery,
        emailValidatedByQuery,
        timeOutId,
        setIsRequesting,
        buyerRepresentativeIdType
      )
      setSubmitEvent(event)
      return
    }

    timeOutId = handleRequestButton(setIsRequesting, timeOutId)
    CompanyRegistration2Functions.formVerification(
      contextInfo,
      buyerAddress,
      buyerLegalRepresentative,
      buyerContactName,
      buyerRepresentativeIdNum,
      setShowLegalRepresentativeIncorrectFormat,
      setShowTypeIdentIncorrectFormat,
      setShowIdNumberIncorrectFormat,
      setShowExpeditionCityIncorrectFormat,
      setShowContactPersonIncorrectFormat,
      setShowAdressIncorrectFormat,
      setShowNotificationPhoneIncorrectFormat,
      setShowEmailIncorrectFormat,
      navigate,
      timeOutId,
      setIsRequesting
    )
  }

  return (
    <div className='companyRegistration2MainContainer'>
      <section className='companyRegistration2SubContainer1'>
        <div className='companyRegistration2ImgPragraphContainer'>
          <img src={logo} className='companyRegistration2SubContainer1Logo' alt={companyRegistrationBuyerString.tuDataLogo}></img>
          <p className='companyRegistration2Container1Paragraph'>{companyRegistrationBuyerString.completeYourProfile}</p>
        </div>
        <h2 className='companyRegistration2Container1H2'>{companyRegistrationBuyerString.welcome}<br />{companyRegistrationBuyerString.portal}</h2>
        <Link to='../RegistroEmpresa' className='compayRegistrationReturnParagraph'><img className='returnArrow' src={arrowIcon} alt={companyRegistrationBuyerString.backArrow} />{companyRegistrationBuyerString.back}</Link>
      </section>
      <section className='companyRegistration2SubContainer2'>
        <Link to='/login' className='companyRegistration2LogInLink1'>{companyRegistrationBuyerString.areYouRegistered} <span className='companyRegistration2LogInLink2'>{companyRegistrationBuyerString.logIn}</span></Link>
        <div className='companyRegistration2Inputs'>
          <p className='obligatoryField'>{companyRegistrationBuyerString.requiredInput}</p>
          <Form className='companyRegistrationForm' onSubmit={(e) =>
            handleSubmit(
              e,
              idValidatedByQuery,
              phoneValidatedByQuery,
              emailValidatedByQuery,
              isRequesting,
              setIsRequesting,
              contextInfo.buyerRepresentativeIdType
            )}>
            <div className='companyRegistration2InputsContainer'>
              <div>
                <Form.Control
                  className='companyRegistration2Container2Form'
                  autoComplete='off'
                  type='string'
                  value={buyerLegalRepresentative}
                  maxLength={48}
                  placeholder={companyRegistration2BuyerString.legalRepresentative}
                  name='buyerLegalRepresentative'
                  onChange={(e) => CompanyRegistration2Functions.hadleCompanyFormChange(e, contextInfo, setContextInfo, null, sessionData, setSessionData)}
                  onBlur={() => {
                    if (!buyerLegalRepresentative) {
                      setShowLegalRepresentativeIncorrectFormat('incorrectFormat')
                    } else {
                      setShowLegalRepresentativeIncorrectFormat('showSpanPass')
                    }
                  }}
                />
                <span className={showLegalRepresentativeIncorrectFormat}>
                  {passwordChangeBuyerString.requiredField}
                </span>
              </div>
              <div>
                <Form.Select
                  size="sm"
                  className="companyRegistrationContainer2Form"
                  onChange={(e) => CompanyRegistration2Functions.hadleSelectCompanyFormChange(e, contextInfo, setContextInfo, setTypeId)}
                  required
                  name="buyerRepresentativeIdType"
                  onBlur={() => {
                    if (!contextInfo.buyerRepresentativeIdType) {
                      setShowTypeIdentIncorrectFormat('incorrectFormat')
                    } else {
                      setShowTypeIdentIncorrectFormat('showSpanPass')
                    }
                  }}
                >
                  <option key="buyerRepresentativeIdType" hidden value="">
                    {companyRegistration2BuyerString.idRepresentatitve}
                  </option>
                  {identList.map((item) => (
                    <option
                      key={item.identDocId + item.identDocName}
                      value={item.identDocId}
                    >
                      {item.identDocName}
                    </option>
                  ))}
                </Form.Select>
                <span className={showTypeIdentIncorrectFormat}>
                  {passwordChangeBuyerString.requiredField}
                </span>
              </div>
              <div className='idInputsContainer'>
                <Form.Control
                  className='idNumInput'
                  disabled={!typeID}
                  autoComplete='off'
                  value={idInputValue}
                  type={typeID}
                  placeholder={companyRegistration2BuyerString.idNumber}
                  name='buyerRepresentativeIdNum'
                  maxLength='10'
                  onChange={(e) => CompanyRegistration2Functions.hadleCompanyFormChange2(e, contextInfo, setContextInfo, setIdInputValue, typeID, sessionData, setSessionData)}
                  onBlur={() =>
                    CompanyRegistration2Functions.validateBuyerRepresentativeIdNum(
                      setShowIdNumberIncorrectFormat,
                      handleShow,
                      setModalMessage,
                      setIdInputValue,
                      buyerRepresentativeIdNum,
                      sessionData,
                      setSessionData,
                      setIdValidatedByQuery,
                      contextInfo.buyerRepresentativeIdType
                    )}
                  onKeyDown={(e) => {
                    if (
                      e.key === 'e' ||
                      e.key === '+' ||
                      e.key === '.' ||
                      e.key === ',' ||
                      e.key === '-' ||
                      e.key === 'ArrowDown' ||
                      e.key === 'ArrowUp'
                    ) {
                      e.preventDefault()
                    }
                  }}
                />
                <Form.Control
                  className='expeditionCityInput'
                  disabled={!typeID}
                  autoComplete='off'
                  value={expeditionCity}
                  onChange={(e) => CompanyRegistration2Functions.handleExpeditionCityChange(e, contextInfo, setContextInfo, setExpeditionCity)}
                  onBlur={(e) =>
                    CompanyRegistration2Functions.handleOnBlurExpeditionCity(
                      e,
                      expeditionCity,
                      setExpeditionCity,
                      citiesList,
                      setModalMessage,
                      handleShow,
                      contextInfo,
                      setContextInfo
                    )}
                  type='text'
                  placeholder={companyRegistration2BuyerString.expeditionCity}
                  name='buyerExpeditionCity'
                />
              </div>
              <div className='idNumAndExpeditionCityErrorMessages'>
                <span className={showIdNumberIncorrectFormat}>
                  {passwordChangeBuyerString.requiredField}
                </span>
                <span className={showExpeditionCityIncorrectFormat}>
                  {passwordChangeBuyerString.requiredField}
                </span>
              </div>
              <div>
                <Form.Control
                  className='companyRegistration2Container2Form'
                  autoComplete='off'
                  type='string'
                  placeholder={companyRegistration2BuyerString.contactPerson}
                  maxLength='48'
                  name='buyerContactName'
                  value={buyerContactName}
                  onChange={(e) => CompanyRegistration2Functions.hadleCompanyFormChange(e, contextInfo, setContextInfo, null, sessionData, setSessionData)}
                  onBlur={() => {
                    if (!buyerContactName) {
                      setShowContactPersonIncorrectFormat('incorrectFormat')
                    } else {
                      setShowContactPersonIncorrectFormat('showSpanPass')
                    }
                  }}
                />
                <span className={showContactPersonIncorrectFormat}>
                  {passwordChangeBuyerString.requiredField}
                </span>
              </div>
              <div>
                <Form.Control
                  className='companyRegistration2Container2Form'
                  autoComplete='off'
                  type='string'
                  maxLength={48}
                  value={buyerAddress}
                  placeholder={companyRegistration2BuyerString.dirCorrespondence}
                  name='buyerAddress'
                  onChange={(e) => CompanyRegistration2Functions.hadleCompanyFormChange(e, contextInfo, setContextInfo, null, sessionData, setSessionData)}
                  onBlur={() => validateBuyerAddress(buyerAddress, setShowAdressIncorrectFormat)}
                />
                <span className={showAdressIncorrectFormat}>
                  {passwordChangeBuyerString.requiredField}
                </span>
              </div>
              <div>
                <Form.Control
                  className='companyRegistration2Container2Form'
                  autoComplete='off'
                  type='text'
                  value={phoneValue}
                  placeholder={companyRegistration2BuyerString.telNotification}
                  name='companyPhone'
                  onChange={(e) => CompanyRegistration2Functions.handlePhoneInputChange(e, contextInfo, setContextInfo, setPhoneValue)}
                  onBlur={() =>
                    CompanyRegistration2Functions.handlePhoneOnBlur(
                      contextInfo,
                      setContextInfo,
                      setShowNotificationPhoneIncorrectFormat,
                      setModalMessage,
                      handleShow,
                      setPhoneValue,
                      setPhoneValidatedByQuery
                    )}
                  onKeyDown={(e) => {
                    if (
                      e.key === 'e' ||
                      e.key === '+' ||
                      e.key === '.' ||
                      e.key === ',' ||
                      e.key === '-' ||
                      e.key === 'ArrowDown' ||
                      e.key === 'ArrowUp'
                    ) {
                      e.preventDefault()
                    }
                  }}
                />
                <span className={showNotificationPhoneIncorrectFormat}>
                  {passwordChangeBuyerString.requiredField}
                </span>
              </div>
              <div>
                <Form.Control
                  className='companyRegistration2Container2Form'
                  autoComplete='off'
                  type='email'
                  value={emailValue}
                  placeholder={companyRegistration2BuyerString.emailNotification}
                  name='companyEmail'
                  onChange={(e) => CompanyRegistration2Functions.hadleCompanyFormChange(e, contextInfo, setContextInfo, setEmailValue, sessionData, setSessionData)}
                  onBlur={() =>
                    CompanyRegistration2Functions.handleEmailOnBlur(
                      contextInfo,
                      setContextInfo,
                      setShowEmailIncorrectFormat,
                      setModalMessage,
                      handleShow,
                      setEmailValue,
                      setEmailValidatedByQuery
                    )}
                />
                <span className={showEmailIncorrectFormat}>
                  {passwordChangeBuyerString.requiredField}
                </span>
              </div>
            </div>
            <button
              type='submit'
              className='companyRegistration2ContinueButton'
              disabled={isRequesting}>
              {companyRegistrationBuyerString.continue}
            </button>
          </Form>
        </div>
      </section>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-greenBtn" onClick={handleClose}>{companyRegistration2BuyerString.ok}</button>
        </Modal.Buttons>
      </Modal>
    </div>
  )
}
