import { activateRequestButton, handleRequestButton } from '../../../utils/functions'
import { passwordRecoveryBuyerStrings } from '../../../utils/strings'
import { deleteSpacing, validateEmailFormat } from '../../../utils/validateFunctions'
import { buyerRequestPasswordRecovery } from './../../../utils/userqueries'

export const hadleFormChange = (e, contextInfo, setContextInfo, setEmail) => {
  setContextInfo({ ...contextInfo, [e.target.name]: deleteSpacing(e.target.value) })
  setEmail(deleteSpacing(e.target.value))
}

export const handleOnBlurEmail = (email, setEmail) => {
  setEmail(deleteSpacing(email))
}

export const handleSubmit = async (
  e,
  navigate,
  contextInfo,
  handleShowModal,
  setModalMessage,
  isRequesting,
  setIsRequesting,
  email
) => {
  e.preventDefault()
  if (!validateEmailFormat(email) || isRequesting) return

  const timeOutId = handleRequestButton(setIsRequesting)
  let buyerRequestPasswordRecoveryResponse

  if (contextInfo.emailPasswordRecovery) {
    handleRequestButton(setIsRequesting, timeOutId)
    buyerRequestPasswordRecoveryResponse = await buyerRequestPasswordRecovery(contextInfo.emailPasswordRecovery)
  } else {
    activateRequestButton(setIsRequesting, timeOutId)
    return
  }

  if (buyerRequestPasswordRecoveryResponse.data?.code.includes('EMAIL_NOT_FOUND')) {
    activateRequestButton(setIsRequesting, timeOutId)
    setModalMessage(passwordRecoveryBuyerStrings.emailNotFound)
    handleShowModal()
  }

  if (buyerRequestPasswordRecoveryResponse === true) {
    navigate('/RecuperarContrasena/PasswordRecoveryVerificationCode')
  }
}
