import { useEffect, useState } from 'react'
import {
  changePassword,
  validateOriginalPassword
} from '../../utils/userqueries'
import { passwordChangeBuyerString } from '../../utils/strings'
import { handleRequestButton, passwordEncrypt } from '../../utils/functions'

const regularExpressions = {
  Complete: /^(?=.*\d)(?=.*[!#$%&*,.:?@[\]^_{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  SpecialChar: /[!#$%&*,.:?@[\]^_{|}~]/,
  NoWhiteSpaces: /^\S+$/,
  CapitalLetter: /.*[A-Z].*/,
  LowerCaseLetter: /.*[a-z].*/,
  MinLength: /.{8,}/,
  NumericChar: /.*\d.*/,
  AllowedChars: /^[a-zA-Z\d!#$%&*,.:?@[\]^_{|}~]+$/
}

export const useChangePassword = () => {
  const [progressBarValue, setProgressBarValue] = useState(0)
  const [formValidated, setFormValidated] = useState(false)
  const [inputValues, setInputValues] = useState({
    inputValue1: '',
    inputValue2: '',
    inputValue3: ''
  })

  const [showPassword, setShowPassword] = useState({
    firstInput: true,
    secondInput: true,
    thirdInput: true
  })

  const [conditions, setConditions] = useState({
    isNewPasswordValid: false,
    arePasswordsEqual: false
  })

  useEffect(() => {
    const areValidationComplete = Object.values(conditions).every(
      (conditions) => conditions === true
    )
    areValidationComplete ? setFormValidated(true) : setFormValidated(false)
  }, [conditions])

  const handleNewPassword = (event) => {
    const password = event.target.value
    setInputValues((inputValues) => ({
      ...inputValues,
      inputValue2: password
    }))

    const validationsNumber = validatePassword(password)
    const percentagePerValidation = 14.29
    const percentageValidation = validationsNumber * percentagePerValidation
    setProgressBarValue(percentageValidation)

    if (percentageValidation >= 100) {
      setConditions((conditions) => ({
        ...conditions,
        isNewPasswordValid: true
      }))
    } else {
      setConditions((conditions) => ({
        ...conditions,
        isNewPasswordValid: false
      }))
    }
  }

  const validatePassword = (password) => {
    const validationsList = [
      { checkRegExp: regularExpressions.CapitalLetter.test(password) },
      { checkRegExp: regularExpressions.SpecialChar.test(password) },
      { checkRegExp: regularExpressions.NoWhiteSpaces.test(password) },
      { checkRegExp: regularExpressions.LowerCaseLetter.test(password) },
      { checkRegExp: regularExpressions.MinLength.test(password) },
      { checkRegExp: regularExpressions.NumericChar.test(password) },
      { checkRegExp: regularExpressions.AllowedChars.test(password) }
    ]
    return validationsList.filter(({ checkRegExp }) => checkRegExp === true)
      .length
  }

  const handleConfirmPassword = (event) => {
    const confirmPassword = event.target.value
    setInputValues((inputValues) => ({
      ...inputValues,
      inputValue3: confirmPassword
    }))
  }

  useEffect(() => {
    inputValues.inputValue2 === inputValues.inputValue3
      ? setConditions((conditions) => ({
        ...conditions,
        arePasswordsEqual: true
      }))
      : setConditions((conditions) => ({
        ...conditions,
        arePasswordsEqual: false
      }))
  }, [inputValues])

  const handleSubmit = async ({
    event,
    email,
    inputValues,
    handleShow,
    setModalMessage,
    setIsChangeSuccess,
    authContext,
    setIsRequesting,
    formValidated,
    isRequesting
  }) => {
    event.preventDefault()
    if (!formValidated || isRequesting) return
    const currentPassword = inputValues.inputValue1
    const newPassword = inputValues.inputValue2

    const currentPasswordEncrypted = passwordEncrypt(currentPassword)
    const timeOutId = handleRequestButton(setIsRequesting)
    const validatePasswordResponse = await validateOriginalPassword(
      email,
      currentPasswordEncrypted
    )

    if (validatePasswordResponse.response) {
      if (currentPassword !== newPassword) {
        event.preventDefault()
        const newPasswordEncrypted = passwordEncrypt(newPassword)
        handleRequestButton(setIsRequesting, timeOutId)
        const changePasswordResponse = await changePassword(
          email,
          newPasswordEncrypted,
          authContext
        )
        if (changePasswordResponse.response) {
          setModalMessage(passwordChangeBuyerString.textPassword)
          setIsChangeSuccess(true)
          handleShow()
        }
      } else {
        setModalMessage(passwordChangeBuyerString.sameOldPasswordError)
        handleShow()
      }
    } else {
      setModalMessage(passwordChangeBuyerString.oldPasswordWrong)
      handleShow()
    }
  }

  const states = {
    inputValues,
    formValidated,
    progressBarValue,
    showPassword,
    conditions
  }

  const handlers = {
    setInputValues,
    setShowPassword,
    handleNewPassword,
    handleConfirmPassword,
    handleSubmit,
    inputValues
  }

  return { states, handlers }
}

export const handleClickPasswordChanged = (navigate, sessionData, setSessionData) => {
  setSessionData({ sessionData, logged: false })
  navigate('/login')
}
