import React, { useContext, useEffect } from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'

// Assets
import logo from '../../assets/icons/logo.svg'

// Utils
import { landingPageBuyerString } from '../../utils/strings'

import './LandingPage.css'
import { DataContext } from '../../context/DataContext'
import { AuthContext } from '../../context/AuthContext'

import { Amplify } from '../../utils/amplify'

export const LandingPage = () => {
  const { setContextInfo, initialDataContextState } = useContext(DataContext)
  const { setSessionData, initialAuthContextState } = useContext(AuthContext)

  useEffect(() => {
    setContextInfo({ ...initialDataContextState, activePage: true })
    setSessionData(initialAuthContextState)
  }, [])

  const amplify = new Amplify()
  amplify.sign()

  return (
    <div className="landingPage">
      <div className="landingPageNav">
        <Link to="/">
          <img className="landingPageLogo" src={logo} alt={landingPageBuyerString.logoTuData} />
        </Link>
        <div className="landingPageNavButtons">
          {/* <BtnActivaModalNosotros /> */}
          <NavLink to="/Nosotros" className={({ isActive }) => `landingPageNavBtn ${isActive ? 'navChecked' : ''}`}>
            {landingPageBuyerString.us}
          </NavLink>
          <NavLink to="Contacto" className={({ isActive }) => `landingPageNavBtn ${isActive ? 'navChecked' : ''}`}>
            {landingPageBuyerString.contact}
          </NavLink>
          <NavLink to="/FAQ" className={({ isActive }) => `landingPageNavBtn ${isActive ? 'navChecked' : ''}`}>
            {landingPageBuyerString.FAQ}
          </NavLink>
          <NavLink to="/login" className={({ isActive }) => `landingPageNavLoginBtn ${isActive ? 'navChecked' : ''}`}>
            {landingPageBuyerString.getIn}
          </NavLink>
        </div>
      </div>
      <Outlet />
    </div>
  )
}
