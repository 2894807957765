import React, { useContext } from 'react'

// Assets
import iconDBSimple from '../../../../assets/icons/icon_bd.svg'
import iconDBSpecialized from '../../../../assets/icons/icon_bd2.svg'

// Utils
import { buyDBResumeBuyerString } from '../../../../utils/strings'

import './BuyDBResume.css'
import { AuthContext } from '../../../../context/AuthContext'

export const BuyDBResume = ({ planType }) => {
  const { sessionData } = useContext(AuthContext)
  const { companyComertialName } = sessionData
  const isBDSimple = planType && planType === 'planBDSimple'

  return (
    <div className="buyDBResume">
      <div
        className={`buyDBResumeImgContainer ${
          isBDSimple ? 'SimpleBG' : 'SpecializedBG'
        }`}
      >
        <img
          src={isBDSimple ? iconDBSimple : iconDBSpecialized}
          alt="icono base de datos"
          className="buyDBResumeImg"
        />
        <p className="buyDBResumeImgTitle">{buyDBResumeBuyerString.DDBB}</p>
        <p className="buyDBResumePlanType">
          {isBDSimple ? 'Simple' : 'Especializada'}
        </p>
      </div>
      <div className="buyDBResumeDescriptionContainer">
        <h2 className="buyDBResumeDescriptionTitle">
          {buyDBResumeBuyerString.description}
        </h2>
        <p className="buyDBResumeDescription">
          {buyDBResumeBuyerString.databaseEntity}
           {companyComertialName}
          <br />
          {`${
            isBDSimple
              ? buyDBResumeBuyerString.dataBaseSimple
              : buyDBResumeBuyerString.dataBaseSpecialized
          }`}
        </p>
      </div>
    </div>
  )
}
