import React, { useContext } from 'react'
import iconPlus from '../../../../assets/icons/icon_plus.svg'
import { Link, useNavigate } from 'react-router-dom'
import { DataBaseBuyerStrings } from '../../../../utils/strings'
import './DataBasePanel.css'
import { DataContext } from '../../../../context/DataContext'
import { formatDate, handleClickBodyRow, handleGoToBDPurchased } from './DataBasePanelFunctions'
import { useModal } from '../../../../hooks/useModal'
import { Modal } from '../../../../components/Modal/Modal'
import { getBuyerActiveValidation } from '../../../../utils/userqueries'
import { AuthContext } from '../../../../context/AuthContext'

export const DataBasePanel = () => {
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const { dataBasePanelStrings } = DataBaseBuyerStrings
  const { contextInfo, setContextInfo } = useContext(DataContext)
  const [showModal, handleShow, handleClose] = useModal()

  const validateBuyerActive = async () => {
    const buyerPat = contextInfo.sessionInfo.findBuyer.buyer.buyerId
    const responseValidation = await getBuyerActiveValidation(buyerPat, authContext)
    if (responseValidation.response) navigate('SeleccionPlan')
    else handleShow()
  }

  return (
    <section id='DataBase'>
      <div className='DataBaseButtonBox'>
        <button className='DataBaseButton' onClick={validateBuyerActive}>
          <img src={iconPlus} alt="icon-pluss" />
          {dataBasePanelStrings.newDataBase}
        </button>
      </div>
      <div className='DataBaseTable'>
        <div className='DataBaseTableHeader'>
          <p>{dataBasePanelStrings.purchaseDate}</p>
          <p>{dataBasePanelStrings.type}</p>
          <p>{dataBasePanelStrings.usersQuantity}</p>
          <p>{dataBasePanelStrings.dueDate}</p>
          <p>{dataBasePanelStrings.DBState}</p>
        </div>
        <div className='DataBaseTableBody'>
          {contextInfo.dbpurchased?.map((data, index) => (
            <button key={index} id={`bdPurchased-${index}`} className='DataBaseTableRowButton' onClick={(e) => handleGoToBDPurchased(e, navigate, contextInfo, setContextInfo)}>
              <div className='DataBaseTableBodyRow' id={`bdPurchased-${index}`} onClick={(e) => handleClickBodyRow(e, index)}>
                <p className='DatabaseInfo'>{formatDate(data.shoppingDate)}</p>
                <p className='DatabaseInfo'>{data.databaseTypeId.databaseType}</p>
                <p className='DatabaseInfo'>{data.usersAmount}</p>
                <p className='DatabaseInfo'>{formatDate(data.expeditionDate)}</p>
                <div className='DataBaseStateContainer'>
                  <div className='DatabaseInfo'>{data.statusId.statusName}</div>
                  <div
                    className={`DataBaseStateIndicator ${data.statusId.statusName === 'Pendiente'
                      ? 'DataBaseStateIndicatorPendingColor'
                      : data.statusId.statusName === 'Activa'
                        ? 'DataBaseStateIndicatorActiveColor'
                        : 'DataBaseStateIndicatorInactiveColor'}`}>
                  </div>
                </div>
              </div>
            </button>
          ))
          }
          {(!contextInfo.dbpurchased || contextInfo.dbpurchased.length === 0) &&
            <div>
              <p className='noBDText'>{dataBasePanelStrings.noDB}<Link className='wannaBuyText' onClick={validateBuyerActive}>{dataBasePanelStrings.wannaBuy}</Link></p>
            </div>
          }
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <img src="" alt="" />
          <p>
            {DataBaseBuyerStrings.notValidatedTitle}
            <br />
            {DataBaseBuyerStrings.notValidatedBody}
          </p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-greenBtn' onClick={handleClose}>OK</button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
