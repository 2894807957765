import React, { useState, useContext, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Assets
import appLogo from '../../../../assets/icons/logo.svg'
import simpleDBIcon from '../../../../assets/icons/icon_bd.svg'
import specializedDBIcon from '../../../../assets/icons/icon_bd2.svg'
import whiteBackIcon from '../../../../assets/icons/icon_flecha_blanco.svg'

// Context
import { AuthContext } from '../../../../context/AuthContext'
import { DataContext } from '../../../../context/DataContext'

// Utils
import { DataBaseBuyerStrings } from '../../../../utils/strings'
import { useModal } from '../../../../hooks/useModal'
import { Modal } from '../../../../components/Modal/Modal'

import { handleSubmit } from './DataBasePriceFunctions'
import './DataBasePrice.css'

export const DataBasePrice = () => {
  const { planType } = useParams()
  const navigate = useNavigate()
  const { contextInfo, setContextInfo } = useContext(DataContext)
  const authContext = useContext(AuthContext)
  const { sessionData } = authContext
  const { buyerId } = sessionData
  const { dataBasePriceStrings, dataBaseChooseStrings } = DataBaseBuyerStrings
  const [modalMessage, setModalMessage] = useState('')
  const [showModal, handleShow, handleClose] = useModal()
  const [isRequesting, setIsRequesting] = useState(false)

  useEffect(() => {
    setContextInfo({ ...contextInfo, planType })
  }, [])

  return (
    <section id="DataBasePrice">
      <div className="DataBasePriceLSide">
        <div className="DataBasePriceLSideTside">
          <img src={appLogo} alt="app-logo" />
          <h2>{dataBasePriceStrings.selectIdealPlan}</h2>
          <br />
        </div>
        <div className="DataBasePriceLSideBside">
          {planType === 'planBDSimple' && (
            <div className="DataBaseSimpleLSideCard">
              <div className="DataBaseSimpleLSideCardImgBox">
                <img src={simpleDBIcon} alt="db-icon" />
              </div>
              <div className="DataBaseSimpleLSideCardText">
                <p className="DataBaseSimpleLSideCardTitle">
                  {dataBasePriceStrings.dataBase}
                </p>
                <p className="DataBaseSimpleLSideCardSubtitle">
                  {dataBasePriceStrings.dbSimple}
                </p>
              </div>
            </div>
          )}
          {planType === 'planBDEspecializada' && (
            <div className="DataBaseSpecializedLSideCard">
              <div className="DataBaseSpecializedLSideCardImgBox">
                <img src={specializedDBIcon} alt="db-icon" />
              </div>
              <div className="DataBaseSpecializedLSideCardText">
                <p className="DataBaseSpecializedLSideCardTitle">
                  {dataBasePriceStrings.dataBase}
                </p>
                <p className="DataBaseSpecializedLSideCardSubtitle">
                  {dataBasePriceStrings.dbSpecialized}
                </p>
              </div>
            </div>
          )}
          <div className="DataBasePriceLSideBsideFooter">
            <Link to={`/buyer/${buyerId}/BaseDeDatos/SeleccionPlan`}>
              <img src={whiteBackIcon} alt="back-icon" />
              <p>{dataBasePriceStrings.back}</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="DataBasePriceRSide">
        <div className="DataBasePriceRSideTopBox">
          <p className="DataBasePriceRSideTopBoxMonths">
            {dataBasePriceStrings.months}
          </p>
        </div>
        {planType === 'planBDSimple' && (
          <div className="DataBasePriceSimpleRSideCard">
            <div className="DataBasePriceSimpleRSideCardImgBox">
              <img src={simpleDBIcon} alt="db-icon" />
            </div>
            <div className="DataBasePriceSimpleRSideCardText">
              <div className="DataBasePriceSimpleRSideCardTop">
                <p className="DataBasePriceSimpleRSideCardTitle">
                  {dataBasePriceStrings.dataBase}
                </p>
                <p className="DataBasePriceSimpleRSideCardSubtitle">
                  {dataBasePriceStrings.dbSimple}
                </p>
              </div>
              <div className="DataBasePriceSimpleRSideCardBottom">
                <p className="DataBasePriceRSideCardInfoPlan">
                  {dataBaseChooseStrings.descriptionDBSimple}
                </p>
              </div>
            </div>
          </div>
        )}
        {planType === 'planBDEspecializada' && (
          <div className="DataBasePriceSpecializedRSideCard">
            <div className="DataBasePriceSpecializedRSideCardImgBox">
              <img src={specializedDBIcon} alt="db-icon" />
            </div>
            <div className="DataBasePriceSpecializedRSideCardText">
              <div className="DataBasePriceSpecializedRSideCardTop">
                <p className="DataBasePriceSpecializedRSideCardTitle">
                  {dataBasePriceStrings.dataBase}
                </p>
                <p className="DataBasePriceSpecializedRSideCardSubtitle">
                  {dataBasePriceStrings.dbSpecialized}
                </p>
              </div>
              <div className="DataBasePriceSpecializedRSideCardBottom">
                <p className="DataBasePriceRSideCardInfoPlan">
                  {dataBaseChooseStrings.descriptionDBSpecialized}
                </p>
              </div>
            </div>
          </div>
        )}
        <button
          onClick={() =>
            handleSubmit(
              navigate,
              planType,
              contextInfo,
              setContextInfo,
              setModalMessage,
              handleShow,
              authContext,
              isRequesting,
              setIsRequesting
            )
          }
          className="DataBasePriceRSideButton"
          disabled={isRequesting}
        >
          {dataBasePriceStrings.select}
        </button>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-greenBtn" onClick={handleClose}>
            {dataBasePriceStrings.ok}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
