import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { validateNumberOfSelectionsbySubCategory } from '../../../../utils/userqueries'
import { CategoriesContext } from '../../../../context/CategoriesContext'
import { getElements } from '../../../../utils/queries'
import whiteBackIcon from '../../../../assets/icons/icon_flecha_blanco.svg'
import iconLupa from '../../../../assets/icons/icon_lupa-8.png'
import { SubCategoriesCard } from '../../components/SubCategoriesCard/SubCategoriesCard'
import { categoriesBuyerStrings } from '../../../../utils/strings'
import { getSubcategories, searchItem } from '../../../../utils/functions'
import './SubCategories.css'
import { AuthContext } from '../../../../context/AuthContext'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'

export const SubCategories = () => {
  const navigate = useNavigate()
  const { categoriesSelected, setCategoriesSelected } =
    useContext(CategoriesContext)
  const authContext = useContext(AuthContext)
  const { sessionData } = authContext
  const { authTk } = sessionData
  const { generalCatSelected } = categoriesSelected
  const [subCatSelected, setSubCatSelected] = useState([])
  const [modalData, setModalData] = useState(0)
  const [showModal, handleShow, handleClose] = useModal()

  const { state: planType } = useLocation()

  const [item, setItem] = useState({
    subcategorySearch: ''
  })
  const [pivotList, setPivotList] = useState([])
  const [filteredList, setFilteredList] = useState([])

  useEffect(() => {
    const getSubCategories = async () => {
      const elements = await getElements(authContext)
      if (elements) {
        const subCategories = await getSubcategories(elements)
        const subCatFiltered = subCategories.filter((subCat) =>
          generalCatSelected.some((cat) => cat.name === subCat.categoryName)
        )
        setPivotList(subCatFiltered)
        setFilteredList(subCatFiltered)
      }
    }

    if (authTk) {
      getSubCategories()
    }
  }, [generalCatSelected])

  useEffect(() => {
    if (categoriesSelected.subCatSelected) {
      const catAlreadySelected = filteredList.filter((subCat) => categoriesSelected.subCatSelected.some((item) => item.id === subCat.id))
      setSubCatSelected(catAlreadySelected)
    }
  }, [filteredList])

  const handleNextCategory = async (selection) => {
    if (!selection.length) return

    setCategoriesSelected({ ...categoriesSelected, subCatSelected })
    const searchParams = {
      ...categoriesSelected,
      subCategories: subCatSelected.map((item) => item.id)
    }
    delete searchParams.generalCatSelected
    delete searchParams.subCatSelected
    const amount = await validateNumberOfSelectionsbySubCategory(
      authContext,
      searchParams
    )
    // modal alert continuar / corregir
    setModalData(amount?.count)
    handleShow()
  }

  const isAlreadySelected = (id) => {
    const isSelected = subCatSelected.some((item) => item.id === id)
    if (isSelected) return true
    else return false
  }

  return (
    <section id="SubCategories">
      <div className="SubCategoriesContent">
        <div className="SubCategoriesSearch">
          <div>
            <input
              placeholder="Buscar"
              name="subcategorySearch"
              type="text"
              className="SubCategoriesSearchInput"
              onChange={(e) =>
                searchItem(e, item, setItem, pivotList, setFilteredList)
              }
            />
            <button className="SubCategoriesSearchBtn">
              <img src={iconLupa} alt="iconLupa" />
            </button>
          </div>
        </div>
        <div className="SubCategoriesList">
          <div className="SubCategoriesListCategory">
            {generalCatSelected?.map((category) => (
              <div key={category.id}>
                <h2>{category.name}</h2>
                <div className="SubCategoriesListCategoryGrid">
                  {filteredList?.map(
                    (subcategory) =>
                      category.name === subcategory.categoryName && (
                        <SubCategoriesCard
                          key={subcategory.id}
                          id={subcategory.id}
                          image={subcategory.icon}
                          name={subcategory.name}
                          categoryData={filteredList}
                          categorySelected={subCatSelected}
                          setCategorySelected={setSubCatSelected}
                          isAlreadySelected={isAlreadySelected(subcategory.id)}
                        />
                      )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="SubCategoriesFooter">
          <div className="SubCategoriesFooterContent">
            <div>
              <Link to="../CategoriasBDGeneral">
                <img
                  className="SubCategoriesFooterBackBtn"
                  src={whiteBackIcon}
                  alt="nextIcon"
                />
                <p>{categoriesBuyerStrings.back}</p>
              </Link>
            </div>
            <div>
              <button
                onClick={() => handleNextCategory(subCatSelected)}
                className="SubCategoriesFooterNextBtn"
                disabled={!subCatSelected.length}
              >
                <p>{categoriesBuyerStrings.continue}</p>
                <img src={whiteBackIcon} alt="nextIcon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>Cantidad de registros por selección actual: {modalData}</p>
        </Modal.Content>
        <Modal.Buttons>
          {modalData === 0
            ? (
            <button className="Modal-greenBtn" onClick={handleClose}>
              OK
            </button>
              )
            : (
            <>
              <button className="Modal-normalBtn" onClick={handleClose}>
                Editar
              </button>
              <button
                className="Modal-orangeBtn"
                onClick={() =>
                  navigate('/SubCategoriasItemsBD', { state: planType })
                }
              >
                Continuar
              </button>
            </>
              )}
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
