import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DataContext } from '../../context/DataContext'
import { Modal } from '../../components/Modal/Modal'
import { useModal } from '../../hooks/useModal'
import IconCheck from '../../assets/icons/icon_bien.svg'
import { deleteAccounButBuyerString } from '../../utils/strings'
import {
  handleDeleteData,
  handleInputChange,
  handleOnBlurOther,
  handleOther,
  handleRequest
} from './DeleteAccountFunctions'

import './DeleteAccount.css'
import { AuthContext } from '../../context/AuthContext'
import { maxSize } from '../../utils/validateFunctions'

export const DeleteAccount = () => {
  const navigate = useNavigate()
  const { contextInfo } = useContext(DataContext)
  const authContext = useContext(AuthContext)
  const [isInputChecked, setIsInputChecked] = useState({
    checkboxChecked: '',
    inputDeleteAll: false
  })
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [reason, setReason] = useState('')
  const [otherReason, setOtherReason] = useState('')
  const [deleteData, setDeleteData] = useState(false)
  const [showModal, handleShow] = useModal()
  const [radicate, setRadicate] = useState('')
  const [isRequesting, setIsRequesting] = useState(false)

  useEffect(() => {
    if (Object.values(isInputChecked).every((el) => el === false)) {
      setBtnDisabled(true)
    } else if (
      isInputChecked.checkboxChecked &&
      isInputChecked.checkboxChecked !== 'input3'
    ) {
      setBtnDisabled(false)
      setOtherReason('')
    } else if (
      isInputChecked.checkboxChecked === 'input3' &&
      otherReason.trim() !== ''
    ) {
      setBtnDisabled(false)
    } else setBtnDisabled(true)
  }, [isInputChecked, otherReason])

  return (
    <section className="deleteAccountMainContainer">
      <h3 className="deleteAccountH3">
        {deleteAccounButBuyerString.deleteAccount}
      </h3>
      <p className="deleteAccountSubTitle1">
        {deleteAccounButBuyerString.paragraphLine1}
      </p>
      <p className="deleteAccountContent">
        {deleteAccounButBuyerString.paragraphLine2}
      </p>
      <p className="deleteAccountSubTitle2">
        {deleteAccounButBuyerString.paragraphLine3}
      </p>
      <div className="deleteAccountChkBoxContainer1">
        <div className="deleteAccountChkBoxSubContainer">
          <input
            className="deleteAccountOption"
            id="input1"
            type="radio"
            name="reasonsToDeleteAccount"
            checked={isInputChecked.checkboxChecked === 'input1'}
            onChange={(e) =>
              handleInputChange(e, isInputChecked, setIsInputChecked, setReason)
            }
          ></input>
          <label className="deleteAccountContent" htmlFor="deleteAccountOp1">
            {deleteAccounButBuyerString.checkBoxOption1}
          </label>
        </div>
        <div className="deleteAccountChkBoxSubContainer">
          <input
            className="deleteAccountOption"
            id="input2"
            type="radio"
            name="reasonsToDeleteAccount"
            checked={isInputChecked.checkboxChecked === 'input2'}
            onChange={(e) =>
              handleInputChange(e, isInputChecked, setIsInputChecked, setReason)
            }
          ></input>
          <label className="deleteAccountContent" htmlFor="deleteAccountOp2">
            {deleteAccounButBuyerString.checkBoxOption2}
          </label>
        </div>
        <div className="deleteAccountChkBoxSubContainer">
          <input
            className="deleteAccountOption"
            id="input3"
            type="radio"
            name="reasonsToDeleteAccount"
            checked={isInputChecked.checkboxChecked === 'input3'}
            onChange={(e) =>
              handleInputChange(e, isInputChecked, setIsInputChecked, setReason)
            }
          ></input>
          <label
            className="deleteAccountContent deleteAccountOther"
            htmlFor="deleteAccountOp3"
          >
            {deleteAccounButBuyerString.checkBoxOption3}
          </label>
          <input
            className="deleteAccountTextInput"
            type="text"
            name="input3"
            value={otherReason}
            onInput={(e) => maxSize(e, 'flName')}
            onChange={(e) =>
              handleOther({
                e,
                setOtherReason,
                isInputChecked,
                setIsInputChecked
              })
            }
            onBlur={(e) => handleOnBlurOther(e, setOtherReason)}
          ></input>
        </div>
      </div>
      <p className="deleteAccountContent">
        {deleteAccounButBuyerString.generalParagraphPart1}{' '}
        <Link to="../CondicionesYPoliticas" className="deleteAccountLink">
          {deleteAccounButBuyerString.terms}
        </Link>{' '}
        {deleteAccounButBuyerString.generalParagraphPart2}{' '}
      </p>
      <div className="deleteAccountChkBoxContainer2">
        <input
          className="deleteAccountOption"
          id="deleteAccountOp4"
          type="checkbox"
          checked={isInputChecked.inputDeleteAll}
          onChange={(e) =>
            handleDeleteData({
              e,
              setDeleteData,
              isInputChecked,
              setIsInputChecked
            })
          }
        ></input>
        <label className="deleteAccountContent" htmlFor="deleteAccountOp4">
          {deleteAccounButBuyerString.deleteMyData}
        </label>
      </div>
      <button
        className="deleteAccountButton navFooterButton navFooterButton2"
        disabled={
          btnDisabled ||
          isRequesting ||
          (reason === 'input3' && otherReason.length < 15)
        }
        onClick={(e) =>
          handleRequest({
            e,
            reason,
            otherReason,
            deleteData,
            handleShow,
            contextInfo,
            setRadicate,
            btnDisabled,
            isRequesting,
            setIsRequesting,
            authContext
          })
        }
      >
        {deleteAccounButBuyerString.sendRequest}
      </button>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <div className="modalHeader">
            <img src={IconCheck} alt="icon-check" className="modalIcon" />
            <p className="modalSettled">
              {`${deleteAccounButBuyerString.settled} ${radicate}`}
            </p>
          </div>
          <p>{deleteAccounButBuyerString.textFollowup2}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-greenBtn" onClick={() => navigate('/login')}>
            {deleteAccounButBuyerString.ok}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
