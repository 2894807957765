import { getPurchasedDB } from '../../../../utils/queries'
import { validateDataBaseExpiration } from '../../../../utils/validateFunctions'

export const handleNextButtonClick = async (navigate, buyerId) => {
  navigate(`/buyer/${buyerId}/Cuenta`)
}

export const getUsersBuyedList = async (authContext, setContextInfo, contextInfo) => {
  const dbpurchased = await getPurchasedDB(null, null, null, null, authContext)
  const databasesStates = validateDataBaseExpiration(dbpurchased?.body)
  const numberOfActiveDataBases = databasesStates.filter((item) => item === 'Activa').length

  setContextInfo({
    ...contextInfo,
    dbpurchased: dbpurchased?.body,
    databasesStates,
    numberOfActiveDataBases
  })
}
