import { handleRequestButton } from '../../../../utils/functions'
import { selectUserBuyerString } from '../../../../utils/strings'
import { getDBPrice } from '../../../../utils/userqueries'
import { onlyIntegerPositiveNumbers } from '../../../../utils/validateFunctions'

export const handleChangeUsersToBuy = (
  e,
  usersWhoWishToContactThem,
  setUsersToBuy
) => {
  if (
    (e.target.value <= usersWhoWishToContactThem &&
      onlyIntegerPositiveNumbers(e.target.value)) ||
    e.target.value === ''
  ) {
    setUsersToBuy(e.target.value)
  }
}

const planTypeMapping = {
  planBDSimple: 1,
  planBDEspecializada: 2
}

export const handleSubmit = async (
  count,
  navigate,
  setContextInfo,
  contextInfo,
  personalize,
  authContext,
  usersToBuy,
  isRequesting,
  setIsRequesting
) => {
  if ((usersToBuy <= 0 && usersToBuy === '') || isRequesting) return

  const { sessionData } = authContext
  const { buyerId, companyId } = sessionData
  const planTypeNum = planTypeMapping[contextInfo.planType]

  if (!planTypeNum) {
    throw new Error(selectUserBuyerString.typeOfPlan)
  }

  try {
    if (count > 0) {
      handleRequestButton(setIsRequesting)
      const getDBPriceResponse = await getDBPrice(
        planTypeNum,
        count,
        authContext
      )

      if (getDBPriceResponse.response) {
        setContextInfo({
          ...contextInfo,
          DBPrice: getDBPriceResponse.data.price,
          numberOfUsersToBuy: count,
          dbPurchaseJSON: {
            buyerId,
            companyId,
            count,
            databaseType: planTypeNum,
            personalize: planTypeNum === 2 ? personalize : null
          }
        })
        if (planTypeNum === 1 || planTypeNum === 2) {
          navigate(`/buyer/${buyerId}/BaseDeDatos/CompraBaseDatosPaso2`)
        }
      }
    }
  } catch (error) {
    throw new Error(error)
  }
}
